import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Avatar, Button, Col, Icon, Row, Upload } from 'antd'
import { withRouter } from 'react-router-dom'
import { setPageTitle } from '../actions/apps'
import { destroyMyAvatar, updateMyAccount, updatePassword, uploadMyAvatar } from '../actions/accounts'
import EditProfileModal from '../components/Modals/EditProfileModal'
import RenewPasswordForm from '../components/RenewPasswordForm'
import style from '../styles/MyPage.module.scss'
import moment from 'moment'

class MyPage extends Component {
  state = {
    profileVisible: false,
  }

  toggleProfileModal = () => {
    this.setState({ profileVisible: !this.state.profileVisible })
  }

  onUpload = file => {
    this.props.uploadAvatar(file)
    return false
  }

  onDeleteAvatar = () => {
    this.props.removeMyAvatar()
  }

  handleProfileSubmit = values => {
    this.props.updateProfile(values)
    this.toggleProfileModal()
  }

  handlePasswordSubmit = values => {
    this.props.changePassword(values)
  }

  componentDidMount() {
    this.props.setPageTitle('マイページ')
  }

  render() {
    const { isLoading, profile, avatarUrl, account } = this.props
    let { profileVisible } = this.state
    return (
      <div className={style.page}>
        <section className={style.contentSection}>
          <div className={style.sectionInnerWrap}>
            <Row type="flex" justify="space-between">
              <Col className={style.subContent} xs={24} md={7}>
                <h2>プロフィール写真</h2>
                <div style={{ textAlign: 'center' }}>
                  <Avatar src={avatarUrl} size={130} />

                  <div style={{ padding: 16 }}>
                    <Row type="flex" justify="center">
                      <Col>
                        <Upload name="avatar" beforeUpload={this.onUpload} disabled={isLoading}>
                          <Button type="primary" style={{ margin: 8 }}>
                            変更
                          </Button>
                        </Upload>
                      </Col>
                      <Col>
                        <Button
                          type="secondary"
                          style={{ margin: 8 }}
                          onClick={this.onDeleteAvatar}
                        >
                          削除
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col className={style.subContent} xs={24} md={16}>
                <Row type="flex" justify="space-between">
                  <Col>
                    <h2>プロフィール</h2>
                  </Col>
                  <Col>
                    <h2>
                      <Icon onClick={this.toggleProfileModal} type="edit" />
                    </h2>
                  </Col>
                </Row>

                <div className={style.profileTable}>
                  {Object.keys(profile).map((key, idx) => (
                    <Row type="flex" key={idx}>
                      <Col xs={24} md={10}>
                        <div className={style.profileKey}>{profile[key].label}</div>
                      </Col>
                      <Col xs={24} md={10}>
                        <div className={style.profileValue}>{profile[key].value}</div>
                      </Col>
                    </Row>
                  ))}
                </div>

                <div className={style.changePassword}>
                  <h2>パスワードを変更</h2>

                  <RenewPasswordForm onSubmit={this.handlePasswordSubmit} />
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <EditProfileModal
          visible={profileVisible}
          account={account}
          okText="更新"
          onOk={this.handleProfileSubmit}
          onCancel={this.toggleProfileModal}
        />
      </div>
    )
  }
}

const mapAccountToProfile = account => {
  let belonging = ''
  let position = ''
  let inCharge = ''
  if (account.accountType === 'staff') {
    belonging = account.staffMembership.nursery.name
    position = account.staffMembership.position
    inCharge = account.staffMembership.classInCharge
  } else {
    belonging = account.employment.organization.name
    position = account.employment.roleName
    inCharge = ''
  }

  return {
    name: { label: '名前', value: account.fullName },
    belonging: { label: '所属', value: belonging },
    position: { label: '役職', value: position },
    inCharge: { label: '担当クラス', value: inCharge },
    employeeCode: { label: '職員コード', value: account.employeeCode },
    birthday: { label: '誕生日', value: moment(account.birthday).format('YYYY年MM月DD日') },
    phone: { label: '電話番号', value: account.phone },
    email: { label: 'Eメール', value: account.email },
  }
}

const mapStateToProps = ({ app, account }) => ({
  isLoading: app.isLoading,
  account: account,
  profile: mapAccountToProfile(account),
  avatarUrl: account.avatarUrl,
})

const mapDispatchToProps = dispatch => ({
  setPageTitle: pageTitle => dispatch(setPageTitle(pageTitle)),
  uploadAvatar: file => dispatch(uploadMyAvatar(file)),
  removeMyAvatar: () => dispatch(destroyMyAvatar()),
  changePassword: params => dispatch(updatePassword(params)),
  updateProfile: params => dispatch(updateMyAccount(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyPage))

import axios from 'axios'
import { API_ROOT, AUTH_API_ROOT } from '../constants/url'

const auth = JSON.parse(localStorage.getItem('persist:auth'))
if (auth) {
  if (auth.authToken) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth.authToken.replace(/["]/g, '')
  }
}

export const setAuthHeader = authToken => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken
}

// APIリクエストであると明示するために、接頭辞にリクエストのタイプを付ける
// Account
export const getAccount = accountId => axios.get(`${API_ROOT}/accounts/${accountId}`)
export const getMyAccount = () => axios.get(`${API_ROOT}/accounts/me`)
export const patchMyAccount = params => axios.patch(`${API_ROOT}/accounts/me`, params)
export const patchMyPassword = params => axios.patch(`${AUTH_API_ROOT}/password/mine`, params)
export const postMyAvatar = params => axios.post(`${API_ROOT}/avatars/mine`, params)
export const deleteMyAvatar = () => axios.delete(`${API_ROOT}/avatars/mine`)

export const getStaffListForNursery = nurseryId =>
  axios.get(`${API_ROOT}/nurseries/${nurseryId}/staffs`)

// Nursery
export const getMyNursery = () => axios.get(`${API_ROOT}/nurseries/mine`)
export const patchMyNursery = params => axios.patch(`${API_ROOT}/nurseries/mine`, params)
export const getNursery = id => axios.get(`${API_ROOT}/nurseries/${id}`)
export const patchNursery = (id, params) => axios.patch(`${API_ROOT}/nurseries/${id}`, params)

// ClassRoom
export const getClassRoom = id => axios.get(`${API_ROOT}/class_rooms/${id}`)
export const getClassRoomList = nurseryId =>
  axios.get(`${API_ROOT}/nurseries/${nurseryId}/class_rooms`)
export const postClassRoom = (nurseryId, params) =>
  axios.post(`${API_ROOT}/nurseries/${nurseryId}/class_rooms`, params)
export const patchClassRoom = (id, params) => axios.patch(`${API_ROOT}/class_rooms/${id}`, params)

// Child
export const getChild = id => axios.get(`${API_ROOT}/children/${id}`)
export const getChildList = (nurseryId, page) =>
  axios.get(`${API_ROOT}/nurseries/${nurseryId}/children?page=${page}`)
export const getSearchedChild = (word, limit) =>
  axios.get(`${API_ROOT}/search/children?keyword=${encodeURI(word)}&limit=${limit}`)
export const postChild = (nurseryId, params) =>
  axios.post(`${API_ROOT}/nurseries/${nurseryId}/children`, params)
export const patchChild = (id, params) => axios.patch(`${API_ROOT}/children/${id}`, params)

// ChildAttendance
export const getChildAttendanceStatusForNursery = (nurseryId, dateStr) =>
  axios.get(`${API_ROOT}/nurseries/${nurseryId}/child_attendance_status?date=${dateStr}`)
export const getChildAttendanceListForChild = (childId, year, month, page) =>
  axios.get(
    `${API_ROOT}/children/${childId}/child_attendances?year=${year}&month=${month}&page=${page}`
  )
export const getChildAttendanceListForNursery = (nurseryId, date, page) =>
  axios.get(`${API_ROOT}/nurseries/${nurseryId}/child_attendances?date=${date}&page=${page}`)
export const getChildTodayAttendance = (childId, date) =>
  axios.get(`${API_ROOT}/children/${childId}/child_attendances?date=${date}`)
export const postChildAttendanceForChild = (childId, params) =>
  axios.post(`${API_ROOT}/children/${childId}/child_attendances`, params)
export const patchChildAttendance = (childAttndId, params) =>
  axios.patch(`${API_ROOT}/child_attendances/${childAttndId}`, params)
export const deleteChildAttendance = childAttndId =>
  axios.delete(`${API_ROOT}/child_attendances/${childAttndId}`)

// NurseryCorrespondence
export const getCorrespondenceListForNursery = (nurseryId, dateStr, classRoomId) =>
  axios.get(
    `${API_ROOT}/nurseries/${nurseryId}/nursery_correspondences?date=${dateStr}&class_room_id=${classRoomId}`
  )

// Correspondence
//// ParentReport
export const getParentReportListForNursery = (nurseryId, dateStr) =>
  axios.get(`${API_ROOT}/nurseries/${nurseryId}/parent_reports?date=${dateStr}`)
//// ChildDiary
export const postChildDiary = (childId, params) =>
  axios.post(`${API_ROOT}/children/${childId}/child_diaries`, params)
export const patchChildDiary = (id, params) =>
  axios.patch(`${API_ROOT}/child_diaries/${id}`, params)
export const deleteChildDiary = id => axios.delete(`${API_ROOT}/child_diaries/${id}`)
/// ChildContactBook
export const postChildContactBook = (childId, params) =>
  axios.post(`${API_ROOT}/children/${childId}/child_contact_books`, params)
export const patchChildContactBook = (id, params) =>
  axios.patch(`${API_ROOT}/child_contact_books/${id}`, params)
export const deleteChildContactBook = id => axios.delete(`${API_ROOT}/child_contact_books/${id}`)

// Diary
//// ClassRoomDiary
export const getClassRoomDiaryList = (classRoomId, dateStr) =>
  axios.get(`${API_ROOT}/class_rooms/${classRoomId}/class_room_diaries?date=${dateStr}`)
export const postClassRoomDiary = (classRoomId, dateStr, params) =>
  axios.post(`${API_ROOT}/class_rooms/${classRoomId}/class_room_diaries?date=${dateStr}`, params)
export const deleteClassRoomDiary = id => axios.delete(`${API_ROOT}/class_room_diaries/${id}`)
//// NurseryDiary
export const getNurseryDiaryList = (nurseryId, dateStr) =>
  axios.get(`${API_ROOT}/nurseries/${nurseryId}/nursery_diaries?date=${dateStr}`)
export const postNurseryDiary = (nurseryId, dateStr, params) =>
  axios.post(`${API_ROOT}/nurseries/${nurseryId}/nursery_diaries?date=${dateStr}`, params)
export const deleteNurseryDiary = id => axios.delete(`${API_ROOT}/nursery_diaries/${id}`)

// ChildHealth
//// Nap
export const getChildNapListForNursery = (nurseryId, dateStr, hour, classRoomId) =>
  axios.get(
    `${API_ROOT}/nurseries/${nurseryId}/child_nap_histories?date=${dateStr}&hour=${hour}&class_room_id=${classRoomId}`
  )
export const postChildNap = (childId, params) =>
  axios.post(`${API_ROOT}/children/${childId}/child_nap_histories`, params)
export const deleteChildNap = napId => axios.delete(`${API_ROOT}/child_nap_histories/${napId}`)
//// ChildBodyTemperature
export const getChildBodyTemperatureListForNursery = (nurseryId, dateStr, classRoomId) =>
  axios.get(
    `${API_ROOT}/nurseries/${nurseryId}/child_body_temperature_histories?date=${dateStr}&class_room_id=${classRoomId}`
  )
export const postChildBodyTemperature = (childId, params) =>
  axios.post(`${API_ROOT}/children/${childId}/child_body_temperature_histories`, params)
export const deleteChildBodyTemperature = bodyTempId =>
  axios.delete(`${API_ROOT}/child_body_temperature_histories/${bodyTempId}`)
//// ChildExcrement
export const getChildExcrementListForNursery = (nurseryId, dateStr, classRoomId) =>
  axios.get(
    `${API_ROOT}/nurseries/${nurseryId}/child_excrement_histories?date=${dateStr}&class_room_id=${classRoomId}`
  )
export const postChildExcrement = (childId, params) =>
  axios.post(`${API_ROOT}/children/${childId}/child_excrement_histories`, params)
export const deleteChildExcrement = excrementId =>
  axios.delete(`${API_ROOT}/child_excrement_histories/${excrementId}`)

// ChildMeal
export const getChildMealListForNursery = (nurseryId, dateStr, type, classRoomId) =>
  axios.get(
    `${API_ROOT}/nurseries/${nurseryId}/child_meals?date=${dateStr}&type=${type}&class_room_id=${classRoomId}`
  )
export const postChildMeal = (childId, params) =>
  axios.post(`${API_ROOT}/children/${childId}/child_meals`, params)
export const deleteChildMeal = mealId => axios.delete(`${API_ROOT}/child_meals/${mealId}`)
export const postAteHistory = (mealId, params) =>
  axios.post(`${API_ROOT}/child_meals/${mealId}/ate_histories`, params)
export const deleteAteHistory = ateId => axios.delete(`${API_ROOT}/ate_histories/${ateId}`)

// DishMenu
export const getDishMenuListForNursery = nurseryId =>
  axios.get(`${API_ROOT}/nurseries/${nurseryId}/dish_menus`)
export const postDishMenuForNursery = (nurseryId, params) =>
  axios.post(`${API_ROOT}/nurseries/${nurseryId}/dish_menus`, params)
export const patchDishMenu = (dishId, params) =>
  axios.patch(`${API_ROOT}/dish_menus/${dishId}`, params)
export const deleteDishMenu = dishId => axios.delete(`${API_ROOT}/dish_menus/${dishId}`)

// Play
export const postPlayForChild = (childAttendId, params) =>
  axios.post(`${API_ROOT}/child_attendances/${childAttendId}/plays`, params)
export const patchPlay = (playId, params) => axios.patch(`${API_ROOT}/plays/${playId}`, params)
export const deletePlay = playId => axios.delete(`${API_ROOT}/plays/${playId}`)

// PhysicalGrowthHistory
export const getPhysicalGrowthHistoryList = childId =>
  axios.get(`${API_ROOT}/children/${childId}/physical_growth_histories`)
export const postPhysicalGrowthHistory = (childId, params) =>
  axios.post(`${API_ROOT}/children/${childId}/physical_growth_histories`, params)
export const patchPhysicalGrowthHistory = (id, params) =>
  axios.patch(`${API_ROOT}/physical_growth_histories/${id}`, params)

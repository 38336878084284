import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form, InputNumber, Table } from 'antd'
import { fetchAllPhysicalHistoryListForChild, updatePhysicalHistory } from '../../actions/physicalGrowthHistory'
import moment from 'moment'

const EditableContext = React.createContext()

class PhysicalHistoryTableCell extends React.Component {
  getInput = () => {
    if (this.props.dataIndex === 'date') {
      return <div>{this.props.record.date.format('YYYY-MM-DD')}</div>
    }
    return <InputNumber min={0} max={300} step={0.1} />
  }

  getFormItemType = () => {
    const { dataIndex } = this.props
    if (dataIndex === 'date') { return 'object' }
    return 'number'
  }

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [{ type: this.getFormItemType() }],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          dataIndex === 'date' ? (
            record.date.format('YYYY-MM-DD')
          ) : ( children )
        )}
      </td>
    )
  }

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
  }
}

class PhysicalHistoryTable extends React.Component {
  state = {
    editingKey: '',
  }
  columns = [
    { title: '日付', dataIndex: 'date', width: '12%', editable: true },
    { title: '身長(cm)', dataIndex: 'height', width: '12%', editable: true },
    { title: '体重(kg)', dataIndex: 'weight', width: '12%', editable: true },
    { title: '胸囲(cm)', dataIndex: 'chest', width: '12%', editable: true },
    { title: '頭囲(cm)', dataIndex: 'headCircumference', width: '12%', editable: true },
    { title: 'カウプ指数', dataIndex: 'bmi', width: '12%', editable: true },
    // {
    //   title: '編集',
    //   dataIndex: 'operation',
    //   render: (value, record) => {
    //     const { editingKey } = this.state
    //     const editable = this.isEditingFor(record)
    //     if (editable) {
    //       return (
    //         <span>
    //           <EditableContext.Consumer>
    //             {form => (
    //               <Button
    //                 type="link"
    //                 style={{ marginRight: 8 }}
    //                 onClick={() => this.onUpdateRecord(form, record)}
    //               >
    //                 保存
    //               </Button>
    //             )}
    //           </EditableContext.Consumer>
    //           <Popconfirm title="編集内容を廃棄しますか？" onConfirm={() => this.cancel()}>
    //             <Button type="link">キャンセル</Button>
    //           </Popconfirm>
    //         </span>
    //       )
    //     } else {
    //       return (
    //         <Button type="link" disabled={editingKey !== ''} onClick={() => this.edit(record.id)}>
    //           編集
    //         </Button>
    //       )
    //     }
    //   },
    // },
  ]

  isEditingFor = record => record.id === this.state.editingKey

  cancel = () => {
    this.setState({ editingKey: '' })
  }

  onUpdateRecord = (form, recordData) => {
    const id = recordData.id

    form.validateFields((error, row) => {
      if (!error) {
        this.props.updatePhysicalHistory(id, { physical_growth_history: row })
          .then(_ => this.props.fetchAllPhysicalHistories(this.props.child.id))
      }
    })
    this.setState({ editingKey: '' })
  }

  edit = id => {
    this.setState({ editingKey: id })
  }

  componentDidMount() {
    if (this.props.child) {
      this.props.fetchAllPhysicalHistories(this.props.child.id)
    }
  }

  render() {
    const { physicalHistoryList } = this.props
    const components = {
      body: {
        cell: PhysicalHistoryTableCell,
      },
    }
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: 'number',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditingFor(record),
        }),
      }
    })

    return (
      <div>
        <EditableContext.Provider value={this.props.form}>
          <Table
            components={components}
            dataSource={physicalHistoryList}
            columns={columns}
            pagination={{
              onChange: this.cancel,
            }}
          />
        </EditableContext.Provider>
      </div>
    )
  }
}

const mapStateToProps = ({ app, child, physicalGrowthHistory }) => ({
  isLoading: app.isLoading,
  child: child.selected,
  physicalHistoryList: physicalGrowthHistory.list && physicalGrowthHistory.list.map((item, idx) => {
    item['key'] = idx
    item['date'] = moment(item.date)
    return item
  }),
})

const mapDispatchToProps = dispatch => ({
  fetchAllPhysicalHistories: childId => dispatch(fetchAllPhysicalHistoryListForChild(childId)),
  updatePhysicalHistory: (id, params) => dispatch(updatePhysicalHistory(id, params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'physicalHistoryTable' })(withRouter(PhysicalHistoryTable)))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, DatePicker, Form, InputNumber, message } from 'antd'
import PhysicalHistoryTable from '../../components/Tables/PhysicalHistoryTable'
import { createPhysicalHistory } from '../../actions/physicalGrowthHistory'
import style from '../../styles/ChildrenBookPage.module.scss'
import moment from 'moment'

class Physical extends Component {
  handleSubmit = e => {
    e.preventDefault()
    const { child } = this.props
    this.props.form.validateFields((err, values) => {
      if (!err && child.id) {
        this.props.addData(child.id, values).then(() => {
          message.success('記録を追加しました')
          this.props.form.resetFields()
          this.props.callBackFetch()
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <div>
        <div className={style.physicalFormWrap}>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item label="計測日">
              {getFieldDecorator('date', {
                initialValue: moment(),
                rules: [{ type: 'object', required: true, message: '入力は必須です' }],
              })(<DatePicker format="YYYY/MM/DD" />)}
            </Form.Item>
            <Form.Item label="身長">
              {getFieldDecorator('height', {
                rules: [{ required: true, message: '入力は必須です' }],
              })(<InputNumber placeholder="身長(cm)" style={{ minWidth: 150 }} />)}
            </Form.Item>
            <Form.Item label="体重">
              {getFieldDecorator('weight', {
                rules: [{ required: true, message: '入力は必須です' }],
              })(<InputNumber placeholder="体重(kg)" style={{ minWidth: 150 }} />)}
            </Form.Item>
            <Form.Item label="胸囲">
              {getFieldDecorator('chest', {
                rules: [{ required: true, message: '入力は必須です' }],
              })(<InputNumber placeholder="胸囲(cm)" style={{ minWidth: 150 }} />)}
            </Form.Item>
            <Form.Item label="頭囲">
              {getFieldDecorator('head_circumference', {
                rules: [{ required: true, message: '入力は必須です' }],
              })(<InputNumber placeholder="頭囲(cm)" style={{ minWidth: 150 }} />)}
            </Form.Item>

            <div style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit">
                記録を追加
              </Button>
            </div>
          </Form>
        </div>

        <div className={style.physicalTableWrap}>
          <PhysicalHistoryTable />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ app, child }) => ({
  child: child.selected,
  isLoading: app.isLoading,
})

const mapDispatchToProps = dispatch => ({
  addData: (childId, params) => dispatch(createPhysicalHistory(childId, params)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'NewPhysic' })(withRouter(Physical)))

import { transformSnakeToCamel } from '../utils/snakeCamelConverter'
import ChildDiary from './correspondence/childDiary'
import ChildContactBook from './correspondence/childContactBook'
import ParentReport from './correspondence/parentReport'
import Child from './child'

export default class NurseryCorrespondence {
  constructor({ childDiary, childContactBook, parentReport, child }) {
    this.childDiary = new ChildDiary(childDiary || {})
    this.childContactBook = new ChildContactBook(childContactBook || {})
    this.parentReport = new ParentReport(parentReport || {})
    this.child = new Child(child || {})
  }

  static newFromApiResponse = data => {
    return new NurseryCorrespondence(transformSnakeToCamel(data))
  }
}

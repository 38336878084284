import { transformSnakeToCamel } from '../utils/snakeCamelConverter'
import Organization from './organization'
import Account from './account'

export default class Nursery {
  constructor({
    id,
    name,
    type,
    nurseryCode,
    thumbnailUrl,
    postcode,
    state,
    address,
    building,
    phone,
    isOpenSaturday,
    beginsChildcareAt,
    endsChildcareAt,
    beginsStdChildcareAt,
    endsStdChildcareAt,
    beginsShortChildcareAt,
    endsShortChildcareAt,
    billingTiming,
    email,
    organizer,
    head,
  }) {
    this.id = id || ''
    this.name = name
    this.type = type
    this.nurseryCode = nurseryCode
    this.thumbnailUrl =
      thumbnailUrl || 'http://edulead.jp/assets/uploads/2019/06/%E6%9C%80%E5%BE%8C.jpg'
    this.postcode = postcode
    this.state = state
    this.address = address
    this.building = building
    this.phone = phone
    this.isOpenSaturday = isOpenSaturday
    this.beginsChildcareAt = beginsChildcareAt ? new Date(beginsChildcareAt) : null
    this.endsChildcareAt = endsChildcareAt ? new Date(endsChildcareAt) : null
    this.beginsStdChildcareAt = beginsStdChildcareAt ? new Date(beginsStdChildcareAt) : null
    this.endsStdChildcareAt = endsStdChildcareAt ? new Date(endsStdChildcareAt) : null
    this.beginsShortChildcareAt = beginsShortChildcareAt ? new Date(beginsShortChildcareAt) : null
    this.endsShortCholdcareAt = endsShortChildcareAt ? new Date(endsShortChildcareAt) : null
    this.billingTiming = billingTiming // Enum[ some...]
    this.email = email
    this.organizer = organizer ? new Organization(organizer) : null
    this.head = new Account(head || {})
  }

  static newFromApiResponse = data => {
    return new Nursery(transformSnakeToCamel(data))
  }
}

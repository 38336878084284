import { transformSnakeToCamel } from '../utils/snakeCamelConverter'
import Child from './child'
import AteHistory from './ateHistory'

export default class ChildMeal {
  constructor({ id, type, message, imageUrl, child, ateHistories }) {
    this.id = id
    this.type = type
    this.message = message
    this.imageUrl = imageUrl
    this.child = new Child(child)
    this.ateHistories = ateHistories.map(ate => new AteHistory(ate))
  }

  static newFromApiResponse = data => {
    return new ChildMeal(transformSnakeToCamel(data))
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Radio, Row, Select } from 'antd'
import { withRouter } from 'react-router-dom'
import {
  createOrUpdateChildBodyTemperature,
  createOrUpdateChildExcrement,
  createOrUpdateChildNap,
  fetchChildBodyTemperatureList,
  fetchChildExcrementList,
  fetchChildNapList,
  removeChildBodyTemperature,
  removeChildExcrement,
  removeChildNap,
} from '../../actions/childHealth'
import { fetchMyNursery } from '../../actions/nursery'
import ChildNapTable from '../../components/Tables/ChildNapTable'
import ChildExcrementTable from '../../components/Tables/ChildExcrementTable'
import ChildBodyTemperatureTable from '../../components/Tables/ChildBodyTemperatureTable'
import style from '../../styles/DailyBoardPage.module.scss'
import moment from 'moment'
import { range } from '../../utils/range'

const { Option } = Select
const fiveSteps = range(0, 60, 5)
const getDoubleDigestNumber = number => {
  return ('0' + number).slice(-2)
}
const hours = [...Array(24).keys()].map(i => `${getDoubleDigestNumber(i)}:00`)

class HealthPage extends Component {
  state = {
    selectedClassName: '全て',
    selectedHealthMenu: 'bodyTemp',
    selectedTimeStr: moment(new Date())
      .minutes(0)
      .format('HH:mm'),
    dataDidLoaded: false,
    _selectedDate: null,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.nursery && !prevState.dataDidLoaded) {
      const { nursery, selectedDate } = nextProps
      nextProps.fetchNapList(nursery.id, selectedDate, null)
      nextProps.fetchBodyTempList(nursery.id, selectedDate, null)
      nextProps.fetchExcrementList(nursery.id, selectedDate, null)
      return { dataDidLoaded: true }
    }
    if (nextProps.nursery) {
      if (nextProps.selectedDate !== prevState._selectedDate) {
        const { nursery, selectedDate } = nextProps
        nextProps.fetchNapList(nursery.id, selectedDate, null)
        nextProps.fetchBodyTempList(nursery.id, selectedDate, null)
        nextProps.fetchExcrementList(nursery.id, selectedDate, null)
        return { dataDidLoaded: true, _selectedDate: selectedDate }
      }
    }
    return null
  }

  getStartsAtList = () => {
    const { selectedTimeStr } = this.state
    const { selectedDate } = this.props
    return [...Array(fiveSteps.length)].map(minute =>
      moment(
        selectedTimeStr
          .year(moment(selectedDate).year())
          .month(moment(selectedDate).month())
          .date(moment(selectedDate).date())
          .minutes(Number(minute))
      )
    )
  }

  onTimeChange = value => {
    this.setState({ selectedTimeStr: value })
    this.fetchData(value)
  }

  fetchData = (timeStr = null) => {
    const { nursery, classRoomList, selectedDate } = this.props
    const { selectedClassName, selectedTimeStr } = this.state
    const hour = Number((timeStr || selectedTimeStr).split(':')[0])
    let classRoom = { id: null }
    if (selectedClassName !== '全て') {
      classRoom = classRoomList.filter(klass => klass.name === selectedClassName)[0]
    }
    if (nursery) {
      this.props.fetchNapList(nursery.id, selectedDate, hour, classRoom.id)
      this.props.fetchBodyTempList(nursery.id, selectedDate, classRoom.id)
      this.props.fetchExcrementList(nursery.id, selectedDate, classRoom.id)
    } else {
      this.props.fetchNursery().then(action => {
        const nursery = action.payload
        this.props.fetchNapList(nursery.id, selectedDate, hour, classRoom.id)
        this.props.fetchBodyTempList(nursery.id, selectedDate, classRoom.id)
        this.props.fetchExcrementList(nursery.id, selectedDate, classRoom.id)
      })
    }
  }

  putBodyTemperature = (childId, tempId, keyType) => value => {
    const { nursery, classRoomList, selectedDate } = this.props
    const { selectedClassName } = this.state
    let classRoom = { id: null }
    let params = {}
    if (selectedClassName !== '全て') {
      classRoom = classRoomList.filter(klass => klass.name === selectedClassName)[0]
    }
    if (keyType === 'degree') {
      params = { id: tempId, degree: value, executed_at: moment(selectedDate).format() }
    } else if (keyType === 'executedAt') {
      params = { id: tempId, executed_at: value.format() }
    }
    this.props
      .putBodyTemp(childId, {
        child_body_temperature_history: params,
      })
      .then(_ => this.props.fetchBodyTempList(nursery.id, selectedDate, classRoom.id))
  }

  removeBodyTemperature = tempId => () => {
    if (tempId) {
      const { nursery, classRoomList, selectedDate } = this.props
      const { selectedClassName } = this.state
      let classRoom = { id: null }
      if (selectedClassName !== '全て') {
        classRoom = classRoomList.filter(klass => klass.name === selectedClassName)[0]
      }
      this.props
        .removeBodyTemp(tempId)
        .then(_ => this.props.fetchBodyTempList(nursery.id, selectedDate, classRoom.id))
    }
  }

  putExcrement = (childId, excrementId, keyType) => value => {
    const { nursery, classRoomList, selectedDate } = this.props
    const { selectedClassName } = this.state
    let classRoom = { id: null }
    let params = {}
    if (selectedClassName !== '全て') {
      classRoom = classRoomList.filter(klass => klass.name === selectedClassName)[0]
    }
    if (keyType === 'type') {
      params = { id: excrementId, type: value, executed_at: moment(selectedDate).format() }
    } else if (keyType === 'executedAt') {
      params = { id: excrementId, executed_at: value.format() }
    }
    this.props
      .putExcrement(childId, {
        child_excrement_history: params,
      })
      .then(_ => this.props.fetchExcrementList(nursery.id, selectedDate, classRoom.id))
  }

  removeExcrement = excrementId => () => {
    if (excrementId) {
      const { nursery, classRoomList, selectedDate } = this.props
      const { selectedClassName } = this.state
      let classRoom = { id: null }
      if (selectedClassName !== '全て') {
        classRoom = classRoomList.filter(klass => klass.name === selectedClassName)[0]
      }
      this.props
        .removeExcrement(excrementId)
        .then(_ => this.props.fetchExcrementList(nursery.id, selectedDate, classRoom.id))
    }
  }

  putNap = (childId, napId, values) => {
    const { nursery, classRoomList, selectedDate } = this.props
    const { selectedClassName, selectedTimeStr } = this.state
    const hour = Number(selectedTimeStr.split(':')[0])
    let classRoom = { id: null }
    if (selectedClassName !== '全て') {
      classRoom = classRoomList.filter(klass => klass.name === selectedClassName)[0]
    }
    values['id'] = napId
    this.props
      .putNap(childId, values)
      .then(_ => this.props.fetchNapList(nursery.id, selectedDate, hour, classRoom.id))
  }

  removeNap = napId => {
    if (napId) {
      const { nursery, classRoomList, selectedDate } = this.props
      const { selectedClassName, selectedTimeStr } = this.state
      const hour = Number(selectedTimeStr.split(':')[0])
      let classRoom = { id: null }
      if (selectedClassName !== '全て') {
        classRoom = classRoomList.filter(klass => klass.name === selectedClassName)[0]
      }
      this.props
        .removeNap(napId)
        .then(_ => this.props.fetchNapList(nursery.id, selectedDate, hour, classRoom.id))
    }
  }

  onClassChange = value => {
    this.setState({ selectedClassName: value })
  }

  onMenuChange = e => this.setState({ selectedHealthMenu: e.target.value })

  menuLabel = () => {
    const { selectedHealthMenu } = this.state
    if (selectedHealthMenu === 'bodyTemp') {
      return '検温'
    } else if (selectedHealthMenu === 'nap') {
      return '午睡'
    } else {
      return '排便'
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.selectedClassName !== this.state.selectedClassName ||
      prevProps.selectedDate !== this.props.selectedDate
    ) {
      this.fetchData()
    }
  }

  render() {
    const { classRoomList, napList, bodyTempList, excrementList, selectedDate } = this.props
    const { selectedClassName, selectedHealthMenu, selectedTimeStr } = this.state
    let Table = null
    if (selectedHealthMenu === 'bodyTemp') {
      Table = ChildBodyTemperatureTable({
        dataSource: bodyTempList,
        putBodyTemperature: this.putBodyTemperature,
        removeBodyTemperature: this.removeBodyTemperature,
      })
    } else if (selectedHealthMenu === 'nap') {
      Table = ChildNapTable({
        dataSource: napList,
        putNap: this.putNap,
        removeNap: this.removeNap,
        sinceDateTime: moment(
          `${moment(selectedDate).format('YYYY-MM-DD')}T${selectedTimeStr}`,
          'YYYY-MM-DDTHH:mm'
        ),
      })
    } else if (selectedHealthMenu === 'excrement') {
      Table = ChildExcrementTable({
        dataSource: excrementList,
        putExcrement: this.putExcrement,
        removeExcrement: this.removeExcrement,
      })
    }

    return (
      <div className={style.page}>
        <section className={style.contentSection}>
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              <Select
                style={{ minWidth: 160 }}
                onChange={this.onClassChange}
                value={selectedClassName}
                defaultValue={selectedClassName}
                placeholder="  -  "
              >
                {[{ name: '全て' }, ...classRoomList].map((classRoom, idx) => (
                  <Option key={idx} value={classRoom.name}>
                    {classRoom.name}
                  </Option>
                ))}
              </Select>
              {selectedHealthMenu === 'nap' && (
                <Select
                  style={{ minWidth: 100, marginLeft: 16 }}
                  onChange={this.onTimeChange}
                  initialValue={selectedTimeStr}
                  value={selectedTimeStr}
                  startsAtList={this.getStartsAtList}
                  placeholder="  -  "
                >
                  {hours.map((hourStr, idx) => (
                    <Option key={idx} value={hourStr}>
                      {hourStr}時台
                    </Option>
                  ))}
                </Select>
              )}
            </Col>
            <Col>
              <Radio.Group
                value={selectedHealthMenu}
                buttonStyle="solid"
                onChange={this.onMenuChange}
              >
                <Radio.Button value="bodyTemp">検温</Radio.Button>
                <Radio.Button value="nap">午睡</Radio.Button>
                <Radio.Button value="excrement">排便</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </section>

        <section className={style.contentSection}>
          <h2>{this.menuLabel()}</h2>

          {Table}
        </section>
      </div>
    )
  }
}

const mapStateToProps = ({ app, nursery, classRoom, childHealth }) => ({
  selectedDate: app.selectedDate,
  nursery: nursery.mine,
  classRoomList: classRoom.list,
  napList: childHealth.napList,
  bodyTempList: childHealth.bodyTempList,
  excrementList: childHealth.excrementList,
})

const mapDispatchToProps = dispatch => ({
  fetchNursery: () => dispatch(fetchMyNursery()),
  fetchNapList: (nurseryId, date, hour, classRoomId) =>
    dispatch(fetchChildNapList(nurseryId, date, hour, classRoomId)),
  fetchBodyTempList: (nurseryId, date, classRoomId) =>
    dispatch(fetchChildBodyTemperatureList(nurseryId, date, classRoomId)),
  fetchExcrementList: (nurseryId, date, classRoomId) =>
    dispatch(fetchChildExcrementList(nurseryId, date, classRoomId)),
  putBodyTemp: (childId, params) => dispatch(createOrUpdateChildBodyTemperature(childId, params)),
  putExcrement: (childId, params) => dispatch(createOrUpdateChildExcrement(childId, params)),
  putNap: (childId, params) => dispatch(createOrUpdateChildNap(childId, params)),
  removeBodyTemp: id => dispatch(removeChildBodyTemperature(id)),
  removeExcrement: id => dispatch(removeChildExcrement(id)),
  removeNap: id => dispatch(removeChildNap(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HealthPage))

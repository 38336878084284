import React from 'react'
import { Icon, Input, Tag } from 'antd'
import { TweenOneGroup } from 'rc-tween-one'

export default class AllergiesInput extends React.Component {
  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return {
        ...(nextProps.value || []),
      }
    }
    return null
  }

  constructor(props) {
    super(props)

    const value = props.value || []
    this.state = {
      inputVisible: false,
      inputValue: '',
      tags: value,
    }
  }

  handleClose = removedTag => {
    const tags = this.state.tags.filter(tag => tag !== removedTag)
    this.setState({ tags })
    this.triggerChange(tags)
  }

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus())
  }

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value })
  }

  handleInputConfirm = () => {
    const { inputValue } = this.state
    const { tags } = this.state
    let newTags = []
    if (inputValue && tags.indexOf(inputValue) === -1) {
      newTags = [...tags, inputValue]
    }
    this.setState({
      tags: newTags,
      inputVisible: false,
      inputValue: '',
    })
    this.triggerChange(newTags)
  }

  saveInputRef = input => (this.input = input)

  triggerChange = changedValue => {
    const { onChange } = this.props
    if (onChange) {
      onChange(changedValue)
    }
  }

  forMap = tag => {
    const tagElem = (
      <Tag
        closable
        onClose={e => {
          e.preventDefault()
          this.handleClose(tag)
        }}
      >
        {tag}
      </Tag>
    )
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    )
  }

  componentDidMount() {
    const { value } = this.props
    this.setState({ tags: value })
  }

  render() {
    const { tags, inputVisible, inputValue } = this.state
    const tagChild = tags.map(this.forMap)
    return (
      <div>
        <div style={{ marginBottom: 16 }}>
          <TweenOneGroup
            enter={{
              scale: 0.8,
              opacity: 0,
              type: 'from',
              duration: 100,
              onComplete: e => {
                e.target.style = ''
              },
            }}
            leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
            appear={false}
          >
            {tagChild}
          </TweenOneGroup>
        </div>
        {inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
            <Icon type="plus" /> アレルギーを追加
          </Tag>
        )}
      </div>
    )
  }
}

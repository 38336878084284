import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'antd'
import SelectAttendanceTypeCell from '../TableCells/ChildAttendance/SelectAttendanceTypeCell'
import NoticeCell from '../TableCells/ChildAttendance/NoticeCell'
import BeginsAtCell from '../TableCells/ChildAttendance/BeginsAtCell'
import BeginsPlayAtCell from '../TableCells/ChildAttendance/BeginsPlayAtCell'
import EndsPlayAtCell from '../TableCells/ChildAttendance/EndsPlayAtCell'
import EndsAtCell from '../TableCells/ChildAttendance/EndsAtCell'
import {
  addChildAttendanceForChild,
  addPlayForChildAttendance,
  deleteChildAttendanceForChild,
  deletePlayForChildAttendance,
  fetchChildAttendanceListForChild,
  updateChildAttendanceForChild,
  updatePlayForChildAttendance,
} from '../../actions/childAttendance'
import moment from 'moment'

const ShareContext = React.createContext()

// TODO: Play全体ではなく、外出時間に絞る
const _columns = [
  { title: '日付', dataIndex: 'date', width: '10%' },
  { title: '出欠', dataIndex: 'attendanceType', width: '8%' },
  { title: '登園', dataIndex: 'beginsAt', width: '8%' },
  { title: '外出', dataIndex: 'beginsPlayAt', width: '8%' },
  { title: '戻り', dataIndex: 'endsPlayAt', width: '8%' },
  { title: '降園', dataIndex: 'endsAt', width: '8%' },
  { title: '保育時間', dataIndex: 'totalCareTime', width: '8%' },
  { title: '外出時間', dataIndex: 'totalPlayTime', width: '8%' },
  { title: '連絡/メモ', dataIndex: 'notice', width: '34%' },
]

class ChildDetailAttendanceTableCell extends React.Component {
  renderCell = () => {
    const {
      createAttendance,
      updateAttendance,
      deleteAttendance,
      createPlay,
      updatePlay,
      deletePlay,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props
    let element
    if (dataIndex === 'date') {
      element = <div style={{ minWidth: 100 }}>{moment(record.date).format('MM月DD日(ddd)')}</div>
    } else if (dataIndex === 'attendanceType') {
      element = <SelectAttendanceTypeCell cellData={record.attendanceType} record={record} />
    } else if (dataIndex === 'notice') {
      element = <NoticeCell cellData={record.notice} record={record} />
    } else if (dataIndex === 'beginsAt') {
      element = (
        <BeginsAtCell
          cellData={record.beginsAt}
          record={record}
          addAttendance={createAttendance}
          updateAttendance={updateAttendance}
          deleteAttendance={deleteAttendance}
        />
      )
    } else if (dataIndex === 'beginsPlayAt') {
      element = (
        <BeginsPlayAtCell
          cellData={record.beginsPlayAt}
          record={record}
          addPlay={createPlay}
          updatePlay={updatePlay}
          deletePlay={deletePlay}
        />
      )
    } else if (dataIndex === 'endsPlayAt') {
      element = (
        <EndsPlayAtCell cellData={record.endsPlayAt} record={record} updatePlay={updatePlay} />
      )
    } else if (dataIndex === 'endsAt') {
      element = (
        <EndsAtCell cellData={record.endsAt} record={record} updateAttendance={updateAttendance} />
      )
    } else if (dataIndex === 'totalCareTime') {
      element = <div>{record.totalCareTime || '--'}</div>
    } else if (dataIndex === 'totalPlayTime') {
      element = <div>{record.totalPlayTime || '--'}</div>
    }

    return <td {...restProps}>{element}</td>
  }

  render() {
    return <ShareContext.Consumer>{this.renderCell}</ShareContext.Consumer>
  }
}

class ChildDetailAttendanceTable extends React.Component {
  state = {
    pagination: {
      defaultPageSize: 20,
      current: 1,
    },
    didInitPagination: false,
  }

  onChange = (pagination, filters, sorter) => {
    const { nursery, selectedDate } = this.props
    const pager = { ...this.state.pagination }
    pager.current = pagination.current
    this.setState({ pagination: pager })
    this.props.fetchAllAttendances(
      nursery.id,
      selectedDate.year,
      selectedDate.month,
      pagination.current
    )
  }

  addAttendance = (childId, params) => {
    return this.props.addAttendance(childId, params).then(_ => this._refreshData())
  }

  updateAttendance = (id, params) => {
    return this.props.updateAttendance(id, params).then(_ => this._refreshData())
  }

  deleteAttendance = id => {
    return this.props.deleteAttendance(id).then(_ => this._refreshData())
  }

  addPlay = (childId, params) => {
    return this.props.addPlay(childId, params).then(_ => this._refreshData())
  }

  updatePlay = (id, params) => {
    return this.props.updatePlay(id, params).then(_ => this._refreshData())
  }

  deletePlay = id => {
    return this.props.deletePlay(id).then(_ => this._refreshData())
  }

  _refreshData = () => {
    const { child, selectedDate } = this.props
    const { pagination } = this.state
    this.props.fetchAllAttendances(
      child.id,
      selectedDate.year(),
      selectedDate.month() + 1,
      pagination.current
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { didInitPagination, pagination } = this.state
    const { attendanceList } = this.props
    if (!didInitPagination && attendanceList[0]) {
      const _pag = { ...pagination }
      _pag.total = attendanceList[0].totalNum
      this.setState({
        didInitPagination: true,
        pagination: _pag,
      })
    }
  }

  render() {
    const { attendanceList, isLoading } = this.props
    const components = {
      body: {
        cell: ChildDetailAttendanceTableCell,
      },
    }
    const columns = _columns.map(col => {
      return {
        ...col,
        onCell: record => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          createAttendance: this.addAttendance,
          updateAttendance: this.updateAttendance,
          deleteAttendance: this.deleteAttendance,
          createPlay: this.addPlay,
          updatePlay: this.updatePlay,
          deletePlay: this.deletePlay,
        }),
      }
    })
    return (
      <div>
        <ShareContext.Provider value={this.props.form}>
          <Table
            loading={isLoading}
            components={components}
            dataSource={attendanceList}
            onChange={this.onChange}
            columns={columns}
            pagination={this.state.pagination}
          />
        </ShareContext.Provider>
      </div>
    )
  }
}

const mapStateToProps = ({ app, childAttendance, child, nursery }) => ({
  isLoading: app.isLoading,
  selectedDate: moment(app.selectedDate),
  child: child.selected,
  nursery: nursery.mine,
  attendanceList: childAttendance.listForChild.map((item, idx) => {
    return {
      key: idx,
      child: child.selected,
      date: item.date,
      attendanceExist: true,
      attendanceId: item.id,
      lastPlayId: item.lastPlayId,
      attendanceType: item.type,
      notice: item.notice,
      beginsAt: item.beginsAt,
      endsAt: item.endsAt,
      beginsPlayAt: item.beginsPlayAt,
      endsPlayAt: item.endsPlayAt,
      plays: item.plays,
      totalCareTime: item.totalCareTime(),
      totalPlayTime: item.totalPlayTime(),
    }
  }),
})

const mapDispatchToProps = dispatch => ({
  fetchAllAttendances: (childId, year, month, page = 1) =>
    dispatch(fetchChildAttendanceListForChild(childId, year, month, page)),
  addAttendance: (childId, params) => dispatch(addChildAttendanceForChild(childId, params)),
  updateAttendance: (attendId, params) => dispatch(updateChildAttendanceForChild(attendId, params)),
  deleteAttendance: attendanceId => dispatch(deleteChildAttendanceForChild(attendanceId)),
  addPlay: (attendId, params) => dispatch(addPlayForChildAttendance(attendId, params)),
  updatePlay: (playId, params) => dispatch(updatePlayForChildAttendance(playId, params)),
  deletePlay: id => dispatch(deletePlayForChildAttendance(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChildDetailAttendanceTable)

import React from 'react'
import ChildBodyTemperatureTableCell from '../TableCells/ChildBodyTemperatureTableCell'

export default ({ dataSource, putBodyTemperature, removeBodyTemperature }) => (
  <div>
    {dataSource.map((data, idx) => (
      <ChildBodyTemperatureTableCell
        key={idx}
        data={data}
        putBodyTemperature={putBodyTemperature}
        removeBodyTemperature={removeBodyTemperature}
      />
    ))}
  </div>
)

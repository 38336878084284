import { transformSnakeToCamel } from '../utils/snakeCamelConverter'

export default class NurseryCertification {
  constructor({
    childminder,
    nurseryTeacher,
    childcareAssistance,
    homeChildcare,
    homeChildcareAssistance,
    nurse,
    publicHealthNurse,
    elementarySchoolTeacher,
    nursingTeacher,
    kindergartenTeacher,
    cook,
    nutritionist,
    other,
  }) {
    this.childminder = childminder
    this.nurseryTeacher = nurseryTeacher
    this.childcareAssistance = childcareAssistance
    this.homeChildcare = homeChildcare
    this.homeChildcareAssistance = homeChildcareAssistance
    this.nurse = nurse
    this.publicHealthNurse = publicHealthNurse
    this.elementarySchoolTeacher = elementarySchoolTeacher
    this.nursingTeacher = nursingTeacher
    this.kindergartenTeacher = kindergartenTeacher
    this.cook = cook
    this.nutritionist = nutritionist
    this.other = other
  }

  static newFromApiResponse = data => {
    return new NurseryCertification(transformSnakeToCamel(data))
  }
}

import React from 'react'
import { Button, Form, Radio, Switch } from 'antd'
import moment from 'moment'

class PutChildNapForm extends React.Component {
  onSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      const { account, data } = this.props
      if (!err) {
        if (!values['staff_id'] && account.staffMembership) {
          values['staff_id'] = account.staffMembership.id
        }
        values['starts_at'] = moment(data.startsAt).format()
        this.props.onSubmit({
          child_nap_history: values,
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { data } = this.props
    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <Form.Item label="">
            {getFieldDecorator('direction', {
              initialValue: data.direction,
              rules: [{ required: true, message: '選択してください' }],
            })(
              <Radio.Group>
                <Radio.Button value="left">左向き</Radio.Button>
                <Radio.Button value="supine">仰向け</Radio.Button>
                <Radio.Button value="right">右向き</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label="うつぶせ直し">
            {getFieldDecorator('fix_prone', {
              initialValue: data.fixProne,
              valuePropName: 'checked',
            })(<Switch />)}
          </Form.Item>
          <Form.Item label="起床">
            {getFieldDecorator('waked_up', {
              initialValue: data.wakedUp,
              valuePropName: 'checked',
            })(<Switch />)}
          </Form.Item>
          {/*TODO: 職員APIができてから*/}
          {/*<Form.Item label="記入者">*/}
          {/*  {getFieldDecorator('targets', {*/}
          {/*    initialValue: data.staff ? data.staff.id : account.id,*/}
          {/*    rules: [{ type: 'array', required: true, message: '選択してください' }],*/}
          {/*  })(*/}
          {/*    <Select placeholder="記入者">*/}
          {/*      {staffList.map(staff => (*/}
          {/*        <Option key={staff.id} value={staff.id}>*/}
          {/*          {staff.fullName}*/}
          {/*        </Option>*/}
          {/*      ))}*/}
          {/*    </Select>*/}
          {/*  )}*/}
          {/*</Form.Item>*/}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              記録
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

export default Form.create({ name: 'PutChildNapForm' })(PutChildNapForm)

import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { AutoComplete, Button, Input } from 'antd'
import { searchChild, setChild } from '../../actions/child'
import routes from '../../routes'

const { Search } = Input
const { Option } = AutoComplete

class SearchChild extends React.Component {
  onSelect = value => {
    // value: id
    const { children } = this.props
    const _res = children.filter(item => item.id === value)
    if (_res.length === 0) {
      return
    }

    if (this.props.onSelected) {
      this.props.onSelected(_res[0])
    } else {
      this.props.push(routes.childrenBook(_res[0].id))
    }
  }

  onChange = value => {
    if (!value) {
      this.props.onClear && this.props.onClear()
    }
  }

  handleSearch = value => {
    this.props.searchChild(value)
  }

  renderOption = item => {
    return (
      <Option key={item.id} text={item.fullName}>
        <Button type="link">{item.fullName}</Button>
      </Option>
    )
  }

  render() {
    const { minWidth, children } = this.props
    return (
      <AutoComplete
        className="global-search"
        style={{ minWidth: minWidth || 250 }}
        dataSource={children.map(this.renderOption)}
        onSelect={this.onSelect}
        onChange={this.onChange}
        onSearch={this.handleSearch}
        placeholder="名前で検索"
        optionLabelProp="text"
        allowClear={true}
      >
        <Search />
      </AutoComplete>
    )
  }
}

const mapStateToProps = ({ child }) => ({
  children: child.resultList,
})

const mapDispatchToProps = dispatch => ({
  setSelectedChild: child => dispatch(setChild(child)),
  searchChild: (word, limit = 5) => dispatch(searchChild(word, limit)),
  push: path => dispatch(push(path)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchChild)

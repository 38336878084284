import React, { Component } from 'react'
import { Button, Col, Icon, Row, Table } from 'antd'
import { addKeyForDataSource } from '../../utils/antHelper'
import ChildMiniCard from '../Cards/ChildMiniCard'
import AteZero from '../../constants/images/ate0.svg'
import AteLittle from '../../constants/images/ate25.svg'
import AteHalf from '../../constants/images/ate50.svg'
import AteAlmost from '../../constants/images/ate75.svg'
import AtePerfect from '../../constants/images/ate100.svg'

export default class ChildMealTable extends Component {
  renderStatusComponent = status => {
    if (status === 'perfect') {
      return <Icon style={style.ateStatusIcon} component={AtePerfect} />
    } else if (status === 'almost') {
      return <Icon style={style.ateStatusIcon} component={AteAlmost} />
    } else if (status === 'half') {
      return <Icon style={style.ateStatusIcon} component={AteHalf} />
    } else if (status === 'little') {
      return <Icon style={style.ateStatusIcon} component={AteLittle} />
    } else if (status === 'zero') {
      return <Icon style={style.ateStatusIcon} component={AteZero} />
    } else {
      return <Icon style={style.ateStatusIcon} component={AteZero} />
    }
  }

  render() {
    const { dataSource } = this.props
    const columns = [
      {
        title: '園児',
        dataIndex: 'child',
        render: value => <ChildMiniCard child={value} />,
      },
      {
        title: 'メニュー',
        dataIndex: 'ateHistories',
        render: value => {
          return (
            <div>
              {value.map(data => {
                const { id, dishMenu, alternativeDishMenu, isAlternative, status } = data
                return (
                  <Row key={id} type="flex">
                    <Col>
                      <div style={isAlternative ? style.strikeLabel : style.label}>
                        {dishMenu.name}
                      </div>
                      {isAlternative && <div style={style.label}>{alternativeDishMenu.name}</div>}
                      {!isAlternative && (
                        <Button type="primary" size="small">
                          代替食を追加
                        </Button>
                      )}
                    </Col>
                    <Col>{this.renderStatusComponent(status)}</Col>
                  </Row>
                )
              })}
            </div>
          )
        },
      },
      {
        title: '保護者への報告',
        dataIndex: 'message',
      },
      {
        title: '画像',
        dataIndex: 'imageUrl',
        render: value =>
          value ? (
            <div style={style.imageZone}>
              <img src={value} alt="食事画像" width={100} />
            </div>
          ) : (
            <div style={style.imageZone}>
              <Button shape="circle" type="ghost" icon="plus-circle" />
            </div>
          ),
      },
    ]
    return <Table bordered dataSource={addKeyForDataSource(dataSource)} columns={columns} />
  }
}

const style = {
  label: {},
  strikeLabel: {
    textDecoration: 'line-through',
  },
  imageZone: {
    textAlign: 'center',
  },
  ateStatusIcon: {
    color: '#BFC795',
    fontSize: 30,
  },
}

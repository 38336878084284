import { START_LOADING, STOP_LOADING } from '../constants/actionTypes'
import moment from 'moment'
import { patchChildContactBook, patchChildDiary, postChildContactBook, postChildDiary } from '../services/v1ApiRequest'
import { notification } from 'antd'
import { setServerError } from './apps'

export const createChildDiary = (childId, date, params) => dispatch => {
  dispatch({ type: START_LOADING })
  params['child_diary']['date'] = moment(date).format('YYYY-MM-DD')
  return postChildDiary(childId, params)
    .then(_ => {
      notification.success({ message: 'Success' })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const updateChildDiary = (id, params) => dispatch => {
  dispatch({ type: START_LOADING })
  return patchChildDiary(id, params)
    .then(_ => {
      notification.success({ message: 'Success' })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const createChildContactBook = (childId, date, params) => dispatch => {
  dispatch({ type: START_LOADING })
  params['child_contact_book']['date'] = moment(date).format('YYYY-MM-DD')
  return postChildContactBook(childId, params)
    .then(_ => {
      notification.success({ message: 'Success' })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const updateChildContactBook = (id, params) => dispatch => {
  dispatch({ type: START_LOADING })
  return patchChildContactBook(id, params)
    .then(_ => {
      notification.success({ message: 'Success' })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

import { SET_CHILD_DINNER_LIST, SET_CHILD_LUNCH_LIST, SET_CHILD_SNACK_LIST, SIGN_OUT } from '../constants/actionTypes'

const DEFAULT_STATE = {
  lunchList: [],
  snackList: [],
  dinnerList: [],
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_CHILD_LUNCH_LIST:
      return { ...state, lunchList: action.payload }
    case SET_CHILD_SNACK_LIST:
      return { ...state, snackList: action.payload }
    case SET_CHILD_DINNER_LIST:
      return { ...state, dinnerList: action.payload }
    case SIGN_OUT:
      return DEFAULT_STATE
    default:
      return state
  }
}

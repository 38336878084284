import {
  SET_ACCOUNT,
  SET_ACCOUNT_ERRORS,
  SET_OTHER_ACCOUNT,
  SET_PROFILE,
  SIGN_IN_ACCOUNT,
  SIGN_OUT,
} from '../constants/actionTypes'
import Account from '../models/account'

const DEFAULT_STATE = {
  ...new Account({}),
  authToken: '',
  isLoggedIn: false,
  isLoaded: false,
  errors: [],
  targetAccount: null,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_ACCOUNT:
      return { ...state, ...action.payload, isLoaded: false }
    case SET_PROFILE:
      return { ...state, ...action.payload, isLoaded: true }
    case SET_OTHER_ACCOUNT:
      return { ...state, targetAccount: action.payload }
    case SET_ACCOUNT_ERRORS:
      return { ...state, errors: action.payload, isLoaded: false }
    case SIGN_IN_ACCOUNT:
      return { ...state, ...action.payload, isLoaded: false }
    case SIGN_OUT:
      return DEFAULT_STATE
    default:
      return state
  }
}

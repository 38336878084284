import {
  SET_CHILD_BODY_TEMPERATURE_LIST,
  SET_CHILD_EXCREMENT_LIST,
  SET_CHILD_NAP_LIST,
  SIGN_OUT,
} from '../constants/actionTypes'

const DEFAULT_STATE = {
  napList: [],
  bodyTempList: [],
  excrementList: [],
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_CHILD_NAP_LIST:
      return { ...state, napList: action.payload }
    case SET_CHILD_BODY_TEMPERATURE_LIST:
      return { ...state, bodyTempList: action.payload }
    case SET_CHILD_EXCREMENT_LIST:
      return { ...state, excrementList: action.payload }
    case SIGN_OUT:
      return DEFAULT_STATE
    default:
      return state
  }
}

import React from 'react'
import ChildNapTableCell from '../TableCells/ChildNapTableCell'

export default ({ dataSource, putNap, removeNap, sinceDateTime }) => (
  <div>
    {dataSource.map((data, idx) => (
      <ChildNapTableCell
        key={idx}
        data={data}
        putNap={putNap}
        removeNap={removeNap}
        sinceDateTime={sinceDateTime}
      />
    ))}
  </div>
)

import Account from '../account'
import { transformSnakeToCamel } from '../../utils/snakeCamelConverter'

export default class ClassRoomDiary {
  constructor({ id, status, date, writer, author, focus, childrenDoing, evaluation, forParent }) {
    this.id = id
    this.status = status
    this.date = date ? new Date(date) : null
    this.writer = new Account(writer || {})
    this.author = new Account(author || {})
    this.focus = focus
    this.childrenDoing = childrenDoing
    this.evaluation = evaluation
    this.forParent = forParent
  }

  static newFromApiResponse = data => {
    return new ClassRoomDiary(transformSnakeToCamel(data))
  }
}

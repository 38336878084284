import {
  deleteChildAttendance,
  deletePlay,
  getChildAttendanceListForChild,
  getChildAttendanceListForNursery,
  getChildAttendanceStatusForNursery,
  getChildTodayAttendance,
  patchChildAttendance,
  patchPlay,
  postChildAttendanceForChild,
  postPlayForChild,
} from '../services/v1ApiRequest'
import {
  SET_CHILD_ATTENDANCE_LIST,
  SET_CHILD_ATTENDANCE_STATUS,
  SET_CHILD_TODAY_ATTENDANCE_LIST,
  SET_FLAG_FOR_CHILD_ATTENDANCE_SHOULD_LOAD,
  SET_NURSERY_CHILDREN_ATTENDANCE_LIST,
  START_LOADING,
  STOP_LOADING,
} from '../constants/actionTypes'
import { setServerError } from './apps'
import { notification } from 'antd'
import ChildAttendance, { ChildAttendanceStatus } from '../models/childAttendance'
import moment from 'moment'

export const fetchChildAttendanceStatusForNursery = (nurseryId, date) => async dispatch => {
  dispatch({ type: START_LOADING })
  return getChildAttendanceStatusForNursery(nurseryId, moment(date).format('YYYY-MM-DD'))
    .then(res => {
      dispatch(setStatus(ChildAttendanceStatus.newFromApiResponse(res.data)))
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const fetchChildAttendanceListForChild = (
  childId,
  year,
  month,
  page = 1
) => async dispatch => {
  dispatch({ type: START_LOADING })
  return getChildAttendanceListForChild(childId, year, month, page)
    .then(res => {
      dispatch(
        setChildAttendanceListForChild(
          res.data.child_attendances.map(data => {
            return ChildAttendance.newFromApiResponse(data)
          })
        )
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const fetchChildAttendanceListForNursery = (nurseryId, date, page = 1) => async dispatch => {
  dispatch({ type: START_LOADING })
  const momentDate = moment(date)
  return getChildAttendanceListForNursery(nurseryId, momentDate.format('YYYY-MM-DD'), page)
    .then(res => {
      dispatch(
        setChildAttendanceListForNursery(
          res.data.child_attendances.map(data => {
            return ChildAttendance.newFromApiResponse(data)
          })
        )
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const fetchChildTodayAttendanceList = (childId, date) => async dispatch => {
  dispatch({ type: START_LOADING })
  const momentDate = moment(date)
  return getChildTodayAttendance(childId, momentDate.format('YYYY-MM-DD'))
    .then(res => {
      dispatch(
        setChildTodayAttendanceList(
          res.data.child_attendances.map(data => {
            return ChildAttendance.newFromApiResponse(data)
          })
        )
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const addChildAttendanceForChild = (childId, params) => async dispatch => {
  dispatch({ type: START_LOADING })
  return postChildAttendanceForChild(childId, params)
    .then(res => {
      dispatch(setFlagForShouldLoad())
      notification.success({
        message: '打刻を完了しました',
      })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      } else if (e && e.response && e.response.status === 409) {
        return e.response.status
      }
      notification.error({
        message: '打刻に失敗しました',
      })
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const updateChildAttendanceForChild = (childAttendanceId, params) => async dispatch => {
  dispatch({ type: START_LOADING })
  return patchChildAttendance(childAttendanceId, params)
    .then(res => {
      dispatch(setFlagForShouldLoad())
      notification.success({
        message: '打刻を完了しました',
      })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
      notification.error({
        message: '打刻に失敗しました',
      })
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const deleteChildAttendanceForChild = childAttendanceId => async dispatch => {
  dispatch({ type: START_LOADING })
  return deleteChildAttendance(childAttendanceId)
    .then(res => {
      dispatch(setFlagForShouldLoad())
      notification.success({
        message: '打刻を取り消しました',
      })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
      notification.error({
        message: '取り消しに失敗しました',
      })
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const addPlayForChildAttendance = (childAttendId, params) => async dispatch => {
  dispatch({ type: START_LOADING })
  return postPlayForChild(childAttendId, params)
    .then(res => {
      dispatch(setFlagForShouldLoad())
      notification.success({
        message: '打刻を完了しました',
      })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
      notification.error({
        message: '打刻に失敗しました',
      })
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const updatePlayForChildAttendance = (playId, params) => async dispatch => {
  dispatch({ type: START_LOADING })
  return patchPlay(playId, params)
    .then(res => {
      dispatch(setFlagForShouldLoad())
      notification.success({
        message: '打刻を完了しました',
      })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
      notification.error({
        message: '打刻に失敗しました',
      })
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const deletePlayForChildAttendance = playId => async dispatch => {
  dispatch({ type: START_LOADING })
  return deletePlay(playId)
    .then(res => {
      dispatch(setFlagForShouldLoad())
      notification.success({
        message: '打刻を取り消しました',
      })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
      notification.error({
        message: '取り消しに失敗しました',
      })
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

const setChildAttendanceListForChild = list => ({
  type: SET_CHILD_ATTENDANCE_LIST,
  payload: list,
})

const setChildAttendanceListForNursery = list => ({
  type: SET_NURSERY_CHILDREN_ATTENDANCE_LIST,
  payload: list,
})

const setChildTodayAttendanceList = list => ({
  type: SET_CHILD_TODAY_ATTENDANCE_LIST,
  payload: list,
})

const setFlagForShouldLoad = () => ({
  type: SET_FLAG_FOR_CHILD_ATTENDANCE_SHOULD_LOAD,
})

const setStatus = status => ({
  type: SET_CHILD_ATTENDANCE_STATUS,
  payload: status,
})

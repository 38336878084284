import React from 'react'
import { DatePicker, Form, Input, Modal } from 'antd'
import style from './index.module.scss'
import moment from 'moment'

export default Form.create({ name: 'profile' })(
  class extends React.Component {
    onSubmit = e => {
      e.preventDefault()
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.onOk(values)
        }
      })
    }

    render() {
      const { getFieldDecorator } = this.props.form
      const { account, visible, onCancel } = this.props
      let _positionKey = '',
        _positionValue = ''
      if (account.accountType === 'staff') {
        _positionKey = 'staff_membership[position]'
        _positionValue = account.staffMembership.position
      } else {
        _positionKey = 'employment[role_name]'
        _positionValue = account.employment.roleName
      }
      return (
        <Modal
          title="Edit Profile"
          visible={visible}
          onOk={this.onSubmit}
          onCancel={onCancel}
          destroyOnClose={true}
        >
          <div className={style.scrollZone}>
            <section className={style.contentSection}>
              <div className="sectionInnerWrap">
                <h2>Process Type</h2>

                <Form>
                  <Form.Item label="姓">
                    {getFieldDecorator('last_name', {
                      initialValue: account.lastName,
                      rules: [{ required: true, message: '名字を入力してください' }],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="名">
                    {getFieldDecorator('first_name', {
                      initialValue: account.firstName,
                      rules: [{ required: true, message: '名前を入力してください' }],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="役職">
                    {getFieldDecorator(_positionKey, {
                      initialValue: _positionValue,
                      rules: [{}],
                    })(<Input />)}
                  </Form.Item>
                  {account.accountType === 'staff' ? (
                    <Form.Item label="担当クラス">
                      {getFieldDecorator('class_in_charge', {
                        rules: [{ required: true, message: '担当クラスを入力してください' }],
                      })(<Input />)}
                    </Form.Item>
                  ) : null}
                  <Form.Item label="生年月日">
                    {getFieldDecorator('birthday', {
                      initialValue: moment(account.birthday),
                      rules: [{ type: 'object' }],
                    })(<DatePicker format="YYYY/MM/DD" />)}
                  </Form.Item>
                  <Form.Item label="電話番号">
                    {getFieldDecorator('phone', {
                      rules: [{}],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="E-mail">
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'Please input your E-mail!',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Form>
              </div>
            </section>
          </div>
        </Modal>
      )
    }
  }
)

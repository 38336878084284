import React from 'react'
import { Link } from 'react-router-dom'
import { Icon, Layout, Menu } from 'antd'
import Logo from '../../../constants/images/edulead_logo.png'
import routes from '../../../routes'
import styles from './index.module.scss'

const { Sider } = Layout
const { SubMenu } = Menu

const mainMenuItemList = [
  { name: 'Dash Board', iconType: 'appstore', link: routes.dashboard() },
  { name: 'デイリーボード', iconType: 'schedule', link: routes.dailyBoard() },
  { name: '登降園管理', iconType: 'reddit', link: routes.childAttendance() },
  { name: '出退勤管理', iconType: 'smile', link: routes.notYet() },
  { name: '園児台帳', iconType: 'contacts', link: routes.childrenBook() },
  { name: '指導案', iconType: 'file-ppt', link: routes.notYet() },
  { name: 'イベントカレンダー', iconType: 'carry-out', link: routes.notYet() },
  { name: 'Wiki', iconType: 'read', link: routes.notYet() },
]

const messageItemList = [
  { name: '保護者チャット', iconType: 'phone', link: routes.notYet() },
  { name: '社内チャット', iconType: 'team', link: routes.notYet() },
]

const settingItemList = [
  { name: '園の基本情報', iconType: '', link: routes.settings.nursery() },
  { name: 'クラス管理', iconType: '', link: routes.settings.classRoom() },
  { name: '料理メニュー管理', iconType: '', link: routes.settings.meal() },
]

export default class SideBar extends React.Component {
  state = {
    currentUrl: '',
    selectedKeys: [mainMenuItemList[0].name],
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const pageUrl = window.location.href
    if (pageUrl !== this.state.currentUrl) {
      const menuItems = [
        ...mainMenuItemList,
        ...messageItemList,
        ...settingItemList,
      ].filter(menuItem => pageUrl.includes(menuItem.link))
      this.setState({ currentUrl: pageUrl, selectedKeys: menuItems.map(menuItem => menuItem.name) })
    }
  }

  render() {
    const { isMenuCollapsed } = this.props
    return (
      <Sider breakpoint="md" collapsible collapsed={isMenuCollapsed} className={styles.sidebar}>
        <div className={styles.logo}>
          <img src={Logo} alt="" width={36} height={36} />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={this.state.selectedKeys}
          defaultOpenKeys={[]}
        >
          {mainMenuItemList.map(menuItem => (
            <Menu.Item key={menuItem.name}>
              <Link to={menuItem.link}>
                <Icon type={menuItem.iconType} />
                <span className="nav-text">{menuItem.name}</span>
              </Link>
            </Menu.Item>
          ))}
          <SubMenu
            key="communication"
            title={
              <span>
                <Icon type="message" />
                <span>連絡</span>
              </span>
            }
          >
            {messageItemList.map(menuItem => (
              <Menu.Item key={menuItem.name}>
                <Link to={menuItem.link}>
                  <Icon type={menuItem.iconType} />
                  <span className="nav-text">{menuItem.name}</span>
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
          <SubMenu
            key="setting"
            title={
              <span>
                <Icon type="setting" />
                <span>設定</span>
              </span>
            }
          >
            {settingItemList.map(menuItem => (
              <Menu.Item key={menuItem.name}>
                <Link to={menuItem.link}>
                  <Icon type={menuItem.iconType} />
                  <span className="nav-text">{menuItem.name}</span>
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
        </Menu>
      </Sider>
    )
  }
}

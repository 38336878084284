import { SET_CLASS_ROOM_DIARY, START_LOADING, STOP_LOADING } from '../constants/actionTypes'
import { deleteClassRoomDiary, getClassRoomDiaryList, postClassRoomDiary } from '../services/v1ApiRequest'
import moment from 'moment'
import { setServerError } from './apps'
import { notification } from 'antd'
import ClassRoomDiary from '../models/diary/classRoomDiary'

export const fetchClassRoomDiary = (classRoomId, date) => dispatch => {
  dispatch({ type: START_LOADING })
  const dateStr = moment(date).format('YYYY-MM-DD')
  return getClassRoomDiaryList(classRoomId, dateStr)
    .then(res => {
      const selected = res.data.class_room_diaries.slice(-1)[0]
      dispatch(
        setDiary(selected ? ClassRoomDiary.newFromApiResponse(selected) : new ClassRoomDiary({}))
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const createOrUpdateClassRoomDiary = (classRoomId, date, params) => dispatch => {
  dispatch({ type: START_LOADING })
  const dateStr = moment(date).format('YYYY-MM-DD')
  params['class_room_diary']['date'] = dateStr
  if (!params['class_room_diary']['status']) {
    params['class_room_diary']['status'] = 'draft'
  }
  return postClassRoomDiary(classRoomId, dateStr, params)
    .then(res => {
      notification.success({ message: 'Success' })
      dispatch(setDiary(new ClassRoomDiary.newFromApiResponse(res.data)))
    })
    .catch(e => {
      notification.error({ message: 'Error' })
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const removeClassRoomDiary = id => dispatch => {
  dispatch({ type: START_LOADING })
  return deleteClassRoomDiary(id)
    .then(_ => {
      notification.success({ message: 'Success!' })
      dispatch(setDiary({}))
    })
    .catch(e => {
      notification.error({ message: 'Error' })
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

const setDiary = data => ({
  type: SET_CLASS_ROOM_DIARY,
  payload: data,
})

import { getMyNursery, getNursery, getStaffListForNursery, patchMyNursery } from '../services/v1ApiRequest'
import { SET_MY_NURSERY, SET_NURSERY, SET_STAFF_LIST, START_LOADING, STOP_LOADING } from '../constants/actionTypes'
import { setServerError } from './apps'
import Nursery from '../models/nursery'
import { notification } from 'antd'
import { Staff } from '../models/account'

export const fetchMyNursery = () => async dispatch => {
  dispatch({ type: START_LOADING })
  return getMyNursery()
    .then(res => {
      return dispatch(setMyNursery(Nursery.newFromApiResponse(res.data)))
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const fetchNursery = id => async dispatch => {
  dispatch({ type: START_LOADING })
  return getNursery(id)
    .then(res => {
      dispatch(setNursery(Nursery.newFromApiResponse(res.data)))
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const fetchStaffListForNursery = nurseryId => async dispatch => {
  dispatch({ type: START_LOADING })
  return getStaffListForNursery(nurseryId)
    .then(res => {
      dispatch(setStaffList(res.staffs.map(staff => new Staff.newFromApiResponse(staff))))
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const updateMyNursery = params => async dispatch => {
  dispatch({ type: START_LOADING })
  return patchMyNursery(params)
    .then(res => {
      dispatch(setMyNursery(Nursery.newFromApiResponse(res.data)))
      notification.success({ message: '更新に成功しました' })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const updateNursery = (id, params) => async dispatch => {
  dispatch({ type: START_LOADING })
  return getNursery(id, params)
    .then(res => {
      dispatch(setNursery(Nursery.newFromApiResponse(res.data)))
      notification.success({ message: '更新に成功しました' })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

const setNursery = nursery => ({
  type: SET_NURSERY,
  payload: nursery,
})

const setMyNursery = nursery => ({
  type: SET_MY_NURSERY,
  payload: nursery,
})

const setStaffList = list => ({
  type: SET_STAFF_LIST,
  payload: list,
})

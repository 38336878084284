import { transformSnakeToCamel } from '../utils/snakeCamelConverter'

export default class DishMenu {
  constructor({ id, name, type, allergies }) {
    this.id = id
    this.name = name
    this.type = type
    this.allergies = allergies
  }

  static newFromApiResponse = data => {
    return new DishMenu(transformSnakeToCamel(data))
  }
}

import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import Header from '../Layouts/MemberHeader'
import Footer from '../Layouts/Footer'
import { closeMenu, openMenu, toggleMenu } from '../../actions/apps'
import { fetchCurrentAccount, selfSignOut } from '../../actions/accounts'
import { fetchMyNursery } from '../../actions/nursery'
import SideBar from '../../components/Layouts/SideBar'
import styles from './index.module.scss'
import EventListener from 'react-event-listener'

const { Content } = Layout

class MemberPageFrame extends React.Component {
  componentDidMount() {
    if (!this.props.account.isLoaded) {
      this.props.fetchProfile().then(() => {
        if (!this.props.myNursery) {
          this.props.fetchNursery()
        }
        // TODO: 切り分けしっかりしてサーバー費用抑える
        // if (this.props.account.accountType === 'staff') {
        //   // 保育園勤め
        //   if (!this.props.myNursery) { this.props.fetchNursery() }
        // } else if (this.props.account.accountType === 'head') {
        //   // 本部社員
        // }
      })
    }
  }

  handleResize = () => {
    const { openMenu, closeMenu } = this.props
    window.innerWidth >= 768 ? openMenu() : closeMenu()
  }

  render() {
    const { children, app, account } = this.props
    return (
      <Layout className={styles.memberPageFrame}>
        <SideBar isMenuCollapsed={app.isMenuCollapsed} />
        <EventListener target="window" onResize={this.handleResize} />
        <Layout>
          <Header
            app={app}
            account={account}
            toggleMenu={this.props.toggleMenu}
            signOut={this.props.signOut}
          />
          <Content className={styles.content}>{children}</Content>
          <Footer />
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({ app, account, nursery }) => ({
  app,
  account: account,
  myNursery: nursery.mine,
})

const mapDispatchToProps = dispatch => ({
  toggleMenu: () => dispatch(toggleMenu()),
  openMenu: () => dispatch(openMenu()),
  closeMenu: () => dispatch(closeMenu()),
  signOut: () => dispatch(selfSignOut()),
  fetchProfile: () => dispatch(fetchCurrentAccount()),
  fetchNursery: () => dispatch(fetchMyNursery()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MemberPageFrame)

import React from 'react'
import { Button, Form, Input, Select } from 'antd'
import AllergiesInput from '../../components/Inputs/AllergiesInput'

const { Option } = Select

class EditDishMenuForm extends React.Component {
  onSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(this.props.dishId, {
          dish_menu: values,
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { data } = this.props
    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <Form.Item label="料理名">
            {getFieldDecorator('name', {
              initialValue: data.name,
              rules: [{ required: true, message: '入力してください' }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="種類">
            {getFieldDecorator('type', {
              initialValue: data.type,
              rules: [{ required: true, message: '選択してください' }],
            })(
              <Select>
                <Option value="staple">主食</Option>
                <Option value="side_dish">副菜</Option>
                <Option value="main_dish">主菜</Option>
                <Option value="soup">汁物</Option>
                <Option value="dessert">デザート</Option>
                <Option value="snack">おかし</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="アレルギー表示">
            {getFieldDecorator('allergies', {
              initialValue: data.allergies,
              rules: [{ type: 'array' }],
            })(<AllergiesInput />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

export default Form.create({ name: 'EditDishMenuForm' })(EditDishMenuForm)

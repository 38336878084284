import ClassRoom from './classRoom'
import NurseryCertification from './nurseryCertification'
import Nursery from './nursery'
import { transformSnakeToCamel } from '../utils/snakeCamelConverter'

export default class StaffMembership {
  constructor({ position, staffCode, classInCharge, nursery, nurseryCertification }) {
    this.position = position
    this.classInCharge = classInCharge ? new ClassRoom(classInCharge) : null
    this.nursery = nursery ? new Nursery(nursery) : null
    this.nurseryCertification = nurseryCertification
      ? new NurseryCertification(nurseryCertification)
      : new NurseryCertification({})
  }

  static newFromApiResponse = data => {
    return new StaffMembership(transformSnakeToCamel(data))
  }
}

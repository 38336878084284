import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { Avatar, Button, Col, Layout, List, Row, Select } from 'antd'
import { fetchClassRoomList } from '../../actions/classRoom'
import { fetchMoreChildList, setChild } from '../../actions/child'
import SearchChild from '../Search/SearchChild'
import CreateChildModal from '../../components/Modals/CreateChildModal'
import style from '../../styles/ChildrenBookPage.module.scss'
import routes from '../../routes'

const { Sider } = Layout
const { Option } = Select

class ChildrenSidebar extends React.Component {
  state = {
    selectedId: null,
    selectedClassName: '全て',
    currentPage: 1,
  }

  onClassChange = value => {
    this.setState({ selectedClassName: value })
  }

  onSelect = child => e => {
    this.props.setChild(child)
    this.props.push(routes.childrenBook(child.id))
  }

  onLoadMore = () => {
    const { nursery } = this.props
    const targetPage = this.state.currentPage + 1
    this.props.loadMoreChildren(nursery.id, targetPage)
    this.setState({ currentPage: targetPage })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const childId = this.props.match.params.id
    if (childId !== this.state.selectedId) {
      this.setState({ selectedId: childId })
    }
    if (this.props.nursery && this.props.classRoomList.length === 0) {
      this.props.fetchAllClassRooms(this.props.nursery.id)
    }
  }

  render() {
    const {
      isLoading,
      children,
      classRoomList,
      createChild,
      visibleModal,
      toggleModal,
    } = this.props
    const { selectedClassName, selectedId } = this.state
    return (
      <Sider className={style.childrenSidebar} breakpoint="md" width={230}>
        <div style={{ padding: 16 }}>
          <SearchChild minWidth={'80%'} />
          <Select
            style={{ minWidth: 100 }}
            onChange={this.onClassChange}
            value={selectedClassName}
            defaultValue={selectedClassName}
            placeholder="  -  "
          >
            {[{ name: '全て' }, ...classRoomList].map((classRoom, idx) => (
              <Option key={idx} value={classRoom.name}>
                {classRoom.name}
              </Option>
            ))}
          </Select>
        </div>
        <Row className={style.sidebarSectionHeader} type="flex" justify="space-between">
          <Col>園児一覧</Col>
          <Col>
            <Button type="primary" shape="circle" icon="plus" size="small" onClick={toggleModal} />
          </Col>
        </Row>
        <List
          loading={isLoading}
          itemLayout="horizontal"
          dataSource={children}
          renderItem={child => (
            <List.Item
              onClick={this.onSelect(child)}
              style={selectedId === child.id ? { backgroundColor: '#F7FFD9' } : {}}
            >
              <List.Item.Meta
                style={{ padding: 16 }}
                avatar={<Avatar src={child.avatarUrl} />}
                title={<b>{child.fullName}</b>}
                description={`${child.classRoom.name} (${child.getAge()}歳児)`}
              />
            </List.Item>
          )}
        />
        <div style={{ textAlign: 'center' }}>
          <Button disabled={isLoading} onClick={this.onLoadMore}>
            さらに読み込み
          </Button>
        </div>
        <CreateChildModal
          visible={visibleModal}
          okText="作成"
          onOk={createChild}
          onCancel={toggleModal}
          classRooms={classRoomList}
        />
      </Sider>
    )
  }
}

const mapStateToProps = ({ app, nursery, child, classRoom }) => ({
  isLoading: app.isLoading,
  child: child.selected,
  children: child.list,
  classRoomList: classRoom.list,
  nursery: nursery.mine,
})

const mapDispatchToProps = dispatch => ({
  fetchAllClassRooms: nurseryId => dispatch(fetchClassRoomList(nurseryId)),
  push: path => dispatch(push(path)),
  setChild: child => dispatch(setChild(child)),
  loadMoreChildren: (nurseryId, page = 2) => dispatch(fetchMoreChildList(nurseryId, page)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChildrenSidebar))

import {
  SET_CLASS_ROOM,
  SET_CLASS_ROOM_LIST,
  SET_FLAG_FOR_CLASS_ROOM_SHOULD_LOAD,
  SIGN_OUT,
} from '../constants/actionTypes'

const DEFAULT_STATE = {
  list: [],
  selected: null,
  shouldLoad: true,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_CLASS_ROOM_LIST:
      return { ...state, list: action.payload, shouldLoad: false }
    case SET_CLASS_ROOM:
      return {
        ...state,
        selected: action.payload,
        shouldLoad: false,
        list: state.list.map(klass => (klass.id === action.payload.id ? action.payload : klass)),
      }
    case SET_FLAG_FOR_CLASS_ROOM_SHOULD_LOAD:
      return { ...state, shouldLoad: true }
    case SIGN_OUT:
      return DEFAULT_STATE
    default:
      return state
  }
}

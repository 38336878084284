import StaffMembership from './staffMembership'
import Employment from './employment'
import { transformSnakeToCamel } from '../utils/snakeCamelConverter'
import Child from './child'

export default class Account {
  constructor({
    id,
    email,
    username,
    firstName,
    lastName,
    phone,
    accountType,
    avatarUrl,
    employeeCode,
    staffMembership,
    employment,
    birthday,
    gender,
    createdAt,
    updatedAt,
  }) {
    this.id = id
    this.email = email
    this.username = username
    this.firstName = firstName
    this.lastName = lastName
    this.fullName = `${lastName} ${firstName}`
    this.phone = phone
    this.accountType = accountType // Enum[ :head, :staff, :parent ]
    this.avatarUrl = avatarUrl
    this.employeeCode = employeeCode
    this.staffMembership = staffMembership
      ? new StaffMembership(staffMembership)
      : accountType === 'staff'
      ? new StaffMembership({})
      : null
    this.employment = employment
      ? new Employment(employment)
      : accountType !== 'parent'
      ? new Employment({})
      : null
    this.birthday = birthday ? new Date(birthday) : null
    this.gender = gender
    this.createdAt = createdAt ? new Date(createdAt) : null
    this.updatedAt = updatedAt ? new Date(updatedAt) : null
  }

  static newFromApiResponse = data => {
    return new Account(transformSnakeToCamel(data))
  }
}

export class Staff extends Account {
  constructor({
    id,
    email,
    username,
    firstName,
    lastName,
    phone,
    avatarUrl,
    employeeCode,
    staffMembership,
    employment,
    birthday,
    gender,
    createdAt,
    updatedAt,
  }) {
    super({
      id,
      email,
      username,
      firstName,
      lastName,
      phone,
      accountType: 'staff',
      avatarUrl,
      employeeCode,
      staffMembership: staffMembership
        ? new StaffMembership(staffMembership)
        : new StaffMembership({}),
      employment,
      birthday,
      gender,
      createdAt,
      updatedAt,
    })
  }

  static newFromApiResponse = data => {
    return new Staff(transformSnakeToCamel(data))
  }
}

export class Parent extends Account {
  constructor({
    id,
    email,
    username,
    firstName,
    lastName,
    phone,
    avatarUrl,
    birthday,
    gender,
    children,
    createdAt,
    updatedAt,
  }) {
    super({
      id,
      email,
      username,
      firstName,
      lastName,
      phone,
      accountType: 'parent',
      avatarUrl,
      birthday,
      gender,
      createdAt,
      updatedAt,
    })
    if (!children) {
      children = []
    }
    this.children = children.map(child => new Child(child))
  }

  static newFromApiResponse = data => {
    return new Parent(transformSnakeToCamel(data))
  }
}

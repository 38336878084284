import { transformSnakeToCamel } from '../../utils/snakeCamelConverter'
import Child from '../child'

export default class ParentReport {
  constructor({
    id,
    message,
    amMood,
    pmMood,
    startsSleepingAt,
    endsSleepingAt,
    lastNightMeal,
    morningMeal,
    willPickUp,
    child,
  }) {
    this.id = id
    this.message = message
    this.amMood = amMood
    this.pmMood = pmMood
    this.startsSleepingAt = startsSleepingAt
    this.endsSleapingAt = endsSleepingAt
    this.lastNightMeal = lastNightMeal
    this.morningMeal = morningMeal
    this.willPickUp = willPickUp
    this.child = child ? new Child(child) : null
  }

  static newFromApiResponse = data => {
    return new ParentReport(transformSnakeToCamel(data))
  }
}

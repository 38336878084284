import { SERVER_HOST } from '../constants/url'

export default {
  top: () => '/',
  signIn: () => '/sign_in',
  signUp: () => '/sign_up',
  dashboard: () => '/dashboard',
  dailyBoard: () => '/daily_board',
  dailyHealth: () => '/daily_health',
  dailyMeal: () => '/daily_meal',
  childAttendance: () => '/child_attendance',
  employeeAttendance: () => '/employee_attendance',
  childrenBook: childId => `/children_books/${childId || 'index'}`,
  myPage: () => '/my_page',
  settings: {
    index: () => '/settings',
    nursery: () => '/settings/nursery',
    classRoom: () => '/settings/class_room',
    meal: () => '/settings/meal',
  },
  download: {
    childTimeCardDayExcel: (nurseryId, date, token) =>
      `${SERVER_HOST}/download/nurseries/${nurseryId}/child_time_card_day_excel?date=${date}&token=${encodeURI(
        token
      )}`,
    childTimeCardMonthExcel: (nurseryId, year, month, token) =>
      `${SERVER_HOST}/download/nurseries/${nurseryId}/child_time_card_month_excel?year=${year}&month=${month}&token=${encodeURI(
        token
      )}`,
    childAttendancesForClassExcel: (nurseryId, year, month, token) =>
      `${SERVER_HOST}/download/nurseries/${nurseryId}/child_attendances_for_class_excel?year=${year}&month=${month}&token=${encodeURI(
        token
      )}`,
    childAttendancesForAll: (nurseryId, year, month, token) =>
      `${SERVER_HOST}/download/nurseries/${nurseryId}/child_attendances_for_all_excel?year=${year}&month=${month}&token=${encodeURI(
        token
      )}`,
    monthlyTimeCardForChild: (childId, year, month, token) =>
      `${SERVER_HOST}/download/children/${childId}/time_card_excel?year=${year}&month=${month}&token=${encodeURI(
        token
      )}`,
  },
  aboutUs: () => '/about_us',
  term: () => '/term',
  privacyPolicy: () => '/privacy_policy',
  notYet: () => '/not_yet',
}

import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal, notification, TimePicker } from 'antd'
import moment from 'moment'
import uuid from 'uuid/v4'

class BeginsAtCell extends React.Component {
  state = {
    visibleModal: false,
    selectedValue: null,
  }

  timePickRef = React.createRef()

  getStatus = () => {
    const { record } = this.props
    if (record.attendanceExist) {
      return 'editable'
    } else {
      return 'addable'
    }
  }

  onValueChange = (momentTime, timeString) => {
    const { record } = this.props
    this.setState({ selectedValue: momentTime })
    if (momentTime === null) {
      /// 削除実行時
      if (record.attendanceId) {
        this.props.deleteAttendance(record.attendanceId)
      }
    }
  }

  createOrUpdateData = () => {
    const { record, selectedDate } = this.props
    const { selectedValue } = this.state
    if (!selectedValue) {
      notification.warn({ message: '値を入力してください' })
      return
    }
    let date = moment(selectedValue)
    date.year(selectedDate.year())
    date.month(selectedDate.month())
    date.date(selectedDate.date())
    if (this.getStatus() === 'editable') {
      this.props.updateAttendance(record.attendanceId, {
        child_attendance: {
          begins_at: date.format(),
        },
      })
    } else if (this.getStatus() === 'addable') {
      this.props.addAttendance(record.child.id, {
        child_attendance: {
          type: 'attend',
          begins_at: date.format(),
          date: date.format('YYYY-MM-DD'),
        },
      })
    }
    this.closeModal()
  }

  openModal = () => {
    const { cellData } = this.props
    const timeVal = cellData ? moment(cellData) : moment(new Date())
    this.setState({
      visibleModal: true,
      selectedValue: timeVal,
    })
  }

  closeModal = () => {
    this.setState({ visibleModal: false })
  }

  onDelete = () => {
    const { record } = this.props
    if (record.attendanceId) {
      this.props.deleteAttendance(record.attendanceId).then(() => this.closeModal())
    }
  }

  componentDidMount() {
    if (this.props.cellData && this.state.selectedValue !== this.props.cellData) {
      this.setState({ selectedValue: moment(this.props.cellData) })
    }
  }

  render() {
    const { cellData } = this.props
    const { visibleModal, selectedValue } = this.state
    const timeVal = cellData ? moment(cellData) : null
    const timeValForInput = selectedValue || moment(new Date())
    return (
      <div>
        <div onClick={this.openModal}>
          <TimePicker
            id={uuid()}
            ref={this.timePickRef}
            open={false}
            style={{ width: 80 }}
            initialValue={timeVal}
            value={timeVal}
            placeholder="--"
            format="HH:mm"
            onFocus={() => {
              this.timePickRef.current.blur()
            }}
            onChange={this.onValueChange}
          />
        </div>

        <Modal
          title={cellData ? '登園時刻を編集' : '登園時刻を打刻'}
          visible={visibleModal}
          okText={cellData ? '更新' : '追加'}
          onOk={this.createOrUpdateData}
          onCancel={this.closeModal}
        >
          <TimePicker
            id={uuid()}
            allowClear={false}
            onChange={this.onValueChange}
            initialValue={timeValForInput}
            value={timeValForInput}
            format="HH:mm"
          />
          <Button
            type="primary"
            shape="circle"
            icon="close"
            style={{ marginLeft: 16 }}
            onClick={this.onDelete}
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ app, child, nursery, childAttendance }) => ({
  isLoading: app.isLoading,
  selectedDate: moment(app.selectedDate),
  nursery: nursery.mine,
  shouldChildrenLoad: child.shouldLoad,
  shouldAttendanceLoad: childAttendance.shouldLoad,
})

export default connect(mapStateToProps)(BeginsAtCell)

import { getCorrespondenceListForNursery } from '../services/v1ApiRequest'
import { SET_NURSERY_CORRESPONDENCE_LIST, START_LOADING, STOP_LOADING } from '../constants/actionTypes'
import { setServerError } from './apps'
import NurseryCorrespondence from '../models/nurseryCorrespondence'
import moment from 'moment'

export const fetchNurseryCorrespondences = (nurseryId, date, classRoomId) => async dispatch => {
  dispatch({ type: START_LOADING })
  const dateStr = moment(date).format('YYYY-MM-DD')
  const _classRoomId = classRoomId || ''
  return getCorrespondenceListForNursery(nurseryId, dateStr, _classRoomId)
    .then(res => {
      dispatch(
        setList(
          res.data.nursery_correspondences.map(
            data => new NurseryCorrespondence.newFromApiResponse(data)
          )
        )
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

const setList = list => ({
  type: SET_NURSERY_CORRESPONDENCE_LIST,
  payload: list,
})

import React from 'react'
import { Button, DatePicker, Form, Input, Select } from 'antd'
import AllergiesInput from '../../components/Inputs/AllergiesInput'
import { jpStates } from '../../constants/jpStates'
import moment from 'moment'

const { Option } = Select

class EditChildProfileForm extends React.Component {
  onSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(this.props.data.id, {
          child: values,
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { data } = this.props
    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <Form.Item label="姓">
            {getFieldDecorator('last_name', {
              initialValue: data.lastName,
              rules: [{ required: true, message: '入力してください' }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="名">
            {getFieldDecorator('first_name', {
              initialValue: data.firstName,
              rules: [{ required: true, message: '入力してください' }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="せい">
            {getFieldDecorator('last_name_kana', {
              initialValue: data.lastNameKana,
            })(<Input />)}
          </Form.Item>
          <Form.Item label="めい">
            {getFieldDecorator('first_name_kana', {
              initialValue: data.firstNameKana,
            })(<Input />)}
          </Form.Item>
          <Form.Item label="生年月日">
            {getFieldDecorator('birthday', {
              initialValue: moment(data.birthday),
              rules: [{ type: 'object' }],
            })(<DatePicker format="YYYY/MM/DD" />)}
          </Form.Item>
          <Form.Item label="性別">
            {getFieldDecorator('gender', {
              initialValue: data.gender,
              rules: [{ required: true, message: '選択してください' }],
            })(
              <Select>
                <Option value="male">男</Option>
                <Option value="female">女</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="郵便番号">
            {getFieldDecorator('postcode', {
              initialValue: data.postcode,
            })(<Input />)}
          </Form.Item>
          <Form.Item label="都道府県">
            {getFieldDecorator('state', {
              initialValue: data.state,
            })(
              <Select>
                {jpStates.map((item, idx) => (
                  <Option key={idx} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="住所１">
            {getFieldDecorator('address', {
              initialValue: data.address,
            })(<Input />)}
          </Form.Item>
          <Form.Item label="住所２(ビル名など)">
            {getFieldDecorator('building', {
              initialValue: data.building,
            })(<Input />)}
          </Form.Item>
          <Form.Item label="アレルギー">
            {getFieldDecorator('allergies', {
              initialValue: data.allergies,
              rules: [{ type: 'array' }],
            })(<AllergiesInput />)}
          </Form.Item>
          <Form.Item label="主治医">
            {getFieldDecorator('primary_hospital', {
              initialValue: data.primaryHospital,
            })(<Input />)}
          </Form.Item>
          <Form.Item label="血液型">
            {getFieldDecorator('blood_type', {
              initialValue: data.bloodType,
            })(
              <Select>
                <Option value="a">A</Option>
                <Option value="b">B</Option>
                <Option value="o">O</Option>
                <Option value="ab">AB</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

export default Form.create({ name: 'EditChildProfileForm' })(EditChildProfileForm)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Col, Modal, Row, Select, Skeleton, Spin, Tag } from 'antd'
import { updateChild } from '../../actions/child'
import { updateClassRoom } from '../../actions/classRoom'
import style from '../../styles/ChildrenBookPage.module.scss'
import QRCode from 'qrcode.react'
import moment from 'moment'
import EditChildProfileForm from '../Forms/EditChildProfileForm'

const { Option } = Select

const basicLabelList = [
  { name: '名前', dataKey: 'fullName', render: value => value },
  {
    name: '生年月日',
    dataKey: 'birthday',
    render: value => moment(value).format('YYYY年MM月DD日'),
  },
  {
    name: '性別',
    dataKey: 'gender',
    render: value => (value === 'male' ? '男' : value === 'female' ? '女' : 'その他'),
  },
  { name: '住所', dataKey: 'fullAddress', render: value => value },
  {
    name: '健康保険証',
    dataKey: 'hasHealthInsurance',
    render: value => (value ? 'はい' : 'いいえ'),
  },
  {
    name: 'アレルギー',
    dataKey: 'allergies',
    render: valueList =>
      valueList.map((value, idx) => (
        <Tag key={idx} color="red">
          {value}
        </Tag>
      )),
  },
  { name: 'かかりつけ医', dataKey: 'primaryHospital', render: value => value },
  { name: '備考', dataKey: 'remark', render: value => value },
]

const verticalPadding = {
  padding: '8px 0',
}

class BasicInfo extends Component {
  state = {
    visibleProfileModal: false,
    visibleClassModal: false,
  }

  onSubmitProfile = (childId, values) => {
    this.props.updateProfile(childId, values).then(_ => this.props.callBackFetch())
    this.toggleProfileModal()
  }

  onClassChange = (value, _) => {
    const { child } = this.props
    this.props.updateProfile(child.id, { child: { class_room_id: value } })
  }

  toggleProfileModal = () => {
    this.setState({ visibleProfileModal: !this.state.visibleProfileModal })
  }

  render() {
    const { isLoading, child, classRooms } = this.props
    const { visibleProfileModal } = this.state
    const basicElements = () =>
      child &&
      basicLabelList.map(item => (
        <Row key={item.name} type="flex">
          <Col style={verticalPadding} xs={24} md={6}>
            <b>{item.name}</b>
          </Col>
          <Col style={verticalPadding}>{item.render(child[item.dataKey])}</Col>
        </Row>
      ))

    return (
      <div className={style.paneContent}>
        <div className={style.basicInfoSection}>
          <Row type="flex" justify="space-between">
            <Col>
              <h3>基本情報</h3>
            </Col>
            <Col>
              <Button type="primary" shape="circle" icon="edit" onClick={this.toggleProfileModal} />
            </Col>
          </Row>

          <Skeleton active loading={isLoading || !child}>
            {basicElements()}
          </Skeleton>
        </div>

        <div className={style.basicInfoSection}>
          <Row type="flex" justify="space-between">
            <Col>
              <h3>クラス・所属</h3>
            </Col>
          </Row>

          <Skeleton active loading={isLoading || !child}>
            <Select
              placeholder="クラスを選択してください(任意)"
              value={child.classRoom && child.classRoom.id}
              onChange={this.onClassChange}
            >
              {classRooms.map(klass => (
                <Option key={klass.id} value={klass.id}>
                  {klass.name}
                </Option>
              ))}
            </Select>
          </Skeleton>
        </div>

        <div className={style.basicInfoSection}>
          <h3>QRコード</h3>
          <div style={{ width: '100%', textAlign: 'center' }}>
            {/* TODO: いろんな目的に応じたQRを生成できるように */}
            {child ? (
              <QRCode
                value={JSON.stringify({ type: 'CHILD_ATTENDANCE', id: child.id })}
                style={{ margin: '0 auto' }}
              />
            ) : (
              <Spin />
            )}
          </div>
        </div>

        <Modal
          visible={visibleProfileModal}
          title="子どもの情報を編集"
          footer={null}
          onCancel={this.toggleProfileModal}
        >
          <EditChildProfileForm data={child} onSubmit={this.onSubmitProfile} />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ app, child, classRoom }) => ({
  child: child.selected,
  classRooms: classRoom.list,
  isLoading: app.isLoading,
})

const mapDispatchToProps = dispatch => ({
  updateProfile: (id, params) => dispatch(updateChild(id, params)),
  updateClass: (id, params) => dispatch(updateClassRoom(id, params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BasicInfo))

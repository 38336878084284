import { SET_NURSERY_CORRESPONDENCE_LIST, SIGN_OUT } from '../constants/actionTypes'

const DEFAULT_STATE = {
  list: [],
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_NURSERY_CORRESPONDENCE_LIST:
      return { ...state, list: action.payload }
    case SIGN_OUT:
      return DEFAULT_STATE
    default:
      return state
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { Avatar, Col, Layout, Row, Skeleton, Tabs } from 'antd'
import { setPageTitle } from '../actions/apps'
import { fetchMyNursery } from '../actions/nursery'
import { fetchChildAttendanceListForChild } from '../actions/childAttendance'
import { fetchAllPhysicalHistoryListForChild } from '../actions/physicalGrowthHistory'
import { createChild, fetchChild, fetchChildList, setChild } from '../actions/child'
import Attendances from './ChildrenBookContents/Attendances'
import BasicInfo from './ChildrenBookContents/BasicInfo'
import Physical from './ChildrenBookContents/Physical'
import ChildrenSidebar from './ChildrenBookContents/ChildrenSidebar'
import style from '../styles/ChildrenBookPage.module.scss'
import routes from '../routes'
import moment from 'moment'

const { TabPane } = Tabs
const { Content } = Layout

class ChildrenBookPage extends Component {
  state = {
    selectedContent: null,
    shouldLoadAttendances: true,
    shouldLoadChildren: true,
    visibleNewChildModal: false,
  }

  newChildSubmit = values => {
    this.props.createNewChild(this.props.nursery.id, { child: values })
    this.toggleCreateChildModal()
  }

  toggleCreateChildModal = () => {
    this.setState({ visibleNewChildModal: !this.state.visibleNewChildModal })
  }

  onceLoadChildAttendanceDataList = childId => {
    if (!this.state.shouldLoadAttendances) {
      return
    }

    const today = moment(new Date())
    this.props.fetchAllAttendances(childId, today.year(), today.month() + 1).then(() => {
      this.setState({ shouldLoadAttendances: false })
    })
  }

  onceLoadChildrenDataList = () => {
    if (!this.props.nursery || !this.state.shouldLoadChildren) {
      return
    }

    this.props.fetchAllChildren(this.props.nursery.id).then(() => {
      this.setState({ shouldLoadChildren: false })
    })
  }

  fetchData = () => {
    const { children } = this.props
    const childId = this.props.match.params.id
    if (this.props.match.params.id !== 'index') {
      // => どこかしらのページで子どものリンクを踏んできた時
      this.props.fetchChild(childId)
      this.onceLoadChildAttendanceDataList(childId)
      this.props.fetchAllPhysicalHistories(childId)
    } else {
      // => サイドバーから開いたのでchildIdの指定がない => childrenListの先頭を使用
      if (children.length > 0) {
        this.props.setChild(children[0])
      } else {
        // global state上にchildに関するデータが何もない => しょうがない、取りに行く
        if (!this.props.nursery) {
          this.props.fetchNursery()
        }
      }
    }
  }

  callBackFetchData = () => {
    this.props.fetchAllChildren(this.props.nursery.id).then(() => {
      this.fetchData()
    })
  }

  fetchPhysicalHistories = () => {
    const childId = this.props.match.params.id
    if (this.props.match.params.id !== 'index') {
      this.props.fetchAllPhysicalHistories(childId)
    }
  }

  componentDidMount() {
    this.props.setPageTitle('園児台帳')
    this.onceLoadChildrenDataList()
    // 各タブコンテナ用のデータをロード
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // サイドバーから開いた時のみchildrenロード後の処理が必要になる
    this.onceLoadChildrenDataList()

    if (this.props.match.params.id === 'index') {
      if (this.props.child.id) {
        return
      }
      //// 1. ロードが完了した時にstateにchildをセット
      if (prevProps.children.length === 0 && this.props.children.length > 0) {
        const child = this.props.children[0]
        this.props.setChild(child)
        this.props.push(routes.childrenBook(child.id))
        //// childに紐づくデータのロード
        this.onceLoadChildAttendanceDataList(child.id)
        this.props.shouldLoadPGH && this.props.fetchAllPhysicalHistories(child.id)
      }
    }
  }

  render() {
    const { isLoading, child } = this.props
    const { visibleNewChildModal } = this.state

    return (
      <div className={style.page}>
        <Layout>
          <Content>
            <section className={style.contentSection}>
              <div style={{ padding: '24px 50px' }}>
                <div
                  className={style.subContent}
                  style={{ minHeight: '100vh', background: 'none' }}
                >
                  <div style={{ paddingBottom: 24 }}>
                    <Skeleton active avatar paragraph={{ rows: 2 }} loading={isLoading}>
                      <div>
                        <Row type="flex" gutter={16}>
                          <Col>
                            <Avatar src={child && child.avatarUrl} size={48} />
                          </Col>
                          <Col>
                            <div style={{ fontSize: 20 }}>{child && child.fullName}</div>
                            <div>{child && `${child.lastNameKana} ${child.firstNameKana}`}</div>
                          </Col>
                        </Row>
                      </div>
                    </Skeleton>
                  </div>

                  <Tabs type="card" className={style.tab}>
                    <TabPane className={style.tabPane} tab="基本情報" key="1">
                      <BasicInfo callBackFetch={this.callBackFetchData} />
                    </TabPane>
                    <TabPane className={style.tabPane} tab="身体測定" key="2">
                      <Physical callBackFetch={this.fetchPhysicalHistories} />
                    </TabPane>
                    <TabPane className={style.tabPane} tab="登降園記録" key="3">
                      <Attendances callBackFetch={this.callBackFetchData} />
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </section>
          </Content>

          <ChildrenSidebar
            toggleModal={this.toggleCreateChildModal}
            visibleModal={visibleNewChildModal}
            createChild={this.newChildSubmit}
          />
        </Layout>
      </div>
    )
  }
}

const mapStateToProps = ({ app, child, nursery }) => ({
  isLoading: app.isLoading,
  child: child.selected,
  children: child.list,
  nursery: nursery.mine,
  shouldLoadPGH: child.shouldLoadPGH,
})

const mapDispatchToProps = dispatch => ({
  setPageTitle: pageTitle => dispatch(setPageTitle(pageTitle)),
  fetchNursery: () => dispatch(fetchMyNursery()),
  fetchChild: id => dispatch(fetchChild(id)),
  fetchAllChildren: (nurseryId, page = 1) => dispatch(fetchChildList(nurseryId, page)),
  fetchAllAttendances: (childId, year, month, page = 1) =>
    dispatch(fetchChildAttendanceListForChild(childId, year, month, page)),
  fetchAllPhysicalHistories: childId => dispatch(fetchAllPhysicalHistoryListForChild(childId)),
  createNewChild: (nurseryId, params) => dispatch(createChild(nurseryId, params)),
  setChild: child => dispatch(setChild(child)),
  push: path => dispatch(push(path)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChildrenBookPage))

import { getClassRoom, getClassRoomList, patchClassRoom, postClassRoom } from '../services/v1ApiRequest'
import { SET_CLASS_ROOM, SET_CLASS_ROOM_LIST, START_LOADING, STOP_LOADING } from '../constants/actionTypes'
import { setServerError } from './apps'
import ClassRoom from '../models/classRoom'

export const fetchClassRoom = id => async dispatch => {
  dispatch({ type: START_LOADING })
  return getClassRoom(id)
    .then(res => {
      dispatch(setClassRoom(ClassRoom.newFromApiResponse(res.data)))
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const fetchClassRoomList = nurseryId => async dispatch => {
  dispatch({ type: START_LOADING })
  return getClassRoomList(nurseryId)
    .then(res => {
      dispatch(
        setClassRoomList(
          res.data.class_rooms.map(classRoomData => {
            return ClassRoom.newFromApiResponse(classRoomData)
          })
        )
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const createClassRoom = (nurseryId, params) => async dispatch => {
  dispatch({ type: START_LOADING })
  return postClassRoom(nurseryId, params)
    .then(res => {
      dispatch(setClassRoom(ClassRoom.newFromApiResponse(res.data)))
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const updateClassRoom = (id, params) => async dispatch => {
  dispatch({ type: START_LOADING })
  patchClassRoom(id, params)
    .then(res => {
      dispatch(setClassRoom(ClassRoom.newFromApiResponse(res.data)))
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

const setClassRoom = classRoom => ({
  type: SET_CLASS_ROOM,
  payload: classRoom,
})

const setClassRoomList = classRoomList => ({
  type: SET_CLASS_ROOM_LIST,
  payload: classRoomList,
})

import React from 'react'
import { connect } from 'react-redux'
import { Button, Form, Input } from 'antd'

const { TextArea } = Input

class PutClassRoomDiaryForm extends React.Component {
  onSubmit = e => {
    const { account } = this.props
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      values['writer_id'] = account.id
      values['author_id'] = account.id
      if (!err) {
        this.props.onSubmit({
          class_room_diary: values,
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { data } = this.props
    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <Form.Item label="本日の狙い">
            {getFieldDecorator('focus', {
              initialValue: data.focus,
              rules: [],
            })(<TextArea />)}
          </Form.Item>
          <Form.Item label="子どもの様子">
            {getFieldDecorator('children_doing', {
              initialValue: data.childrenDoing,
              rules: [],
            })(<TextArea />)}
          </Form.Item>
          <Form.Item label="評価・反省">
            {getFieldDecorator('evaluation', {
              initialValue: data.evaluation,
              rules: [],
            })(<TextArea />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = ({ account }) => ({
  account,
})

export default connect(mapStateToProps)(
  Form.create({ name: 'EditClassRoomDiary' })(PutClassRoomDiaryForm)
)

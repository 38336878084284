import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { withRouter } from 'react-router-dom'
import { Button, Col, DatePicker, Dropdown, Icon, Menu, Row, Select } from 'antd'
import { setPageTitle, setSelectedDate } from '../actions/apps'
import { fetchChildList } from '../actions/child'
import { fetchChildAttendanceListForNursery } from '../actions/childAttendance'
import { fetchMyNursery } from '../actions/nursery'
import { fetchClassRoomList } from '../actions/classRoom'
import ChildAttendanceTable from '../components/Tables/ChildAttendanceTable'
import QrAttendancer from '../components/Modals/QrAttendancer'
import SearchChild from './Search/SearchChild'
import queryString from 'query-string'
import moment from 'moment'
import style from '../styles/ChildAttendancePage.module.scss'
import routes from '../routes'

const { Option } = Select

class ChildAttendancePage extends Component {
  state = {
    isNurseryLoaded: false,
    selectedChild: null,
    selectedClassName: '全て',
    visibleQrScanner: false,
  }

  onDateChange = (date, dateString) => {
    const ds = dateString.replace(/\//g, '-')
    const page = queryString.parse(this.props.location.search)['page'] || 1
    if (this.props.nursery) {
      this.props.fetchAllAttendances(this.props.nursery.id, ds, page)
    } else {
      this.props.fetchNursery().then(action => {
        const nursery = action.payload
        this.props.fetchAllAttendances(nursery.id, ds, page)
      })
    }
    this.props.push(`${routes.childAttendance()}?date=${date.format('YYYY-MM-DD')}&page=${page}`)
    this.props.setSelectedDate(date.toDate())
  }

  onClassChange = value => {
    this.setState({ selectedClassName: value })
  }

  onSearchedResultSelected = child => {
    this.setState({ selectedChild: child })
  }

  onSearchClear = () => {
    this.setState({ selectedChild: null })
  }

  secureDataLoad = () => {
    const qDate =
      queryString.parse(this.props.location.search)['date'] ||
      moment(new Date()).format('YYYY-MM-DD')
    let page = queryString.parse(this.props.location.search)['page'] || 1
    if (this.props.nursery) {
      this.props.shouldChildrenLoad && this.props.fetchChildren(this.props.nursery.id)
      this.props.shouldAttendanceLoad &&
        this.props.fetchAllAttendances(this.props.nursery.id, qDate, page)
      this.props.shouldClassLoad && this.props.fetchAllClassRooms(this.props.nursery.id)
    } else {
      this.props.fetchNursery().then(action => {
        const nursery = action.payload
        this.props.shouldChildrenLoad && this.props.fetchChildren(nursery.id)
        this.props.shouldAttendanceLoad && this.props.fetchAllAttendances(nursery.id, qDate, page)
        this.props.shouldClassLoad && this.props.fetchAllClassRooms(nursery.id)
      })
    }
  }

  toggleScannerModal = () => {
    this.setState({ visibleQrScanner: !this.state.visibleQrScanner })
  }

  componentDidMount() {
    const qDate =
      queryString.parse(this.props.location.search)['date'] ||
      moment(new Date()).format('YYYY-MM-DD')
    this.onDateChange(moment(qDate, 'YYYY-MM-DD'), qDate)

    this.props.setPageTitle('登降園管理')
    this.secureDataLoad()
  }

  componentWillUnmount() {
    this.props.setSelectedDate(new Date())
  }

  render() {
    const { classRoomList, nursery, token, selectedDate } = this.props
    const { selectedClassName, visibleQrScanner, selectedChild } = this.state
    const nurseryId = nursery && nursery.id
    const year = selectedDate.year()
    const month = selectedDate.month() + 1
    const downloadOptionList = [
      {
        selector: 'excelToday',
        display: 'Excel形式（本日分）',
        icon: 'file-excel',
        link: routes.download.childTimeCardDayExcel(
          nurseryId,
          selectedDate.format('YYYY-MM-DD'),
          token
        ),
      },
      {
        selector: 'excelMonth',
        display: 'Excel形式（今月分）',
        icon: 'file-excel',
        link: routes.download.childTimeCardMonthExcel(nurseryId, year, month, token),
      },
      {
        selector: 'excelAttendanceForClass',
        display: '出席簿（今月分・クラス別）',
        icon: 'file-excel',
        link: routes.download.childAttendancesForClassExcel(nurseryId, year, month, token),
      },
      {
        selector: 'excelAttendanceForAll',
        display: '出席簿（今月分・全体）',
        icon: 'file-excel',
        link: routes.download.childAttendancesForAll(nurseryId, year, month, token),
      },
    ]
    const downloadMenu = (
      <Menu>
        {downloadOptionList.map(item => (
          <Menu.Item key={item.selector}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a ref="noopener noreferrer" href={item.link} target="_blank">
              <Icon type={item.icon} />
              {item.display}
            </a>
          </Menu.Item>
        ))}
      </Menu>
    )

    return (
      <div className={style.page}>
        <section className={style.contentSection}>
          <div style={{ padding: '24px 50px' }}>
            <div className={style.subContent}>
              <Row type="flex" justify="space-between">
                <Col>
                  <h2>登降園管理</h2>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    shape="circle"
                    icon="qrcode"
                    onClick={this.toggleScannerModal}
                  />
                </Col>
              </Row>

              <div style={{ padding: '24px 0' }}>
                <Row type="flex" justify="space-between">
                  <Col>
                    <Row type="flex" gutter={16}>
                      <Col>
                        <DatePicker
                          allowClear={false}
                          onChange={this.onDateChange}
                          value={selectedDate}
                          format="YYYY/MM/DD"
                        />
                      </Col>
                      <Col>
                        <Select
                          style={{ minWidth: 100 }}
                          onChange={this.onClassChange}
                          value={selectedClassName}
                          defaultValue={selectedClassName}
                          placeholder="  -  "
                        >
                          {[{ name: '全て' }, ...classRoomList].map((classRoom, idx) => (
                            <Option key={idx} value={classRoom.name}>
                              {classRoom.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col>
                        <SearchChild
                          onClear={this.onSearchClear}
                          onSelected={this.onSearchedResultSelected}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Dropdown.Button overlay={downloadMenu} icon={<Icon type="download" />}>
                      ダウンロード
                    </Dropdown.Button>
                  </Col>
                </Row>
              </div>

              <div className={style.overflowXScroll}>
                <ChildAttendanceTable
                  selectedDate={selectedDate}
                  classFilter={selectedClassName}
                  selectedChild={selectedChild}
                />
              </div>
            </div>

            {/* モーダル */}
            <QrAttendancer visible={visibleQrScanner} onCancel={this.toggleScannerModal} />
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = ({ app, account, child, nursery, childAttendance, classRoom }) => ({
  isLoading: app.isLoading,
  selectedDate: moment(app.selectedDate),
  token: account.authToken,
  nursery: nursery.mine,
  classRoomList: classRoom.list,
  shouldChildrenLoad: child.shouldLoad,
  shouldAttendanceLoad: childAttendance.shouldLoad,
  shouldClassLoad: classRoom.shouldLoad,
})

const mapDispatchToProps = dispatch => ({
  setPageTitle: pageTitle => dispatch(setPageTitle(pageTitle)),
  fetchNursery: () => dispatch(fetchMyNursery()),
  fetchChildren: (nurseryId, page = 1) => dispatch(fetchChildList(nurseryId, page)),
  fetchAllClassRooms: nurseryId => dispatch(fetchClassRoomList(nurseryId)),
  fetchAllAttendances: (nurseryId, date, page = 1) =>
    dispatch(fetchChildAttendanceListForNursery(nurseryId, date, page)),
  push: path => dispatch(push(path)),
  setSelectedDate: date => dispatch(setSelectedDate(date)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChildAttendancePage))

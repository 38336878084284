import React from 'react'
import { Select } from 'antd'

const { Option } = Select

export default class AgeTargetsInput extends React.Component {
  static getDerivedStateFromProps(nextProps) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      return {
        ...(nextProps.value || []),
      }
    }
    return null
  }

  constructor(props) {
    super(props)

    const value = props.value || []
    this.state = {
      targets: value,
    }
  }

  onSelect = (value, _) => {
    const targets = this.state.targets
    targets.push(value)
    this.setState({ targets: targets })
    this.triggerChange(targets)
  }

  handleClose = value => {
    const targets = this.state.targets.filter(target => target !== value)
    this.setState({ targets: targets })
    this.triggerChange(targets)
  }

  triggerChange = changedValue => {
    // Should provide an event to pass value to Form.
    const { onChange } = this.props
    if (onChange) {
      onChange(changedValue)
    }
  }

  componentDidMount() {
    const { value } = this.props
    this.setState({ targets: value || [] })
  }

  render() {
    const { targets } = this.state
    return (
      <div>
        <Select
          value={targets}
          onSelect={this.onSelect}
          onDeselect={this.handleClose}
          mode="multiple"
          placeholder="対象学年を選択してください（複数可）"
        >
          {[0, 1, 2, 3, 4, 5, 7, 8].map(i => (
            <Option key={i} value={i}>{`${i}歳児`}</Option>
          ))}
        </Select>
      </div>
    )
  }
}

import React from 'react'
import ChildExcrementTableCell from '../TableCells/ChildExcrementTableCell'

export default ({ dataSource, putExcrement, removeExcrement }) => (
  <div>
    {dataSource.map((data, idx) => (
      <ChildExcrementTableCell
        key={idx}
        data={data}
        putExcrement={putExcrement}
        removeExcrement={removeExcrement}
      />
    ))}
  </div>
)

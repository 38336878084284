import {
  SET_CHILD_ATTENDANCE_LIST,
  SET_CHILD_ATTENDANCE_STATUS,
  SET_CHILD_TODAY_ATTENDANCE_LIST,
  SET_FLAG_FOR_CHILD_ATTENDANCE_SHOULD_LOAD,
  SET_NURSERY_CHILDREN_ATTENDANCE_LIST,
  SIGN_OUT,
} from '../constants/actionTypes'
import { ChildAttendanceStatus } from '../models/childAttendance'

const DEFAULT_STATE = {
  status: new ChildAttendanceStatus({}),
  listForNursery: [],
  listForChild: [],
  listForTodayChild: [],
  shouldLoad: true,
  selected: null,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_NURSERY_CHILDREN_ATTENDANCE_LIST:
      return { ...state, listForNursery: action.payload, shouldLoad: false }
    case SET_CHILD_ATTENDANCE_LIST:
      return { ...state, listForChild: action.payload, shouldLoad: false }
    case SET_CHILD_TODAY_ATTENDANCE_LIST:
      return { ...state, listForTodayChild: action.payload }
    case SET_FLAG_FOR_CHILD_ATTENDANCE_SHOULD_LOAD:
      return { ...state, shouldLoad: true }
    case SET_CHILD_ATTENDANCE_STATUS:
      return { ...state, status: action.payload }
    case SIGN_OUT:
      return DEFAULT_STATE
    default:
      return state
  }
}

import { getParentReportListForNursery } from '../services/v1ApiRequest'
import { SET_PARENT_REPORT_LIST, START_LOADING, STOP_LOADING } from '../constants/actionTypes'
import moment from 'moment'
import ParentReport from '../models/correspondence/parentReport'
import { setServerError } from './apps'

export const fetchParentReports = (nurseryId, date) => async dispatch => {
  dispatch({ type: START_LOADING })
  const dateStr = moment(date).format('YYYY-MM-DD')
  return getParentReportListForNursery(nurseryId, dateStr)
    .then(res => {
      dispatch(setList(res.data.parent_reports.map(data => new ParentReport(data))))
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

const setList = list => ({
  type: SET_PARENT_REPORT_LIST,
  payload: list,
})

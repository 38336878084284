import { transformSnakeToCamel } from '../../utils/snakeCamelConverter'

export default class ChildContactBook {
  constructor({
    id,
    message,
    publishStatus,
    publishesAt,
    notifyByEmail,
    amMood,
    pmMood,
    tookBath,
    numberOfDiapers,
  }) {
    this.id = id
    this.message = message
    this.publishStatus = publishStatus
    this.publishesAt = publishesAt
    this.notifyByEmail = notifyByEmail
    this.amMood = amMood
    this.pmMood = pmMood
    this.tookBath = tookBath
    this.numberOfDiapers = numberOfDiapers
  }

  static newFromApiResponse = data => {
    return new ChildContactBook(transformSnakeToCamel(data))
  }
}

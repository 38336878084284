import React from 'react'
import { connect } from 'react-redux'
import { Button, Input, Modal, notification } from 'antd'
import { updateChildAttendanceForChild } from '../../../actions/childAttendance'
import moment from './EndsPlayAtCell'

const { TextArea } = Input

class NoticeCell extends React.Component {
  state = {
    visibleModal: false,
    value: '',
  }

  getStatus = () => {
    const { record } = this.props
    if (record.attendanceExist) {
      return 'editable'
    } else {
      return 'disable'
    }
  }

  onValueChange = e => {
    this.setState({ value: e.target.value })
  }

  createOrUpdateData = () => {
    const { record } = this.props
    const { value } = this.state
    if (!value) {
      notification.warn({ message: '値を入力してください' })
      return
    }

    if (this.getStatus() === 'editable') {
      this.props.updateAttendance(record.attendanceId, {
        play: {
          notice: value,
        },
      })
    }
    this.closeModal()
  }

  openModal = () => {
    if (this.getStatus() === 'disable') {
      return
    }

    const { cellData } = this.props
    const timeVal = cellData ? moment(cellData) : null
    this.setState({
      visibleModal: true,
      value: timeVal,
    })
  }

  closeModal = () => {
    this.setState({ visibleModal: false })
  }

  render() {
    const { cellData } = this.props
    let { visibleModal, value } = this.state
    return (
      <div>
        <div>
          {cellData}
          <br />
          <Button
            onClick={this.openModal}
            type="primary"
            size="small"
            shape="circle"
            icon={cellData ? 'edit' : 'plus'}
          />
        </div>

        <Modal
          title={cellData ? '特記事項を編集' : '特記事項を追加'}
          visible={visibleModal}
          okText={cellData ? '更新' : '追加'}
          onOk={this.createOrUpdateData}
          onCancel={this.closeModal}
        >
          <TextArea onChange={this.onValueChange} value={value} rows={4} />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ app, child, nursery, childAttendance }) => ({
  isLoading: app.isLoading,
  nursery: nursery.mine,
  shouldChildrenLoad: child.shouldLoad,
  shouldAttendanceLoad: childAttendance.shouldLoad,
})

const mapDispatchToProps = dispatch => ({
  updateAttendance: (attendId, params) => dispatch(updateChildAttendanceForChild(attendId, params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NoticeCell)

import { transformSnakeToCamel } from '../../utils/snakeCamelConverter'
import Child from '../child'

export class ChildBodyTemperature {
  constructor({ id, degree, executedAt }) {
    this.id = id
    this.degree = degree
    this.executedAt = executedAt ? new Date(executedAt) : null
  }

  static newFromApiResponse = data => {
    return new ChildBodyTemperature(transformSnakeToCamel(data))
  }
}

export default class ChildBodyTemperatureHistory {
  constructor({ child, bodyTemperatures }) {
    this.child = new Child(child || {})
    this.bodyTemperatures = bodyTemperatures.map(data => new ChildBodyTemperature(data))
  }

  static newFromApiResponse = data => {
    return new ChildBodyTemperatureHistory(transformSnakeToCamel(data))
  }
}

import { notification } from 'antd'
import {
  deleteMyAvatar,
  getAccount,
  getMyAccount,
  patchMyAccount,
  patchMyPassword,
  postMyAvatar,
  setAuthHeader,
} from '../services/v1ApiRequest'
import { postSignIn, postSignUp } from '../services/authApiRequest'
import {
  SET_ACCOUNT,
  SET_ACCOUNT_ERRORS,
  SET_OTHER_ACCOUNT,
  SET_PROFILE,
  SIGN_IN_ACCOUNT,
  SIGN_OUT,
  START_LOADING,
  STOP_LOADING,
} from '../constants/actionTypes'
import { setServerError } from './apps'
import { push } from 'connected-react-router'
import routes from '../routes'
import responseStatuses from '../constants/responseStatuses'
import Account from '../models/account'

export const fetchCurrentAccount = () => dispatch => {
  dispatch({ type: START_LOADING })
  return getMyAccount()
    .then(res => {
      return dispatch(setProfile(Account.newFromApiResponse(res.data)))
    })
    .catch(e => {
      if (e.response) {
        if (e.response.status === responseStatuses.UNAUTHENTICATED) {
          forceLogout(dispatch)
        } else {
          dispatch(setErrors(e.response.data.errors))
        }
      } else {
        dispatch(setErrors(['responseを取得できませんでした']))
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const fetchAccount = accountId => async dispatch => {
  dispatch({ type: START_LOADING })
  return getAccount(accountId)
    .then(res => {
      dispatch(setAccount(Account.newFromApiResponse(res.data)))
    })
    .catch(e => {
      if (e.response) {
        if (e.response.status === responseStatuses.UNAUTHENTICATED) {
          forceLogout(dispatch)
        } else {
          dispatch(setErrors(e.response.data.errors))
        }
      } else {
        dispatch(setErrors(['responseを取得できませんでした']))
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const uploadMyAvatar = file => async dispatch => {
  dispatch({ type: START_LOADING })
  const formData = new FormData()
  formData.append('avatar', file)
  postMyAvatar(formData)
    .then(res => {
      dispatch(setAccount(Account.newFromApiResponse(res.data)))
      notification.success({ message: 'プロフィール画像を設定しました' })
    })
    .catch(e => {
      if (e.response) {
        if (e.response.status === responseStatuses.UNAUTHENTICATED) {
          forceLogout(dispatch)
        } else {
          dispatch(setErrors(e.response.data.errors))
        }
      } else {
        dispatch(setServerError(['responseを取得できませんでした']))
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const destroyMyAvatar = () => async dispatch => {
  dispatch({ type: START_LOADING })
  return deleteMyAvatar()
    .then(res => {
      dispatch(setAccount(Account.newFromApiResponse(res.data)))
    })
    .catch(e => {
      if (e.response) {
        if (e.response.status === responseStatuses.UNAUTHENTICATED) {
          forceLogout(dispatch)
        } else {
          dispatch(setErrors(e.response.data.errors))
        }
      } else {
        dispatch(setServerError(['responseを取得できませんでした']))
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const updateMyAccount = params => async dispatch => {
  dispatch({ type: START_LOADING })
  return patchMyAccount({ account: params })
    .then(res => {
      dispatch(setAccount(Account.newFromApiResponse(res.data)))
      notification.success({ message: '更新に成功しました' })
    })
    .catch(e => {
      if (e.response) {
        if (e.response.status === responseStatuses.UNAUTHENTICATED) {
          forceLogout(dispatch)
        } else {
          dispatch(setErrors(e.response.data.errors))
        }
      } else {
        dispatch(setErrors(['responseを取得できませんでした']))
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const updatePassword = params => dispatch => {
  dispatch({ type: START_LOADING })
  return patchMyPassword({ account: params })
    .then(res => {
      setAuthHeader(res.data.token)
      dispatch(signInAccount({ ...res.data.account, authToken: res.data.token }))
      notification.success({ message: 'Passwordを更新しました' })
    })
    .catch(e => {
      if (e.response) {
        if (e.response.status === responseStatuses.UNAUTHENTICATED) {
          forceLogout(dispatch)
        } else {
          dispatch(setErrors(e.response.data.errors))
        }
      } else {
        dispatch(setServerError(['responseを取得できませんでした']))
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const fetchOtherAccount = accountId => dispatch => {
  dispatch({ type: START_LOADING })
  return getAccount(accountId)
    .then(res => {
      dispatch(setOtherAccount(Account.newFromApiResponse(res.data)))
    })
    .catch(e => {
      if (e.response.status === responseStatuses.UNAUTHENTICATED) {
      } else {
        dispatch(setErrors(e.response.data.errors))
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const signUp = formProps => dispatch => {
  dispatch({ type: START_LOADING })
  return postSignUp({ account: formProps })
    .then(res => {
      setAuthHeader(res.data.token)
      dispatch(signInAccount({ ...res.data.account, authToken: res.data.token }))
      dispatch(push(routes.dashboard()))
      notification.success({ message: 'Register Success' })
    })
    .catch(e => {
      if (e.response) {
        if (e.response.status === responseStatuses.UNAUTHENTICATED) {
          dispatch(setErrors([e.response.data.message]))
        }
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const signIn = formProps => dispatch => {
  dispatch({ type: START_LOADING })
  return postSignIn({ account: formProps })
    .then(res => {
      setAuthHeader(res.data.token)
      dispatch(signInAccount({ ...res.data.account, authToken: res.data.token }))
      dispatch(push(routes.dashboard()))
      notification.success({ message: 'Login Success' })
    })
    .catch(e => {
      if (e.response) {
        if (e.response.status === responseStatuses.UNAUTHENTICATED) {
          dispatch(setErrors([e.response.data.message]))
        }
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

const setAccount = user => ({
  type: SET_ACCOUNT,
  payload: { ...user, isLoggedIn: true, errors: [] },
})

const setProfile = user => ({
  type: SET_PROFILE,
  payload: user,
})

const setOtherAccount = user => ({
  type: SET_OTHER_ACCOUNT,
  payload: { ...user },
})

const signInAccount = user => ({
  type: SIGN_IN_ACCOUNT,
  payload: { ...user, isLoggedIn: true, errors: [] },
})

const setErrors = errors => ({
  type: SET_ACCOUNT_ERRORS,
  payload: errors,
})

export const forceLogout = dispatch => {
  dispatch(signOut())
  notification.warning({ message: 'You need Login' })
  dispatch(push(routes.signIn()))
}

export const selfSignOut = () => dispatch => {
  dispatch(signOut())
  notification.success({ message: 'Logout Success' })
  dispatch(push(routes.top()))
}

export const signOut = () => ({
  type: SIGN_OUT,
})

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Form, Input, Select, Upload } from 'antd'
import { updateMyNursery } from '../../actions/nursery'
import { jpStates } from '../../constants/jpStates'
import style from '../../styles/SettingsPage.module.scss'
import Nursery from '../../models/nursery'

const { Option } = Select

const stateSelector = (
  <Select style={{ width: '30%' }} initialValue="東京">
    {jpStates.map(item => (
      <Option key={item.code} value={item.name}>
        {item.name}
      </Option>
    ))}
  </Select>
)

class NurserySetting extends React.Component {
  onUpdate = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updateNurserySetting({ nursery: values })
      }
    })
  }

  uploadThumbnail = file => {
    const formData = new FormData()
    formData.append('avatar', file)
    this.props.updateNurserySetting(formData)
    return false
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { isLoading, nursery } = this.props
    const safeNursery = nursery || new Nursery({})
    return (
      <div>
        <div
          className={style.thumbnail}
          style={{ backgroundImage: `url(${safeNursery.thumbnailUrl})` }}
        >
          <div className={style.thumbnailEditButtonWrap}>
            <Upload name="avatar" beforeUpload={this.uploadThumbnail} disabled={isLoading}>
              <Button type="primary">サムネイルを変更</Button>
            </Upload>
          </div>
        </div>
        <div className={style.settingContent}>
          <h2>園の基本情報</h2>

          <Form onSubmit={this.onUpdate}>
            <Form.Item label="園の名前">
              {getFieldDecorator('name', {
                initialValue: safeNursery.name,
                rules: [{ required: true, message: '名前を入力してください' }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="園コード">
              {getFieldDecorator('nursery_code', {
                initialValue: safeNursery.nurseryCode,
              })(<Input />)}
            </Form.Item>
            {/*<Form.Item label="業者名">*/}
            {/*  {getFieldDecorator('organizer[name]', {*/}
            {/*    initialValue: safeNursery.organizer && safeNursery.organizer.name,*/}
            {/*  })(<Input />)}*/}
            {/*</Form.Item>*/}
            <Form.Item label="郵便番号">
              {getFieldDecorator('postcode', {
                initialValue: safeNursery.postcode,
              })(<Input />)}
            </Form.Item>
            <Form.Item label="都道府県">
              {getFieldDecorator('state', {
                initialValue: safeNursery.state,
              })(stateSelector)}
            </Form.Item>
            <Form.Item label="住所">
              {getFieldDecorator('address', {
                initialValue: safeNursery.name,
              })(<Input />)}
            </Form.Item>
            <Form.Item label="建物名">
              {getFieldDecorator('building', {
                initialValue: safeNursery.name,
              })(<Input />)}
            </Form.Item>
            <Form.Item label="電話番号">
              {getFieldDecorator('phone', {
                initialValue: safeNursery.phone,
              })(<Input />)}
            </Form.Item>
            {/*<Form.Item label="メールアドレス">*/}
            {/*  {getFieldDecorator('email', {*/}
            {/*    initialValue: safeNursery.email,*/}
            {/*    rules: [*/}
            {/*      {*/}
            {/*        type: 'email',*/}
            {/*        message: 'The input is not valid E-mail!',*/}
            {/*      },*/}
            {/*    ],*/}
            {/*  })(<Input />)}*/}
            {/*</Form.Item>*/}

            <div style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit">
                更新
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ app, account, nursery }) => ({
  isLoading: app.isLoading,
  account: account,
  nursery: nursery.mine,
})

const mapDispatchToProps = dispatch => ({
  updateNurserySetting: params => dispatch(updateMyNursery(params)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'nursery' })(withRouter(NurserySetting)))

import React from 'react'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
import { setSelectedDate } from '../../actions/apps'
import './styles/PutNurseryDiaryForm.scss'

const { Option } = Select
const { TextArea } = Input
const rSpanHeight = 24
const style = {
  tdLabel: {
    border: '1px solid #ddd',
    width: 24,
    fontSize: 12,
    textAlign: 'center',
  },
  flexExpand: {
    flex: 1,
  },
  date: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '32px',
    padding: '0 8px',
  },
  rSpan1: {
    border: '1px solid #ddd',
    height: rSpanHeight,
  },
  rSpan2: {
    border: '1px solid #ddd',
    height: rSpanHeight * 2,
  },
  rSpan4: {
    border: '1px solid #ddd',
    height: rSpanHeight * 4,
  },
  rSpan6: {
    border: '1px solid #ddd',
    height: rSpanHeight * 6,
  },
  rSpan12: {
    border: '1px solid #ddd',
    height: rSpanHeight * 12,
  },
}

class PutNurseryDiaryForm extends React.Component {
  prev = e => {
    const { selectedDate } = this.props
    this.props.setDate(new Date(selectedDate).setDate(new Date(selectedDate).getDate() - 1))
  }

  next = e => {
    const { selectedDate } = this.props
    this.props.setDate(new Date(selectedDate).setDate(new Date(selectedDate).getDate() + 1))
  }

  getChildrenNum = (age, gender) => {
    // TODO: impl
    return 0
  }

  getChildAttendNum = age => {
    // TODO: impl
    return 0
  }

  getChildAbsentNum = age => {
    // TODO: impl
    return 0
  }

  onSubmit = e => {
    // TODO: 職員APIが有効になったらリストから選択式に
    const { account } = this.props
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      values['writer_id'] = account.id
      values['author_id'] = account.id
      if (!err) {
        this.props.onSubmit({
          nursery_diary: values,
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { selectedDate, nursery, account, staffAttendance, data } = this.props
    return (
      <div className="PutNurseryDiaryForm">
        <section style={{ marginBottom: 24 }}>
          <Row type="flex" justify="space-between">
            <Col>
              <Row type="flex" justify="space-between">
                <Col>
                  <Button onClick={this.prev} icon="left" />
                </Col>
                <Col>
                  <div style={style.date}>{moment(selectedDate).format('MM月DD日(ddd)')}</div>
                </Col>
                <Col>
                  <Button onClick={this.next} icon="right" />
                </Col>
              </Row>
            </Col>
          </Row>
        </section>

        <section>
          <h2>園日誌</h2>

          <Form onSubmit={this.onSubmit}>
            <Row>
              <Col span={14} style={style.rSpan2}>
                <h3>{moment(selectedDate).format('西暦 YYYY 年 MM 月 DD 日 (ddd)')}</h3>
              </Col>
              <Col span={10}>
                <Row>
                  <Col span={8} style={style.rSpan2}>
                    <Row type="flex">
                      <Col style={style.tdLabel}>天気</Col>
                      <Col style={style.flexExpand}>
                        <Form.Item label="" style={{ margin: 0 }}>
                          {getFieldDecorator('weather', {
                            initialValue: data.weather || 'sunny',
                            rules: [],
                          })(
                            <Select size="small">
                              <Option value="sunny">晴</Option>
                              <Option value="cloudy">曇り</Option>
                              <Option value="rainy">雨</Option>
                              <Option value="snowy">雪</Option>
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8} style={style.rSpan2}>
                    <Row type="flex">
                      <Col style={style.tdLabel}>園長</Col>
                      <Col style={style.flexExpand}>{nursery.head.fullName}</Col>
                    </Row>
                  </Col>
                  <Col span={8} style={style.rSpan2}>
                    <Row type="flex">
                      <Col style={style.tdLabel}>担当</Col>
                      <Col style={style.flexExpand}>{account.fullName}</Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col span={14}>
                <Row type="flex">
                  <Col style={style.tdLabel}>職員出欠勤</Col>
                  <Col style={style.flexExpand}>
                    <Row type="flex">
                      <Col style={style.rSpan2} span={8}>
                        欠勤(未実装)
                      </Col>
                      <Col style={style.rSpan2} span={16}>
                        {staffAttendance.absent}
                      </Col>
                    </Row>
                    <Row type="flex">
                      <Col style={style.rSpan2} span={8}>
                        早退(未実装)
                      </Col>
                      <Col style={style.rSpan2} span={16}>
                        {staffAttendance.leaveEarly}
                      </Col>
                    </Row>
                    <Row type="flex">
                      <Col style={style.rSpan2} span={8}>
                        遅刻(未実装)
                      </Col>
                      <Col style={style.rSpan2} span={16}>
                        {staffAttendance.late}
                      </Col>
                    </Row>
                    <Row type="flex">
                      <Col style={style.rSpan2} span={8}>
                        出張・研修(未実装)
                      </Col>
                      <Col style={style.rSpan2} span={16}>
                        {staffAttendance.businessTrip}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row type="flex">
                  <Col style={style.tdLabel}>保護者連絡</Col>
                  <Col style={Object.assign(style.rSpan4, style.flexExpand)}>
                    <Form.Item label="">
                      {getFieldDecorator('for_parent', {
                        initialValue: data.forParent,
                        rules: [],
                      })(<TextArea placeholder="(未実装)" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex">
                  <Col style={style.tdLabel}>来園者</Col>
                  <Col style={Object.assign(style.rSpan4, style.flexExpand)}>
                    (未実装)
                    {data.visitors.map(visitor => (
                      <span>
                        {visitor.name}({visitor.organizationName}),{' '}
                      </span>
                    ))}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col span={14}>
                <Row type="flex">
                  <Col style={style.tdLabel}>園児出欠者</Col>
                  <Col style={style.flexExpand}>
                    <Row>
                      <Col span={9}>
                        <Col style={style.rSpan2}>クラス名</Col>
                        <Col style={style.rSpan2}>0歳児全体</Col>
                        <Col style={style.rSpan2}>1歳児全体</Col>
                        <Col style={style.rSpan2}>2歳児全体</Col>
                        <Col style={style.rSpan2}>3歳児全体</Col>
                        <Col style={style.rSpan2}>4歳児全体</Col>
                        <Col style={style.rSpan2}>5歳児全体</Col>
                        <Col style={style.rSpan2} />
                        <Col style={style.rSpan2}>計</Col>
                      </Col>
                      <Col span={9}>
                        <Col style={style.rSpan1}>在籍</Col>
                        <Row>
                          <Col span={8}>
                            <Col style={style.rSpan1}>男</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(0, 'male')}</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(1, 'male')}</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(2, 'male')}</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(3, 'male')}</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(4, 'male')}</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(5, 'male')}</Col>
                            <Col style={style.rSpan2} />
                            <Col style={style.rSpan2}>{this.getChildrenNum(null, 'male')}</Col>
                          </Col>
                          <Col span={8}>
                            <Col style={style.rSpan1}>女</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(0, 'female')}</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(1, 'female')}</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(2, 'female')}</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(3, 'female')}</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(4, 'female')}</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(5, 'female')}</Col>
                            <Col style={style.rSpan2} />
                            <Col style={style.rSpan2}>{this.getChildrenNum(null, 'female')}</Col>
                          </Col>
                          <Col span={8}>
                            <Col style={style.rSpan1}>女</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(0)}</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(1)}</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(2)}</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(3)}</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(4)}</Col>
                            <Col style={style.rSpan2}>{this.getChildrenNum(5)}</Col>
                            <Col style={style.rSpan2} />
                            <Col style={style.rSpan2}>{this.getChildrenNum()}</Col>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={3}>
                        <Col style={style.rSpan2}>出席</Col>
                        {[...Array(6)].map((i, idx) => (
                          <Col key={idx} style={style.rSpan2}>
                            {this.getChildAttendNum(i)}
                          </Col>
                        ))}
                        <Col style={style.rSpan2} />
                        <Col style={style.rSpan2}>{this.getChildAttendNum()}</Col>
                      </Col>
                      <Col span={3}>
                        <Col style={style.rSpan2}>欠席</Col>
                        {[...Array(6)].map((i, idx) => (
                          <Col key={idx} style={style.rSpan2}>
                            {this.getChildAbsentNum(i)}
                          </Col>
                        ))}
                        <Col style={style.rSpan2} />
                        <Col style={style.rSpan2}>{this.getChildAttendNum()}</Col>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row type="flex">
                  <Col style={style.tdLabel}>行事</Col>
                  <Col style={Object.assign(style.rSpan6, style.flexExpand)}>
                    (未実装)
                    {data.events.map(event => (
                      <span>
                        {event.title} ({moment(event.startsAt).format('HH:mm')} -{' '}
                        {moment(event.endsAt).format('HH:mm')}),{' '}
                      </span>
                    ))}
                  </Col>
                </Row>
                <Row type="flex">
                  <Col style={style.tdLabel}>事故</Col>
                  <Col style={Object.assign(style.rSpan6, style.flexExpand)}>(未実装)</Col>
                </Row>
                <Row type="flex">
                  <Col style={style.tdLabel}>連絡引継事項</Col>
                  <Col style={Object.assign(style.rSpan6, style.flexExpand)}>
                    <Form.Item label="">
                      {getFieldDecorator('handover', {
                        initialValue: data.handover,
                        rules: [],
                      })(<TextArea rows={4} />)}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row type="flex">
              <Col style={style.tdLabel}>保育記事</Col>
              <Col style={Object.assign(style.rSpan12, style.flexExpand)}>
                <Form.Item label="">
                  {getFieldDecorator('nursery_note', {
                    initialValue: data.nurseryNote,
                    rules: [],
                  })(<TextArea rows={8} />)}
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex">
              <Col style={style.tdLabel}>その他</Col>
              <Col style={Object.assign(style.rSpan6, style.flexExpand)}>
                <Form.Item label="">
                  {getFieldDecorator('other', {
                    initialValue: data.other,
                    rules: [],
                  })(<TextArea rows={4} />)}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                保存
              </Button>
            </Form.Item>
          </Form>
        </section>
      </div>
    )
  }
}

const mapStateToProps = ({ app, account, nursery, staffAttendance }) => ({
  selectedDate: app.selectedDate,
  account,
  staffList: nursery.staffList,
  nursery: nursery.mine,
  staffAttendance: staffAttendance.status,
})

const mapDispatchToProps = dispatch => ({
  setDate: date => dispatch(setSelectedDate(date)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'EditNurseryDiaryForm' })(PutNurseryDiaryForm))

import { transformSnakeToCamel } from '../utils/snakeCamelConverter'

export default class Parent {
  constructor({ id, height, weight, width, chest, headCircumference, date }) {
    this.id = id
    this.height = Number(height)
    this.weight = Number(weight)
    this.width = Number(width)
    this.chest = Number(chest)
    this.headCircumference = Number(headCircumference)
    this.date = date ? new Date(date) : new Date()
  }

  static newFromApiResponse = data => {
    return new Parent(transformSnakeToCamel(data))
  }
}

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Avatar, Button, Col, Modal, Row, Skeleton } from 'antd'
import { fetchScannedChild, setScannedChild } from '../../../actions/child'
import {
  addChildAttendanceForChild,
  fetchChildTodayAttendanceList,
  updateChildAttendanceForChild,
} from '../../../actions/childAttendance'
import QrReader from 'react-qr-reader'
import moment from 'moment'
import style from './index.module.scss'
import Child from '../../../models/child'

const scanTarget = {
  childAttendance: {
    type: 'CHILD_ATTENDANCE',
  },
  staffAttendance: {
    type: 'STAFF_ATTENDANCE',
  },
}

class _Index extends React.Component {
  state = {
    scanning: true,
    scannedType: null,
    isFrontCam: true,
    didFetchChild: false,
    didFetchAttendance: false,
  }

  onFinishSubmittingAttendance = () => {
    this.props.setNullChild()
    this.setState({
      scanning: true,
      scannedType: null,
      didFetchChild: false,
      didFetchAttendance: false,
      didSubmitAttendance: false,
    })
  }

  handleScan = data => {
    const dataObj = JSON.parse(data)
    if (dataObj && (dataObj.type || dataObj.id)) {
      switch (dataObj.type) {
        case scanTarget.childAttendance.type:
          this.props.fetchChild(dataObj.id).then(() => this.setState({ didFetchChild: true }))
          this.props
            .fetchTodayAttendance(dataObj.id)
            .then(() => this.setState({ didFetchAttendance: true }))
          this.setState({
            scannedType: scanTarget.childAttendance.type,
            scanning: false,
          })
          return
        case scanTarget.staffAttendance.type:
          // TODO: impl
          this.setState({
            scannedType: scanTarget.staffAttendance.type,
            scanning: false,
          })
          return
        default:
          return
      }
    }
  }

  handleScanError = err => {
    console.log(err)
  }

  onChangeCamera = () => {
    this.setState({ isFrontCam: !this.state.isFrontCam })
  }

  onCloseModal = () => {
    this.props.setNullChild()
    this.setState({ scanning: true })
    this.props.onCancel()
  }

  _onBegin = () => {
    const { child } = this.props
    this.props
      .attend(child.id, {
        child_attendance: {
          type: 'attend',
          begins_at: moment(new Date()).format(),
          date: moment(new Date()).format('YYYY-MM-DD'),
        },
      })
      .then(code => {
        if (code === 409) {
          this._onEnd()
        } else {
          this.onFinishSubmittingAttendance()
        }
      })
    this.setState({ didSubmitAttendance: true })
  }

  _onEnd = () => {
    const { childAttendance } = this.props
    const attendance = childAttendance[0]
    this.props
      .goHome(attendance.id, {
        child_attendance: {
          ends_at: moment(new Date()).format(),
        },
      })
      .then(() => {
        this.onFinishSubmittingAttendance()
      })
    this.setState({ didSubmitAttendance: true })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { childAttendance } = this.props
    const {
      scannedType,
      scanning,
      didFetchChild,
      didFetchAttendance,
      didSubmitAttendance,
    } = this.state
    const attendance = childAttendance[0]
    const beginsAt = attendance && attendance.beginsAt
    // スキャンが終わった
    if (!scanning) {
      // キャンしたものに関するデータのロードが完了した
      if (didFetchChild && didFetchAttendance) {
        // まだ勤怠データを送信していない
        if (!didSubmitAttendance) {
          switch (scannedType) {
            case scanTarget.childAttendance.type:
              beginsAt ? this._onEnd() : this._onBegin()
              break
            case scanTarget.staffAttendance.type:
              // TODO: impl
              console.log('未実装')
              break
            default:
              break
          }
        }
      }
    }
  }

  getResultComponent = () => {
    const { child, isLoading } = this.props
    // TODO: 他のscannedTypeの時も対処
    return (
      <div>
        <Skeleton active avatar paragraph={{ rows: 2 }} loading={isLoading}>
          <div>
            <Row type="flex" gutter={16}>
              <Col>
                <Avatar src={child && child.avatarUrl} size={48} />
              </Col>
              <Col>
                <div style={{ fontSize: 20 }}>{child && child.fullName}</div>
                <div>{child && `${child.lastNameKana} ${child.firstNameKana}`}</div>
              </Col>
            </Row>
          </div>
        </Skeleton>
      </div>
    )
  }

  getActionComponent = () => {
    const { childAttendance, isLoading } = this.props
    const attendance = childAttendance[0]
    const beginsAt = attendance && attendance.beginsAt,
      endsAt = attendance && attendance.endsAt

    return (
      <div>
        <h3>
          現在時刻:
          {moment(new Date()).format('YYYY/MM/DD HH:mm')}で
          {isLoading ? '...' : !beginsAt && !endsAt ? '登園を打刻中' : '降園を打刻中'}
        </h3>
      </div>
    )
  }

  render() {
    const { visible } = this.props
    const { scanning, isFrontCam } = this.state
    return (
      <div>
        <Modal
          title="QRコードをスキャン"
          visible={visible}
          onOk={this.onCloseModal}
          onCancel={this.onCloseModal}
          destroyOnClose={true}
          className={style.modal}
        >
          {scanning ? (
            <div className={style.qrReaderWrap}>
              <QrReader
                facingMode={isFrontCam ? 'user' : 'environment'}
                delay={300}
                onError={this.handleScanError}
                onScan={this.handleScan}
                className={style.qrCamZone}
              />
              <div className={style.cameraChanger}>
                <Button type="primary" shape="circle" icon="sync" onClick={this.onChangeCamera} />
              </div>
            </div>
          ) : (
            this.getResultComponent()
          )}

          <div className={style.actionZone}>
            <section>
              <div className="sectionInnerWrap">
                <h2>{scanning ? '検出中...' : ''}</h2>

                <div>{scanning ? null : this.getActionComponent()}</div>
              </div>
            </section>
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ app, child, childAttendance }) => ({
  isLoading: app.isLoading,
  child: child.scanned,
  childAttendance: childAttendance.listForTodayChild,
})

const mapDispatchToProps = dispatch => ({
  fetchChild: id => dispatch(fetchScannedChild(id)),
  fetchTodayAttendance: childId => dispatch(fetchChildTodayAttendanceList(childId, new Date())),
  setNullChild: () => dispatch(setScannedChild(new Child({}))),
  attend: (childId, params) => dispatch(addChildAttendanceForChild(childId, params)),
  goHome: (attendId, params) => dispatch(updateChildAttendanceForChild(attendId, params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(_Index))

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { Button, Table, Typography } from 'antd'
import SelectAttendanceTypeCell from '../TableCells/ChildAttendance/SelectAttendanceTypeCell'
import NoticeCell from '../TableCells/ChildAttendance/NoticeCell'
import BeginsAtCell from '../TableCells/ChildAttendance/BeginsAtCell'
import BeginsPlayAtCell from '../TableCells/ChildAttendance/BeginsPlayAtCell'
import EndsPlayAtCell from '../TableCells/ChildAttendance/EndsPlayAtCell'
import EndsAtCell from '../TableCells/ChildAttendance/EndsAtCell'
import {
  addChildAttendanceForChild,
  addPlayForChildAttendance,
  deleteChildAttendanceForChild,
  deletePlayForChildAttendance,
  fetchChildAttendanceListForNursery,
  updateChildAttendanceForChild,
  updatePlayForChildAttendance,
} from '../../actions/childAttendance'
import { setChild } from '../../actions/child'
import routes from '../../routes'
import queryString from 'query-string'
import moment from 'moment'

const ShareContext = React.createContext()
const { Text } = Typography

const _columns = [
  {
    title: '名前',
    dataIndex: 'child',
    width: '20%',
    sorter: (a, b) => a.child.nameForSearch > b.child.nameForSearch,
    defaultSortOrder: 'ascend',
  },
  { title: '出欠', dataIndex: 'attendanceType', width: '10%' },
  { title: '連絡/メモ', dataIndex: 'notice', width: '10%' },
  { title: '登園', dataIndex: 'beginsAt', width: '10%' },
  { title: '外出', dataIndex: 'beginsPlayAt', width: '10%' },
  { title: '戻り', dataIndex: 'endsPlayAt', width: '10%' },
  { title: '降園', dataIndex: 'endsAt', width: '10%' },
  { title: '保育時間', dataIndex: 'totalCareTime', width: '10%' },
  { title: '外出時間', dataIndex: 'totalPlayTime', width: '10%' },
]

class ChildAttendanceTableCell extends React.Component {
  renderCell = onChildTap => {
    const {
      createAttendance,
      updateAttendance,
      deleteAttendance,
      createPlay,
      updatePlay,
      deletePlay,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props
    let element
    if (dataIndex === 'child') {
      const data = record.child
      // TODO: onChildTapでいきなり詳細の登降園管理のところ飛べるように
      element = (
        <div>
          <Button
            size="large"
            style={{ padding: 0, maxWidth: 180 }}
            type="link"
            onClick={onChildTap(data)}
          >
            {data ? data.fullName : '氏名'}
          </Button>
          <br />
          <Text strong>{data ? data.classRoom.nursery.name : '保育園'}</Text>
          <br />
          <Text disabled>
            {data ? data.getAge() : 0}歳児 {data ? data.classRoom.name : 'クラス'}
          </Text>
        </div>
      )
    } else if (dataIndex === 'attendanceType') {
      element = <SelectAttendanceTypeCell cellData={record.attendanceType} record={record} />
    } else if (dataIndex === 'notice') {
      element = <NoticeCell cellData={record.notice} record={record} />
    } else if (dataIndex === 'beginsAt') {
      element = (
        <BeginsAtCell
          cellData={record.beginsAt}
          record={record}
          addAttendance={createAttendance}
          updateAttendance={updateAttendance}
          deleteAttendance={deleteAttendance}
        />
      )
    } else if (dataIndex === 'beginsPlayAt') {
      element = (
        <BeginsPlayAtCell
          cellData={record.beginsPlayAt}
          record={record}
          addPlay={createPlay}
          updatePlay={updatePlay}
          deletePlay={deletePlay}
        />
      )
    } else if (dataIndex === 'endsPlayAt') {
      element = (
        <EndsPlayAtCell cellData={record.endsPlayAt} record={record} updatePlay={updatePlay} />
      )
    } else if (dataIndex === 'endsAt') {
      element = (
        <EndsAtCell cellData={record.endsAt} record={record} updateAttendance={updateAttendance} />
      )
    } else if (dataIndex === 'totalCareTime') {
      element = <div>{record.totalCareTime || '--'}</div>
    } else if (dataIndex === 'totalPlayTime') {
      element = <div>{record.totalPlayTime || '--'}</div>
    }

    return <td {...restProps}>{element}</td>
  }

  render() {
    return <ShareContext.Consumer>{this.renderCell}</ShareContext.Consumer>
  }
}

class ChildAttendanceTable extends React.Component {
  state = {
    pagination: {
      defaultPageSize: 20,
      current: 1,
    },
    didInitPagination: false,
  }

  onChildTap = child => () => {
    this.props.setChild(child)
    this.props.push(routes.childrenBook(child.id))
  }

  onChange = (pagination, filters, sorter) => {
    let qDate =
      queryString.parse(this.props.location.search)['date'] ||
      moment(new Date()).format('YYYY-MM-DD')
    this.props.push(`${routes.childAttendance()}?date=${qDate}&page=${pagination.current}`)
    const { nursery, selectedDate } = this.props
    const pager = { ...this.state.pagination }
    pager.current = pagination.current
    this.setState({ pagination: pager })
    const targetDate = selectedDate ? new Date(selectedDate.format('YYYY-MM-DD')) : new Date()
    this.props.fetchAllAttendances(nursery.id, targetDate, pagination.current)
  }

  recordDataList = () => {
    const { attendanceList, classFilter, selectedChild, selectedDate } = this.props
    const records = attendanceList.map((attendance, idx) => {
      return {
        key: idx,
        child: attendance.child,
        attendanceExist: !!attendance.id,
        attendanceId: attendance.id,
        lastPlayId: attendance.lastPlayId,
        attendanceType: attendance.type,
        notice: attendance.notice,
        beginsAt: attendance.beginsAt,
        endsAt: attendance.endsAt,
        beginsPlayAt: attendance.beginsPlayAt,
        endsPlayAt: attendance.endsPlayAt,
        plays: attendance.plays,
        totalCareTime: attendance.totalCareTime(),
        totalPlayTime: attendance.totalPlayTime(),
        date: selectedDate,
      }
    })
    let res =
      classFilter === '全て'
        ? records
        : records.filter(record => record.child && record.child.classRoom.name === classFilter)
    return selectedChild
      ? res.filter(record => record.child && record.child.id === selectedChild.id)
      : res
  }

  addAttendance = (childId, params) => {
    return this.props.addAttendance(childId, params).then(_ => this._refreshData())
  }

  updateAttendance = (id, params) => {
    return this.props.updateAttendance(id, params).then(_ => this._refreshData())
  }

  deleteAttendance = id => {
    return this.props.deleteAttendance(id).then(_ => this._refreshData())
  }

  addPlay = (childId, params) => {
    return this.props.addPlay(childId, params).then(_ => this._refreshData())
  }

  updatePlay = (id, params) => {
    return this.props.updatePlay(id, params).then(_ => this._refreshData())
  }

  deletePlay = id => {
    return this.props.deletePlay(id).then(_ => this._refreshData())
  }

  _refreshData = () => {
    const { nursery, selectedDate } = this.props
    const { pagination } = this.state
    const targetDate = selectedDate ? new Date(selectedDate.format('YYYY-MM-DD')) : new Date()
    this.props.fetchAllAttendances(nursery.id, targetDate, pagination.current)
  }

  componentDidMount() {
    let page = queryString.parse(this.props.location.search)['page'] || 1
    this.setState({
      pagination: {
        defaultPageSize: 20,
        current: Number(page),
      },
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { didInitPagination, pagination } = this.state
    const { attendanceList } = this.props
    if (!didInitPagination && attendanceList[0]) {
      const _pag = { ...pagination }
      _pag.total = attendanceList[0].totalNum
      this.setState({
        didInitPagination: true,
        pagination: _pag,
      })
    }
  }

  render() {
    const { isLoading } = this.props
    const dataSource = this.recordDataList()
    const components = {
      body: {
        cell: ChildAttendanceTableCell,
      },
    }
    const columns = _columns.map(col => {
      return {
        ...col,
        onCell: record => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          createAttendance: this.addAttendance,
          updateAttendance: this.updateAttendance,
          deleteAttendance: this.deleteAttendance,
          createPlay: this.addPlay,
          updatePlay: this.updatePlay,
          deletePlay: this.deletePlay,
        }),
      }
    })
    return (
      <div>
        <ShareContext.Provider value={this.onChildTap}>
          <Table
            loading={isLoading}
            components={components}
            dataSource={dataSource}
            onChange={this.onChange}
            columns={columns}
            pagination={this.state.pagination}
          />
        </ShareContext.Provider>
      </div>
    )
  }
}

const mapStateToProps = ({ app, account, childAttendance, nursery }) => ({
  isLoading: app.isLoading,
  nursery: nursery.mine,
  account,
  attendanceList: childAttendance.listForNursery,
})

const mapDispatchToProps = dispatch => ({
  push: path => dispatch(push(path)),
  setChild: child => dispatch(setChild(child)),
  fetchAllAttendances: (nurseryId, date, page = 1) =>
    dispatch(fetchChildAttendanceListForNursery(nurseryId, date, page)),
  addAttendance: (childId, params) => dispatch(addChildAttendanceForChild(childId, params)),
  updateAttendance: (attendId, params) => dispatch(updateChildAttendanceForChild(attendId, params)),
  deleteAttendance: attendanceId => dispatch(deleteChildAttendanceForChild(attendanceId)),
  addPlay: (attendId, params) => dispatch(addPlayForChildAttendance(attendId, params)),
  updatePlay: (playId, params) => dispatch(updatePlayForChildAttendance(playId, params)),
  deletePlay: id => dispatch(deletePlayForChildAttendance(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChildAttendanceTable))

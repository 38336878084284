import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setPageTitle } from '../actions/apps'
import style from '../styles/NotYetPage.module.scss'

class NotYetPage extends Component {
  componentDidMount() {
    this.props.setPageTitle('Not Yet Published Page')
  }

  render() {
    return (
      <div>
        <section className={style.contentSection}>
          <div className={style.sectionInnerWrap}>
            <h1>このページはただいま開発中です</h1>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = ({ app }) => ({
  isLoading: app.isLoading,
})

const mapDispatchToProps = dispatch => ({
  setPageTitle: pageTitle => dispatch(setPageTitle(pageTitle)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotYetPage))

import { transformSnakeToCamel } from '../../utils/snakeCamelConverter'

export default class ChildDiary {
  constructor({ id, date, nursing, education }) {
    this.id = id
    this.date = new Date(date)
    this.nursing = nursing
    this.education = education
  }

  static newFromApiResponse = data => {
    return new ChildDiary(transformSnakeToCamel(data))
  }
}

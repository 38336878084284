import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Avatar, Button, Col, Modal, Row } from 'antd'
import PutChildDiaryForm from '../../../containers/Forms/PutChildDiaryForm'
import PutChildContactBookForm from '../../../containers/Forms/PutChildContactBookForm'
import {
  createChildContactBook,
  createChildDiary,
  updateChildContactBook,
  updateChildDiary,
} from '../../../actions/childCorrespondence'
import style from './index.module.scss'

class ChildGridItem extends React.Component {
  state = {
    diaryVisible: false,
    contactVisible: false,
  }

  toggleDiaryModal = () => this.setState({ diaryVisible: !this.state.diaryVisible })
  toggleContactModal = () => this.setState({ contactVisible: !this.state.contactVisible })

  onSubmitDiary = params => {
    const { correspondenceData, selectedDate } = this.props
    const { child, childDiary } = correspondenceData
    if (childDiary.id) {
      this.props.updateDiary(childDiary.id, params)
    } else {
      this.props.createDiary(child.id, selectedDate, params)
    }
    this.toggleDiaryModal()
  }

  onSubmitContact = params => {
    const { correspondenceData, selectedDate } = this.props
    const { child, childContactBook } = correspondenceData
    if (childContactBook.id) {
      this.props.updateContact(childContactBook.id, params)
    } else {
      this.props.createContact(child.id, selectedDate, params)
    }
    this.toggleContactModal()
  }

  render() {
    const { contactVisible, diaryVisible } = this.state
    const { correspondenceData } = this.props
    const { child, childDiary, childContactBook, parentReport } = correspondenceData
    return (
      <Col className={style.item}>
        <Row gutter={16}>
          <Col span={8}>
            <Avatar size={48} src={child.avatarUrl} icon="user" />
          </Col>
          <Col span={16}>
            <h3>{child.fullName}</h3>
            <div>{child.classRoom && child.classRoom.name}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ paddingTop: 8 }}>
              <h4>保護者からの連絡</h4>
              <p>{parentReport.message ? parentReport.message : 'なし'}</p>
            </div>
          </Col>
        </Row>

        <div style={{ textAlign: 'center' }}>
          <span style={{ marginRight: 8 }}>
            <Button onClick={this.toggleDiaryModal}>日誌</Button>
          </span>
          <span>
            <Button onClick={this.toggleContactModal}>連絡帳</Button>
          </span>
        </div>

        <Modal
          title={`${child.fullName}さんの日誌`}
          footer={null}
          visible={diaryVisible}
          onCancel={this.toggleDiaryModal}
        >
          <PutChildDiaryForm data={childDiary} onSubmit={this.onSubmitDiary} />
        </Modal>

        <Modal
          title={`${child.fullName}さんの連絡帳`}
          footer={null}
          visible={contactVisible}
          onCancel={this.toggleContactModal}
        >
          <PutChildContactBookForm data={childContactBook} onSubmit={this.onSubmitContact} />
        </Modal>
      </Col>
    )
  }
}

const mapStateToProps = ({ app }) => ({
  selectedDate: app.selectedDate,
})

const mapDispatchToProps = dispatch => ({
  createDiary: (childId, date, params) => dispatch(createChildDiary(childId, date, params)),
  updateDiary: (id, params) => dispatch(updateChildDiary(id, params)),
  createContact: (childId, date, params) => dispatch(createChildContactBook(childId, date, params)),
  updateContact: (id, params) => dispatch(updateChildContactBook(id, params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChildGridItem))

import StaffAttendanceStatus from '../models/staffAttendanceStatus'
import { SIGN_OUT } from '../constants/actionTypes'

// TODO: 勤怠管理を導入したときにドメイン層やAPI cliを実装する
const DEFAULT_STATE = {
  status: new StaffAttendanceStatus({}),
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SIGN_OUT:
      return DEFAULT_STATE
    default:
      return state
  }
}

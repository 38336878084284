import {
  deleteAteHistory,
  deleteChildMeal,
  getChildMealListForNursery,
  postAteHistory,
  postChildMeal,
} from '../services/v1ApiRequest'
import {
  SET_CHILD_DINNER_LIST,
  SET_CHILD_LUNCH_LIST,
  SET_CHILD_SNACK_LIST,
  START_LOADING,
  STOP_LOADING,
} from '../constants/actionTypes'
import { setServerError } from './apps'
import ChildMeal from '../models/childMeal'
import moment from 'moment'
import { notification } from 'antd'

export const fetchChildMealList = (nurseryId, date, type, classRoomId) => async dispatch => {
  dispatch({ type: START_LOADING })
  const dateStr = moment(date).format('YYYY-MM-DD')
  const _classRoomId = classRoomId || ''
  let setter = _ => ({})
  if (type === 'lunch') {
    setter = setChildLunchList
  } else if (type === 'snack') {
    setter = setChildSnackList
  } else if (type === 'dinner') {
    setter = setChildDinnerList
  }
  return getChildMealListForNursery(nurseryId, dateStr, type, _classRoomId)
    .then(res => {
      dispatch(
        setter(
          res.data.child_meals.map(data => {
            return ChildMeal.newFromApiResponse(data)
          })
        )
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const createOrUpdateChildMeal = (childId, params) => async dispatch => {
  dispatch({ type: START_LOADING })
  return postChildMeal(childId, params)
    .then(_ => notification.success({ message: 'SUCCESS!' }))
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const removeChildMeal = mealId => async dispatch => {
  dispatch({ type: START_LOADING })
  return deleteChildMeal(mealId)
    .then(_ => notification.success({ message: 'SUCCESS!' }))
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const createOrUpdateAteHistory = (mealId, params) => async dispatch => {
  dispatch({ type: START_LOADING })
  return postAteHistory(mealId, params)
    .then(_ => notification.success({ message: 'SUCCESS!' }))
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const removeAteHistory = ateId => async dispatch => {
  dispatch({ type: START_LOADING })
  return deleteAteHistory(ateId)
    .then(_ => notification.success({ message: 'SUCCESS!' }))
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

const setChildLunchList = list => ({
  type: SET_CHILD_LUNCH_LIST,
  payload: list,
})

const setChildSnackList = list => ({
  type: SET_CHILD_SNACK_LIST,
  payload: list,
})

const setChildDinnerList = list => ({
  type: SET_CHILD_DINNER_LIST,
  payload: list,
})

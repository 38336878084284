import {
  LOAD_MORE_CHILD_LIST,
  SET_CHILD,
  SET_CHILD_LIST,
  SET_FLAG_FOR_PHYSICAL_HISTORY_SHOULD_LOAD,
  SET_RESULT_CHILD_LIST,
  SET_SCANNED_CHILD,
  SIGN_OUT,
} from '../constants/actionTypes'
import Child from '../models/child'

const DEFAULT_STATE = {
  list: [],
  resultList: [],
  selected: new Child({}),
  scanned: new Child({}),
  physicalGrowthHistoriesList: [],
  shouldLoad: true,
  shouldLoadPGH: false,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_CHILD_LIST:
      return { ...state, list: action.payload, shouldLoad: false }
    case LOAD_MORE_CHILD_LIST:
      return { ...state, list: state.list.concat(action.payload), shouldLoad: false }
    case SET_CHILD:
      let findSomeCount = 0
      const newList = state.list.map(child => {
        if (child.id === action.payload.id) {
          findSomeCount = findSomeCount + 1
          return action.payload
        }
        return child
      })
      if (findSomeCount === 0) {
        newList.push(action.payload)
      }
      return { ...state, selected: action.payload, list: newList, shouldLoadPGH: true }
    case SET_SCANNED_CHILD:
      return { ...state, scanned: action.payload }
    case SET_RESULT_CHILD_LIST:
      return { ...state, resultList: action.payload }
    case SET_FLAG_FOR_PHYSICAL_HISTORY_SHOULD_LOAD:
      return { ...state, shouldLoadPGH: true }
    case SIGN_OUT:
      return DEFAULT_STATE
    default:
      return state
  }
}

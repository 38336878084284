import React from 'react'
import { Avatar, Col, Row } from 'antd'

export default ({ child, showClass }) => (
  <div style={{ paddingRight: 16, borderRight: '1px dotted #ddd' }}>
    <Row type="flex" gutter={16}>
      <Col>
        <Avatar src={child.avatarUrl} icon="user" size={48} />
      </Col>

      <Col>
        <div>{child.fullName}</div>
        {showClass ? <h3>{child.classRoom.name}</h3> : null}
      </Col>
    </Row>
  </div>
)

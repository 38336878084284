import React from 'react'
import { connect } from 'react-redux'
import { Select } from 'antd'
import { addChildAttendanceForChild, updateChildAttendanceForChild } from '../../../actions/childAttendance'
import moment from 'moment'

const { Option } = Select

class SelectAttendanceTypeCell extends React.Component {
  getStatus = () => {
    const { record } = this.props
    if (record.attendanceExist) {
      return 'editable'
    } else {
      return 'addable'
    }
  }

  onChange = value => {
    const { record } = this.props

    if (this.getStatus() === 'editable') {
      this.props.updateAttendance(record.attendanceId, {
        child_attendance: {
          type: value,
        },
      })
    } else if (this.getStatus() === 'addable') {
      this.props.addAttendance(record.child.id, {
        child_attendance: {
          type: value,
          date: record.selectedDate
            ? record.selectedDate.format('YYYY-MM-DD')
            : moment(new Date()).format('YYYY-MM-DD'),
        },
      })
    }
  }

  render() {
    const { cellData } = this.props
    return (
      <div>
        <Select
          style={{ minWidth: 72 }}
          onChange={this.onChange}
          value={cellData}
          defaultValue={cellData}
          placeholder="  -  "
        >
          <Option value="attend">出席</Option>
          <Option value="late">遅刻</Option>
          <Option value="leave_early">出席</Option>
          <Option value="official_absent">病欠</Option>
          <Option value="absent">都合欠</Option>
        </Select>
      </div>
    )
  }
}

const mapStateToProps = ({ app, child, nursery, childAttendance }) => ({
  isLoading: app.isLoading,
  nursery: nursery.mine,
  shouldChildrenLoad: child.shouldLoad,
  shouldAttendanceLoad: childAttendance.shouldLoad,
})

const mapDispatchToProps = dispatch => ({
  addAttendance: (childId, params) => dispatch(addChildAttendanceForChild(childId, params)),
  updateAttendance: (attendId, params) => dispatch(updateChildAttendanceForChild(attendId, params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectAttendanceTypeCell)

import React from 'react'
import { Form, Input, Modal, Switch } from 'antd'
import AgeTargetsInput from '../../Inputs/AgeTargetsInput'
import style from './index.module.scss'

export default Form.create({ name: 'profile' })(
  class extends React.Component {
    onSubmit = e => {
      e.preventDefault()
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.onOk({
            class_room: values,
          })
        }
      })
    }

    render() {
      const { getFieldDecorator } = this.props.form
      const { visible, okText, onCancel } = this.props
      return (
        <Modal
          title="クラスを追加"
          visible={visible}
          okText={okText}
          onOk={this.onSubmit}
          onCancel={onCancel}
          destroyOnClose={true}
        >
          <div className={style.scrollZone}>
            <section className={style.contentSection}>
              <div className="sectionInnerWrap">
                <Form>
                  <Form.Item label="クラスの名前">
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: '入力してください' }],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="状態">
                    {getFieldDecorator('is_enable', {
                      initialValue: false,
                      valuePropName: 'checked',
                      rules: [{ type: 'boolean' }],
                    })(<Switch />)}
                  </Form.Item>
                  <Form.Item label="対象学年（複数選択可）">
                    {getFieldDecorator('targets', {
                      rules: [{ type: 'array', required: true, message: '選択してください' }],
                    })(<AgeTargetsInput />)}
                  </Form.Item>
                </Form>
              </div>
            </section>
          </div>
        </Modal>
      )
    }
  }
)

import {
  getPhysicalGrowthHistoryList,
  patchPhysicalGrowthHistory,
  postPhysicalGrowthHistory,
} from '../services/v1ApiRequest'
import { SET_FLAG_FOR_PHYSICAL_HISTORY_SHOULD_LOAD, SET_PHYSICAL_HISTORY_LIST } from '../constants/actionTypes'
import { setServerError } from './apps'
import PhysicalGrowthHistory from '../models/physicalGrowthHistory'
import { notification } from 'antd'

export const fetchAllPhysicalHistoryListForChild = childId => async dispatch => {
  return getPhysicalGrowthHistoryList(childId)
    .then(res => {
      dispatch(
        setPhysicalHistoryList(
          res.data.physical_growth_histories.map(data => {
            return PhysicalGrowthHistory.newFromApiResponse(data)
          })
        )
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {})
}

export const createPhysicalHistory = (childId, params) => async dispatch => {
  return postPhysicalGrowthHistory(childId, params)
    .then(res => {
      dispatch(setReloadFlag())
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {})
}

export const updatePhysicalHistory = (id, params) => async dispatch => {
  return patchPhysicalGrowthHistory(id, params)
    .then(res => {
      notification.success({ message: 'SUCCESS' })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {})
}

const setPhysicalHistoryList = list => ({
  type: SET_PHYSICAL_HISTORY_LIST,
  payload: list,
})

const setReloadFlag = () => ({
  type: SET_FLAG_FOR_PHYSICAL_HISTORY_SHOULD_LOAD,
})

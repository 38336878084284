import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Col, Modal, Row, Select, Table } from 'antd'
import { withRouter } from 'react-router-dom'
import { fetchClassRoomList } from '../../actions/classRoom'
import { fetchMyNursery } from '../../actions/nursery'
import { fetchNurseryCorrespondences } from '../../actions/nurseryCorrespondence'
import { fetchChildAttendanceStatusForNursery } from '../../actions/childAttendance'
import { fetchParentReports } from '../../actions/parentReport'
import ChildGridItem from '../../components/GridItems/ChildGridItem'
import { addKeyForDataSource } from '../../utils/antHelper'
import { createOrUpdateNurseryDiary, fetchNurseryDiary } from '../../actions/nurseryDiary'
import { createOrUpdateClassRoomDiary, fetchClassRoomDiary } from '../../actions/classRoomDiary'
import PutClassRoomDiaryForm from '../Forms/PutClassRoomDiaryForm'
import PutNurseryDiaryForm from '../Forms/PutNurseryDiaryForm'

import style from '../../styles/DailyBoardPage.module.scss'

const { Option } = Select
const attendanceStatusColumns = [
  { title: '総園児数', dataIndex: 'all', key: 'all' },
  { title: '登園予定', dataIndex: 'willCome', key: 'willCome' },
  { title: '出席', dataIndex: 'attend', key: 'attend' },
  { title: '欠席', dataIndex: 'absent', key: 'absent' },
  { title: '登園中', dataIndex: 'exist', key: 'exist' },
]

class DiaryPage extends Component {
  state = {
    selectedClassName: '全て',
    visibleForDiaryModal: false,
  }

  fetchData = () => {
    const { nursery, classRoomList, selectedDate } = this.props
    const { selectedClassName } = this.state
    let classRoom = { id: null }
    if (selectedClassName !== '全て') {
      classRoom = classRoomList.filter(klass => klass.name === selectedClassName)[0]
      this.props.fetchClassRoomDiary(classRoom.id, selectedDate)
    }
    if (nursery) {
      this.props.fetchParentReports(nursery.id, selectedDate)
      this.props.fetchCorrespondence(nursery.id, selectedDate, classRoom.id)
      this.props.fetchAllClassRooms(nursery.id)
      this.props.fetchAttendanceStatus(nursery.id, selectedDate)
      this.props.fetchNurseryDiary(nursery.id, selectedDate)
    } else {
      this.props.fetchNursery().then(action => {
        const nursery = action.payload
        this.props.fetchParentReports(nursery.id, selectedDate)
        this.props.fetchCorrespondence(nursery.id, selectedDate, classRoom.id)
        this.props.fetchAllClassRooms(nursery.id)
        this.props.fetchAttendanceStatus(nursery.id, selectedDate)
        this.props.fetchNurseryDiary(nursery.id, selectedDate)
      })
    }
  }

  onClassChange = value => {
    this.setState({ selectedClassName: value })
  }

  onNewDiary = e => this.setState({ visibleForDiaryModal: true })

  closeDiaryModal = e => this.setState({ visibleForDiaryModal: false })

  putNurseryDiary = params => {
    const { selectedDate, nursery } = this.props
    this.props.putNurseryDiary(nursery.id, selectedDate, params).then(_ => this.fetchData())
    this.closeDiaryModal()
  }

  putClassRoomDiary = params => {
    const { selectedClassName } = this.state
    const { selectedDate, classRoomList } = this.props
    let classRoom = { id: null }
    if (selectedClassName !== '全て') {
      classRoom = classRoomList.filter(klass => klass.name === selectedClassName)[0]
    }
    this.props.putClassRoomDiary(classRoom.id, selectedDate, params).then(_ => this.fetchData())
    this.closeDiaryModal()
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.selectedClassName !== this.state.selectedClassName ||
      prevProps.selectedDate !== this.props.selectedDate
    ) {
      this.fetchData()
    }
  }

  render() {
    const {
      classRoomList,
      attendanceStatus,
      correspondenceList,
      classRoomDiary,
      nurseryDiary,
    } = this.props
    const { selectedClassName, visibleForDiaryModal } = this.state
    return (
      <div className={style.page}>
        <section className={style.contentSection}>
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              <div>
                <Select
                  style={{ minWidth: 160, marginBottom: 16 }}
                  onChange={this.onClassChange}
                  value={selectedClassName}
                  defaultValue={selectedClassName}
                  placeholder="  -  "
                >
                  {[{ name: '全て' }, ...classRoomList].map((classRoom, idx) => (
                    <Option key={idx} value={classRoom.name}>
                      {classRoom.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div>
                <div>
                  クラス日誌を作成するには
                  <br />
                  クラスを選択してください
                </div>
                <Button type="primary" onClick={this.onNewDiary}>
                  {selectedClassName === '全て' ? '園日誌を作成' : 'クラス日誌を作成'}
                </Button>
              </div>
            </Col>
            <Col>
              <Table
                columns={attendanceStatusColumns}
                dataSource={addKeyForDataSource([attendanceStatus])}
                pagination={false}
              />
            </Col>
          </Row>
        </section>

        <section className={style.contentSection}>
          <h2>園児一覧</h2>
          <div className={style.flexGridBox}>
            {correspondenceList.map((data, idx) => (
              <ChildGridItem correspondenceData={data} key={idx} />
            ))}
          </div>
        </section>

        <Modal
          visible={visibleForDiaryModal}
          onCancel={this.closeDiaryModal}
          style={{ minWidth: 750 }}
          footer={null}
          destroyOnClose={true}
        >
          {selectedClassName === '全て' ? (
            <PutNurseryDiaryForm data={nurseryDiary} onSubmit={this.putNurseryDiary} />
          ) : (
            <PutClassRoomDiaryForm data={classRoomDiary} onSubmit={this.putClassRoomDiary} />
          )}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({
  app,
  nursery,
  classRoom,
  childAttendance,
  nurseryCorrespondence,
  diary,
}) => ({
  selectedDate: app.selectedDate,
  nursery: nursery.mine,
  classRoomList: classRoom.list,
  attendanceStatus: childAttendance.status,
  correspondenceList: nurseryCorrespondence.list,
  classRoomDiary: diary.forClassRoom,
  nurseryDiary: diary.forNursery,
})

const mapDispatchToProps = dispatch => ({
  fetchNursery: () => dispatch(fetchMyNursery()),
  fetchAllClassRooms: nurseryId => dispatch(fetchClassRoomList(nurseryId)),
  fetchCorrespondence: (nurseryId, date, classRoomId) =>
    dispatch(fetchNurseryCorrespondences(nurseryId, date, classRoomId)),
  fetchAttendanceStatus: (nurseryId, date) =>
    dispatch(fetchChildAttendanceStatusForNursery(nurseryId, date)),
  fetchParentReports: (nurseryId, date) => dispatch(fetchParentReports(nurseryId, date)),
  fetchNurseryDiary: (nurseryId, date) => dispatch(fetchNurseryDiary(nurseryId, date)),
  fetchClassRoomDiary: (classRoomId, date) => dispatch(fetchClassRoomDiary(classRoomId, date)),
  putNurseryDiary: (nurseryId, date, params) =>
    dispatch(createOrUpdateNurseryDiary(nurseryId, date, params)),
  putClassRoomDiary: (classRoomId, date, params) =>
    dispatch(createOrUpdateClassRoomDiary(classRoomId, date, params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DiaryPage))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Card, Icon, List } from 'antd'
import { setPageTitle } from '../actions/apps'
import style from '../styles/Dashboardpage.module.scss'
import routes from '../routes'

const menuItemList = [
  { name: 'デイリーボード', iconType: 'schedule', link: routes.dailyBoard() },
  { name: '登降園管理', iconType: 'reddit', link: routes.childAttendance() },
  { name: '出退勤管理', iconType: 'smile', link: routes.notYet() },
  { name: '園児台帳', iconType: 'contacts', link: routes.childrenBook() },
  { name: '指導案', iconType: 'file-ppt', link: routes.notYet() },
  { name: 'イベントカレンダー', iconType: 'carry-out', link: routes.notYet() },
  { name: 'Wiki', iconType: 'read', link: routes.notYet() },
  { name: '保護者チャット', iconType: 'phone', link: routes.notYet() },
  { name: '社内チャット', iconType: 'team', link: routes.notYet() },
  { name: '園の基本情報', iconType: 'bank', link: routes.settings.nursery() },
  { name: 'クラス管理', iconType: 'table', link: routes.settings.classRoom() },
]

class DashboardPage extends Component {
  componentDidMount() {
    this.props.setPageTitle('ダッシュボード')
  }

  render() {
    return (
      <div>
        <section className={style.contentSection}>
          <div className={style.sectionInnerWrap}>
            <List
              grid={{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 5 }}
              dataSource={menuItemList}
              renderItem={item => (
                <List.Item>
                  <Link to={item.link}>
                    <Card title={item.name}>
                      <div className={style.menuIconWrap}>
                        <Icon type={item.iconType} />
                      </div>
                    </Card>
                  </Link>
                </List.Item>
              )}
            />
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = ({ account }) => ({
  profile: account,
})

const mapDispatchToProps = dispatch => ({
  setPageTitle: pageTitle => dispatch(setPageTitle(pageTitle)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardPage))

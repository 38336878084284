import { CLOSE_MENU, OPEN_MENU, SERVER_ERROR, SET_DATE, SET_PAGE_TITLE, TOGGLE_MENU } from '../constants/actionTypes'

export const setPageTitle = pageTitle => ({
  type: SET_PAGE_TITLE,
  payload: pageTitle,
})

export const toggleMenu = () => ({
  type: TOGGLE_MENU,
})

export const openMenu = () => ({
  type: OPEN_MENU,
})

export const closeMenu = () => ({
  type: CLOSE_MENU,
})

export const setServerError = () => ({
  type: SERVER_ERROR,
})

export const setSelectedDate = date => ({
  type: SET_DATE,
  payload: date,
})

export const resetDate = () => ({
  type: SET_DATE,
  payload: new Date(),
})

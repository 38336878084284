import { transformSnakeToCamel } from '../../utils/snakeCamelConverter'
import { Parent, Staff } from '../account'
import Child from '../child'

export class ChildNap {
  constructor({ id, startsAt, endsAt, direction, fixProne, wakedUp, staff, parent }) {
    this.id = id
    this.startsAt = startsAt
    this.endsAt = endsAt
    this.direction = direction
    this.fixProne = fixProne
    this.wakedUp = wakedUp
    this.staff = staff ? new Staff(staff) : null
    this.parent = parent ? new Parent(parent) : null
  }

  static newFromApiResponse = data => {
    return new ChildNap(transformSnakeToCamel(data))
  }
}

export default class ChildNapHistory {
  constructor({ child, naps }) {
    this.child = new Child(child || {})
    this.naps = naps.map(data => new ChildNap(data))
  }

  static newFromApiResponse = data => {
    return new ChildNapHistory(transformSnakeToCamel(data))
  }
}

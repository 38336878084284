import {
  deleteDishMenu,
  getDishMenuListForNursery,
  patchDishMenu,
  postDishMenuForNursery,
} from '../services/v1ApiRequest'
import { SET_DISH_MENU_LIST, START_LOADING, STOP_LOADING } from '../constants/actionTypes'
import DishMenu from '../models/dishMenu'
import { setServerError } from './apps'
import { notification } from 'antd'

export const fetchDishMenuListForNursery = nurseryId => dispatch => {
  dispatch({ type: START_LOADING })
  return getDishMenuListForNursery(nurseryId)
    .then(res => {
      dispatch(
        setList(
          res.data.dish_menus.map(data => {
            return DishMenu.newFromApiResponse(data)
          })
        )
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const createDishMenuForNursery = (nurseryId, params) => dispatch => {
  return postDishMenuForNursery(nurseryId, params)
    .then(_ => {
      notification.success({ message: 'SUCCESS!' })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const updateDishMenu = (id, params) => dispatch => {
  return patchDishMenu(id, params)
    .then(_ => {
      notification.success({ message: 'SUCCESS!' })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const removeDishMenu = id => dispatch => {
  return deleteDishMenu(id)
    .then(_ => {
      notification.success({ message: 'SUCCESS!' })
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

const setList = list => ({
  type: SET_DISH_MENU_LIST,
  payload: list,
})

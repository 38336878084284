import { transformSnakeToCamel } from '../utils/snakeCamelConverter'

export default class Department {
  constructor({ name }) {
    this.name = name
  }

  static newFromApiResponse = data => {
    return new Department(transformSnakeToCamel(data))
  }
}

import Department from './department'
import { transformSnakeToCamel } from '../utils/snakeCamelConverter'
import Organization from './organization'

export default class Employment {
  constructor({
    employeeCustomId,
    contractType,
    roleName,
    beginsContractAt,
    endsContractAt,
    organization,
    department,
  }) {
    this.contractType = contractType // Enum[ :regular, :... ]
    this.roleName = roleName
    this.beginsContractAt = beginsContractAt
    this.endsContractAt = endsContractAt
    this.organization = organization ? new Organization(organization) : new Organization({})
    this.department = department ? new Department(department) : null
  }

  static newFromApiResponse = data => {
    return new Employment(transformSnakeToCamel(data))
  }
}

import React from 'react'
import { DatePicker, Form, Input, Modal, Radio, Select } from 'antd'
import AllergiesInput from '../../../components/Inputs/AllergiesInput'
import style from './index.module.scss'
import { jpStates } from '../../../constants/jpStates'

const { Option } = Select

const stateSelector = (
  <Select style={{ width: '30%' }} initialValue="東京">
    {jpStates.map(item => (
      <Option key={item.code} value={item.name}>
        {item.name}
      </Option>
    ))}
  </Select>
)

export default Form.create({ name: 'EditChildProfile' })(
  class extends React.Component {
    onSubmit = e => {
      e.preventDefault()
      this.props.form.validateFields((err, values) => {
        if (!err) {
          values.customer_status = 'enable'
          values.birthday = values.birthday ? values.birthday.format('YYYY-MM-DD') : null
          this.props.onOk(values)
        }
      })
    }

    render() {
      const { getFieldDecorator } = this.props.form
      const { visible, onCancel, classRooms } = this.props

      return (
        <Modal
          title="園児を追加"
          visible={visible}
          onOk={this.onSubmit}
          onCancel={onCancel}
          destroyOnClose={true}
        >
          <div className={style.scrollZone}>
            <section className={style.contentSection}>
              <div className="sectionInnerWrap">
                <h2>入力情報</h2>

                <Form>
                  <Form.Item label="クラスを選択">
                    {getFieldDecorator('class_room_id', {
                      rules: [{ required: true, message: '名字を入力してください' }],
                    })(
                      <Select placeholder="クラスを洗濯してください(任意)">
                        {classRooms.map(klass => (
                          <Option key={klass.id} value={klass.id}>
                            {klass.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item label="姓">
                    {getFieldDecorator('last_name', {
                      rules: [{ required: true, message: '名字を入力してください' }],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="名">
                    {getFieldDecorator('first_name', {
                      rules: [{ required: true, message: '名前を入力してください' }],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="せい">
                    {getFieldDecorator('last_name_kana', {
                      rules: [
                        { required: true, message: '名字(かな)を入力してください' },
                        { pattern: new RegExp(/^[ぁ-んー　]*$/) },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="めい">
                    {getFieldDecorator('first_name_kana', {
                      rules: [
                        { required: true, message: '名前(かな)を入力してください' },
                        { pattern: new RegExp(/^[ぁ-んー　]*$/) },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="性別">
                    {getFieldDecorator('gender', {
                      initialValue: 'male',
                      rules: [{ required: true, message: '性別を入力してください' }],
                    })(
                      <Radio.Group>
                        <Radio value="male">男</Radio>
                        <Radio value="female">女</Radio>
                        <Radio value="other">その他</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                  <Form.Item label="生年月日">
                    {getFieldDecorator('birthday', {
                      rules: [{ required: true, type: 'object' }],
                    })(<DatePicker format="YYYY/MM/DD" />)}
                  </Form.Item>
                  <Form.Item label="郵便番号">
                    {getFieldDecorator('postcode', {})(<Input />)}
                  </Form.Item>
                  <Form.Item label="都道府県">
                    {getFieldDecorator('state', {})(stateSelector)}
                  </Form.Item>
                  <Form.Item label="住所">{getFieldDecorator('address', {})(<Input />)}</Form.Item>
                  <Form.Item label="建物名">
                    {getFieldDecorator('building', {})(<Input />)}
                  </Form.Item>
                  <Form.Item label="電話番号">
                    {getFieldDecorator('phone', {})(<Input />)}
                  </Form.Item>
                  <Form.Item label="アレルギー(複数可)">
                    {getFieldDecorator('allergies', {
                      initialValue: [],
                      rules: [{ type: 'array' }],
                    })(<AllergiesInput />)}
                  </Form.Item>
                </Form>
              </div>
            </section>
          </div>
        </Modal>
      )
    }
  }
)

import { transformSnakeToCamel } from '../utils/snakeCamelConverter'
import DishMenu from './dishMenu'

export default class AteHistory {
  constructor({ id, dishMenu, alternativeDishMenu, isAlternative, status }) {
    this.id = id
    this.dishMenu = new DishMenu(dishMenu)
    this.alternativeDishMenu = new DishMenu(alternativeDishMenu)
    this.isAlternative = isAlternative
    this.status = status
  }

  static newFromApiResponse = data => {
    return new AteHistory(transformSnakeToCamel(data))
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Radio, Row, Select } from 'antd'
import {
  createOrUpdateAteHistory,
  createOrUpdateChildMeal,
  fetchChildMealList,
  removeAteHistory,
  removeChildMeal,
} from '../../actions/childMeal'
import { fetchClassRoomList } from '../../actions/classRoom'
import ChildMealTable from '../../components/Tables/ChildMealTable'
import style from '../../styles/DailyBoardPage.module.scss'
import { fetchMyNursery } from '../../actions/nursery'

const { Option } = Select

class MealPage extends Component {
  state = {
    selectedClassName: '全て',
    selectedMealType: 'lunch',
  }

  fetchData = () => {
    const { nursery, classRoomList, selectedDate } = this.props
    const { selectedClassName, selectedMealType } = this.state
    let classRoom = { id: null }
    if (selectedClassName !== '全て') {
      classRoom = classRoomList.filter(klass => klass.name === selectedClassName)[0]
    }
    if (nursery) {
      this.props.fetchAllClassRooms(nursery.id)
      this.props.fetchMealList(nursery.id, selectedDate, selectedMealType, classRoom.id)
    } else {
      this.props.fetchNursery().then(action => {
        const nursery = action.payload
        this.props.fetchAllClassRooms(nursery.id)
        this.props.fetchMealList(nursery.id, selectedDate, selectedMealType, classRoom.id)
      })
    }
  }

  putMeal = (childId, values) => {
    const { nursery, classRoomList, selectedDate } = this.props
    const { selectedClassName } = this.state
    let classRoom = { id: null }
    if (selectedClassName !== '全て') {
      classRoom = classRoomList.filter(klass => klass.name === selectedClassName)[0]
    }
    this.props
      .putMeal(childId, {
        child_meal: values,
      })
      .then(_ => this.props.fetchMealList(nursery.id, selectedDate, classRoom.id))
  }

  removeMeal = id => {
    const { nursery, classRoomList, selectedDate } = this.props
    const { selectedClassName } = this.state
    let classRoom = { id: null }
    if (selectedClassName !== '全て') {
      classRoom = classRoomList.filter(klass => klass.name === selectedClassName)[0]
    }
    this.props
      .removeMeal(id)
      .then(_ => this.props.fetchMealList(nursery.id, selectedDate, classRoom.id))
  }

  putAteHistory = (mealId, values) => {
    const { nursery, classRoomList, selectedDate } = this.props
    const { selectedClassName } = this.state
    let classRoom = { id: null }
    if (selectedClassName !== '全て') {
      classRoom = classRoomList.filter(klass => klass.name === selectedClassName)[0]
    }
    this.props
      .putAteHistory(mealId, { ate_history: values })
      .then(_ => this.props.fetchMealList(nursery.id, selectedDate, classRoom.id))
  }

  removeAteHistory = id => {
    const { nursery, classRoomList, selectedDate } = this.props
    const { selectedClassName } = this.state
    let classRoom = { id: null }
    if (selectedClassName !== '全て') {
      classRoom = classRoomList.filter(klass => klass.name === selectedClassName)[0]
    }
    this.props
      .removeAteHistory(id)
      .then(_ => this.props.fetchMealList(nursery.id, selectedDate, classRoom.id))
  }

  menuLabel = () => {
    const { selectedMealType } = this.state
    if (selectedMealType === 'lunch') {
      return '昼食'
    } else if (selectedMealType === 'snack') {
      return 'おかし'
    }
    // else if (selectedMealType === 'dinner') { return '夕食' }
  }

  onClassChange = value => {
    this.setState({ selectedClassName: value })
  }

  onMenuChange = e => this.setState({ selectedMealType: e.target.value })

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.selectedClassName !== this.state.selectedClassName ||
      prevProps.selectedDate !== this.props.selectedDate
    ) {
      this.fetchData()
    }
  }

  render() {
    const { lunchList, snackList, classRoomList } = this.props
    const { selectedClassName, selectedMealType } = this.state
    let dataSource = []
    if (selectedMealType === 'lunch') {
      dataSource = lunchList
    } else if (selectedMealType === 'snack') {
      dataSource = snackList
    }

    return (
      <div className={style.page}>
        <section className={style.contentSection}>
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              <Select
                style={{ minWidth: 160 }}
                onChange={this.onClassChange}
                value={selectedClassName}
                defaultValue={selectedClassName}
                placeholder="  -  "
              >
                {[{ name: '全て' }, ...classRoomList].map((classRoom, idx) => (
                  <Option key={idx} value={classRoom.name}>
                    {classRoom.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Radio.Group
                value={selectedMealType}
                buttonStyle="solid"
                onChange={this.onMenuChange}
              >
                <Radio.Button value="lunch">昼食</Radio.Button>
                <Radio.Button value="snack">お菓子</Radio.Button>
                {/*<Radio.Button value="dinner">夕食</Radio.Button>*/}
              </Radio.Group>
            </Col>
          </Row>
        </section>

        <section className={style.contentSection}>
          <h2>{this.menuLabel()}</h2>

          <ChildMealTable
            dataSource={dataSource}
            putMeal={this.putMeal}
            removeMeal={this.removeMeal}
            putAteHistory={this.putAteHistory}
            removeAteHistory={this.removeAteHistory}
          />
        </section>
      </div>
    )
  }
}

const mapStateToProps = ({ app, nursery, childMeal, classRoom }) => ({
  selectedDate: app.selectedDate,
  nursery: nursery.mine,
  lunchList: childMeal.lunchList,
  snackList: childMeal.snackList,
  // dinnerList: childMeal.dinnerList,
  classRoomList: classRoom.list,
})

const mapDispatchToProps = dispatch => ({
  fetchNursery: () => dispatch(fetchMyNursery()),
  fetchMealList: (nurseryId, date, classRoomId) =>
    dispatch(fetchChildMealList(nurseryId, date, classRoomId)),
  putMeal: (childId, params) => dispatch(createOrUpdateChildMeal(childId, params)),
  removeMeal: id => dispatch(removeChildMeal(id)),
  putAteHistory: (mealId, params) => dispatch(createOrUpdateAteHistory(mealId, params)),
  removeAteHistory: id => dispatch(removeAteHistory(id)),
  fetchAllClassRooms: nurseryId => dispatch(fetchClassRoomList(nurseryId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MealPage)

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Col, notification, Row } from 'antd'
import ClassRoomTable from '../../components/Tables/ClassRoomTable'
import CreateClassRoomModal from '../../components/Modals/CreateClassRoomModal'
import style from '../../styles/SettingsPage.module.scss'
import { createClassRoom, fetchClassRoomList } from '../../actions/classRoom'

class ClassSetting extends React.Component {
  state = {
    visibleModal: false,
    shouldReload: false,
  }

  toggleModal = e => {
    e && e.preventDefault()
    this.setState({ visibleModal: !this.state.visibleModal })
  }

  handleClassSubmit = values => {
    const duplicates = this.props.classRoomList.filter(
      data => data.name === values['class_room'].name
    )
    if (duplicates.length === 0) {
      this.props.createClass(this.props.currentNursery.id, values).then(() => {
        this.setState({ shouldReload: true })
      })
    } else {
      notification.warning({ message: '同じクラス名は一度しか使えません' })
    }
    this.toggleModal()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.shouldReload) {
      this.props.fetchAllClassRoomSetting(this.props.currentNursery.id)
      this.setState({ shouldReload: false })
    }
  }

  render() {
    const { visibleModal } = this.state
    return (
      <div>
        <div className={style.settingContent}>
          <Row type="flex" justify="space-between">
            <Col>
              <h2>クラス一覧</h2>
            </Col>
            <Col>
              <Button type="primary" shape="circle" icon="plus" onClick={this.toggleModal} />
            </Col>
          </Row>
          <ClassRoomTable />
          <CreateClassRoomModal
            visible={visibleModal}
            okText="作成"
            onOk={this.handleClassSubmit}
            onCancel={this.toggleModal}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ app, nursery, classRoom }) => ({
  isLoading: app.isLoading,
  currentNursery: nursery.mine,
  classRoomList: classRoom.list,
})

const mapDispatchToProps = dispatch => ({
  fetchAllClassRoomSetting: nurseryId => dispatch(fetchClassRoomList(nurseryId)),
  createClass: (nurseryId, params) => dispatch(createClassRoom(nurseryId, params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClassSetting))

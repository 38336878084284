import { transformSnakeToCamel } from '../utils/snakeCamelConverter'
import ClassRoom from './classRoom'
import moment from 'moment'

export default class Child {
  constructor({
    id,
    customerStatus,
    firstName,
    firstNameKana,
    lastName,
    lastNameKana,
    avatarUrl,
    allergies,
    birthday,
    gender,
    postcode,
    state,
    address,
    building,
    phone,
    primaryHospital,
    bloodType,
    classRoom,
  }) {
    this.id = id
    this.customerStatus = customerStatus // Enum[ :enable, :frozen, :quit, :graduate ]
    this.firstName = firstName
    this.firstNameKana = firstNameKana
    this.lastName = lastName
    this.lastNameKana = lastNameKana
    this.avatarUrl = avatarUrl
    this.birthday = birthday ? new Date(birthday) : new Date()
    this.gender = gender
    this.postcode = postcode
    this.state = state
    this.address = address
    this.building = building
    this.phone = phone
    this.primaryHospital = primaryHospital
    this.bloodTyp = bloodType
    this.avatarUrl = avatarUrl
    this.classRoom = classRoom ? new ClassRoom(classRoom) : new ClassRoom({})
    this.allergies = allergies ? allergies : []

    // local only
    this.fullName = `${lastName} ${firstName}`
    this.nameForSearch = `${lastNameKana}${firstNameKana}${lastName}${firstName}`
    this.fullAddress = ``
  }

  static newFromApiResponse = data => {
    return new Child(transformSnakeToCamel(data))
  }

  getAge = () => {
    // 学年のようなもので実際の年齢とはとこなる
    const birth = moment(this.birthday)
    const now = moment(new Date())
    const birthYear = Number(birth.format('MMDD')) > 401 ? birth.year() : birth.year() - 1
    const nowYear = Number(now.format('MMDD')) > 401 ? now.year() : now.year() - 1
    return nowYear - birthYear
  }
}

import { getChild, getChildList, getSearchedChild, patchChild, postChild } from '../services/v1ApiRequest'
import {
  LOAD_MORE_CHILD_LIST,
  SET_CHILD,
  SET_CHILD_LIST,
  SET_RESULT_CHILD_LIST,
  SET_SCANNED_CHILD,
  START_LOADING,
  STOP_LOADING,
} from '../constants/actionTypes'
import { setServerError } from './apps'
import Child from '../models/child'

export const fetchChild = id => async dispatch => {
  dispatch({ type: START_LOADING })
  return getChild(id)
    .then(res => {
      dispatch(setChild(Child.newFromApiResponse(res.data)))
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const fetchScannedChild = id => async dispatch => {
  dispatch({ type: START_LOADING })
  return getChild(id)
    .then(res => {
      dispatch(setScannedChild(Child.newFromApiResponse(res.data)))
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const fetchChildList = (nurseryId, page = 1) => async dispatch => {
  dispatch({ type: START_LOADING })
  return getChildList(nurseryId, page)
    .then(res => {
      dispatch(
        setChildList(
          res.data.children.map(children => {
            return Child.newFromApiResponse(children)
          })
        )
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const fetchMoreChildList = (nurseryId, page = 1) => async dispatch => {
  dispatch({ type: START_LOADING })
  return getChildList(nurseryId, page)
    .then(res => {
      dispatch(
        setMoreChildList(
          res.data.children.map(children => {
            return Child.newFromApiResponse(children)
          })
        )
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const createChild = (nurseryId, params) => async dispatch => {
  dispatch({ type: START_LOADING })
  return postChild(nurseryId, params)
    .then(res => {
      dispatch(setChild(Child.newFromApiResponse(res.data)))
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const updateChild = (id, params) => async dispatch => {
  dispatch({ type: START_LOADING })
  return patchChild(id, params)
    .then(res => {
      dispatch(setChild(Child.newFromApiResponse(res.data)))
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const searchChild = (word, limit = 5) => async dispatch => {
  return getSearchedChild(word, limit)
    .then(res => {
      dispatch(
        setSearchedChildList(
          res.data.children.map(child => {
            return Child.newFromApiResponse(child)
          })
        )
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const setChild = child => ({
  type: SET_CHILD,
  payload: child,
})

export const setScannedChild = child => ({
  type: SET_SCANNED_CHILD,
  payload: child,
})

const setChildList = children => ({
  type: SET_CHILD_LIST,
  payload: children,
})

const setMoreChildList = children => ({
  type: LOAD_MORE_CHILD_LIST,
  payload: children,
})

const setSearchedChildList = children => ({
  type: SET_RESULT_CHILD_LIST,
  payload: children,
})

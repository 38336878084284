export const TOGGLE_MENU = 'TOGGLE_MENU'
export const OPEN_MENU = 'OPEN_MENU'
export const CLOSE_MENU = 'CLOSE_MENU'
export const SIGN_OUT = 'SIGN_OUT'
export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'
export const SERVER_ERROR = 'SERVER_ERROR'
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE'
export const SIGN_IN_ACCOUNT = 'SIGN_IN_ACCOUNT'
export const SET_ACCOUNT = 'SET_ACCOUNT'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_OTHER_ACCOUNT = 'SET_OTHER_ACCOUNT'
export const SET_ACCOUNT_ERRORS = 'SET_ACCOUNT_ERRORS'
export const SET_NURSERY = 'SET_NURSERY'
export const SET_MY_NURSERY = 'SET_MY_NURSERY'
export const SET_CLASS_ROOM = 'SET_CLASS_ROOM'
export const SET_CLASS_ROOM_LIST = 'SET_CLASS_ROOM_LIST'
export const SET_FLAG_FOR_CLASS_ROOM_SHOULD_LOAD = 'SET_FLAG_FOR_CLASS_ROOM_SHOULD_LOAD'
export const SET_CHILD = 'SET_CHILD'
export const SET_CHILD_LIST = 'SET_CHILD_LIST'
export const LOAD_MORE_CHILD_LIST = 'LOAD_MORE_CHILD_LIST'
export const SET_RESULT_CHILD_LIST = 'SET_RESULT_CHILD_LIST'
export const SET_NURSERY_CHILDREN_ATTENDANCE_LIST = 'SET_NURSERY_CHILDREN_ATTENDANCE_LIST'
export const SET_CHILD_ATTENDANCE_LIST = 'SET_CHILD_ATTENDANCE_LIST'
export const SET_CHILD_TODAY_ATTENDANCE_LIST = 'SET_CHILD_TODAY_ATTENDANCE_LIST'
export const SET_FLAG_FOR_CHILD_ATTENDANCE_SHOULD_LOAD = 'SET_FLAG_FOR_CHILD_ATTENDANCE_SHOULD_LOAD'
export const SET_PHYSICAL_HISTORY_LIST = 'SET_PHYSICAL_HISTORY_LIST'
export const SET_FLAG_FOR_PHYSICAL_HISTORY_SHOULD_LOAD = 'SET_FLAG_FOR_PHYSICAL_HISTORY_SHOULD_LOAD'
export const SET_SCANNED_CHILD = 'SET_SCANNED_CHILD'
export const SET_DATE = 'SET_DATE'
export const SET_NURSERY_CORRESPONDENCE_LIST = 'SET_NURSERY_CORRESPONDENCE_LIST'
export const SET_CHILD_NAP_LIST = 'SET_CHILD_NAP_LIST'
export const SET_CHILD_BODY_TEMPERATURE_LIST = 'SET_CHILD_BODY_TEMPERATURE_LIST'
export const SET_CHILD_EXCREMENT_LIST = 'SET_CHILD_EXCREMENT_LIST'
export const SET_STAFF_LIST = 'SET_STAFF_LIST'
export const SET_CHILD_LUNCH_LIST = 'SET_CHILD_LUNCH_LIST'
export const SET_CHILD_SNACK_LIST = 'SET_CHILD_SNACK_LIST'
export const SET_CHILD_DINNER_LIST = 'SET_CHILD_DINNER_LIST'
export const SET_CHILD_ATTENDANCE_STATUS = 'SET_CHILD_ATTENDANCE_STATUS'
export const SET_PARENT_REPORT_LIST = 'SET_PARENT_REPORT_LIST'
export const SET_DISH_MENU_LIST = 'SET_DISH_MENU_LIST'
export const SET_CLASS_ROOM_DIARY = 'SET_CLASS_ROOM_DIARY'
export const SET_NURSERY_DIARY = 'SET_NURSERY_DIARY'

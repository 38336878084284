import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Layout, Menu } from 'antd'
import { withRouter } from 'react-router-dom'
import { setPageTitle } from '../actions/apps'
import { fetchMyNursery } from '../actions/nursery'
import { fetchClassRoomList } from '../actions/classRoom'
import NurserySetting from './Settings/NurserySetting'
import ClassSetting from './Settings/ClassSetting'
import MealSetting from './Settings/MealSetting'
import style from '../styles/SettingsPage.module.scss'
import routes from '../routes'

const { Sider, Content } = Layout

const settingItemList = [
  {
    name: '園の基本情報',
    link: routes.settings.nursery(),
    sectionKey: 'nursery',
    component: <NurserySetting />,
  },
  {
    name: 'クラス管理',
    link: routes.settings.classRoom(),
    sectionKey: 'classRoom',
    component: <ClassSetting />,
  },
  {
    name: '食事メニュー管理',
    link: routes.settings,
    sectionKey: 'meal',
    component: <MealSetting />,
  },
]

class SettingsPage extends React.Component {
  state = {
    currentUrl: '',
    selectedKey: settingItemList[0].sectionKey,
    didLoadMyNursery: false,
    shouldLoadClassRooms: false,
  }

  _handleMenu = e => {
    this._setSection(e.key)
  }

  onMenuItemClick = path => e => {
    this.props.push(path)
    this._handleMenu(e)
  }

  _setSection = sectionKey => {
    this.setState({
      selectedKey: sectionKey,
      currentUrl: window.location.href,
    })
  }

  componentDidMount() {
    const { nursery } = this.props
    this.props.setPageTitle('設定')
    this.props.fetchMyNursery().then(() => {
      this.setState({ didLoadMyNursery: true })
    })
    if (nursery && nursery.id) {
      this.props.fetchAllClassRoomSetting(nursery.id)
    } else {
      this.setState({ shouldLoadClassRooms: true })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const pageUrl = window.location.href
    if (pageUrl !== this.state.currentUrl) {
      if (pageUrl.includes(routes.settings.nursery())) {
        this._setSection('nursery')
      } else if (pageUrl.includes(routes.settings.classRoom())) {
        this._setSection('classRoom')
      } else if (pageUrl.includes(routes.settings.meal())) {
        this._setSection('meal')
      } else {
        this._setSection('nursery')
      }
    }

    if (this.state.didLoadMyNursery && this.state.shouldLoadClassRooms) {
      this.props.fetchAllClassRoomSetting(this.props.nursery.id)
      this.setState({ shouldLoadClassRooms: false })
    }
  }

  render() {
    const { selectedKey } = this.state
    return (
      <div className={style.page}>
        <Layout>
          <Sider width={200} style={{ background: '#ffffff' }}>
            <Menu mode="inline" selectedKeys={[selectedKey]} style={{ height: '100%' }}>
              {settingItemList.map(item => (
                <Menu.Item key={item.sectionKey} onClick={this.onMenuItemClick(item.link)}>
                  {item.name}
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
          <Content style={{ padding: '24px', minHeight: '100vh' }}>
            {settingItemList.filter(i => i.sectionKey === selectedKey)[0].component}
          </Content>
        </Layout>
      </div>
    )
  }
}

const mapStateToProps = ({ app, account, nursery }) => ({
  isLoading: app.isLoading,
  account: account,
  nursery: nursery.mine,
})

const mapDispatchToProps = dispatch => ({
  setPageTitle: pageTitle => dispatch(setPageTitle(pageTitle)),
  fetchMyNursery: () => dispatch(fetchMyNursery()),
  fetchAllClassRoomSetting: nurseryId => dispatch(fetchClassRoomList(nurseryId)),
  push: path => dispatch(push(path)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingsPage))

import { transformSnakeToCamel } from '../../utils/snakeCamelConverter'
import Child from '../child'

export class ChildExcrement {
  constructor({ id, type, executedAt }) {
    this.id = id
    this.type = type
    this.executedAt = executedAt ? new Date(executedAt) : null
  }

  static newFromApiResponse = data => {
    return new ChildExcrement(transformSnakeToCamel(data))
  }
}

export default class ChildExcrementHistory {
  constructor({ child, excrements }) {
    this.child = new Child(child || {})
    this.excrements = excrements.map(data => new ChildExcrement(data))
  }

  static newFromApiResponse = data => {
    return new ChildExcrementHistory(transformSnakeToCamel(data))
  }
}

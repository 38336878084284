import Account from '../account'
import { transformSnakeToCamel } from '../../utils/snakeCamelConverter'
import { Schedule } from '../schedule'
import StaffAttendanceStatus from '../staffAttendanceStatus'
import Visitor from '../visitor'

export default class NurseryDiary {
  constructor({
    id,
    status,
    date,
    weather,
    writer,
    head,
    visitors,
    events,
    staffAttendanceStatus,
    forParent,
    handover,
    nurseryNote,
    other,
  }) {
    this.id = id
    this.status = status
    this.date = date ? new Date(date) : null
    this.weather = weather
    this.writer = new Account(writer || {})
    this.head = new Account(head || {})
    this.visitors = (visitors || []).map(visitor => new Visitor(visitor))
    this.events = events ? events.map(data => new Schedule(data)) : []
    this.staffAttendanceStatus = new StaffAttendanceStatus(staffAttendanceStatus || {})
    this.forParent = forParent
    this.handover = handover
    this.nurseryNote = nurseryNote
    this.other = other
  }

  static newFromApiResponse = data => {
    return new NurseryDiary(transformSnakeToCamel(data))
  }
}

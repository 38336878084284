import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Col, Modal, notification, Row, Table, Tag } from 'antd'
import style from '../../styles/SettingsPage.module.scss'
import {
  createDishMenuForNursery,
  fetchDishMenuListForNursery,
  removeDishMenu,
  updateDishMenu,
} from '../../actions/dishMenu'
import { addKeyForDataSource } from '../../utils/antHelper'
import CreateDishMenuForm from '../Forms/NewDishMenuForm'
import EditDishMenuForm from '../Forms/EditDishMenuForm'
import DishMenu from '../../models/dishMenu'

class MealSetting extends React.Component {
  state = {
    newFormVisible: false,
    editFormVisible: false,
    selectedDish: new DishMenu({}),
    didFetchDishMenu: false,
  }

  columns = [
    { title: '料理名', dataIndex: 'name' },
    {
      title: '種類',
      dataIndex: 'type',
      render: value => <div>{this.getMenuTypeLabel(value)}</div>,
    },
    {
      title: 'アレルギー',
      dataIndex: 'allergies',
      render: values => (
        <div>
          {values.map(value => (
            <Tag key={value}>{value}</Tag>
          ))}
        </div>
      ),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <span>
          <Button style={{ marginRight: 8 }} type="primary" onClick={this.onEdit(record)}>
            編集
          </Button>
          <Button type="danger" onClick={this.onDelete(record)}>
            削除
          </Button>
        </span>
      ),
    },
  ]

  getMenuTypeLabel = value => {
    if (value === 'staple') {
      return '主食'
    } else if (value === 'side_dish') {
      return '副菜'
    } else if (value === 'main_dish') {
      return '主菜'
    } else if (value === 'soup') {
      return 'スープ'
    } else if (value === 'dessert') {
      return 'デザート'
    } else if (value === 'snack') {
      return 'お菓子'
    } else {
      return ''
    }
  }

  createDishMenu = (nurseryId, params) => {
    if (!nurseryId) {
      notification.warning({ message: 'データ通信が混み合っています。もう一度お試しください' })
    }
    this.props.createDishMenu(nurseryId, params).then(_ => this.fetchData())
    this.setState({ newFormVisible: false })
  }

  updateDishMenu = (id, params) => {
    this.props.updateDishMenu(id, params).then(_ => this.fetchData())
    this.setState({ editFormVisible: false })
  }

  onNew = e => this.setState({ newFormVisible: true })

  onEdit = dishData => e => {
    this.setState({
      selectedDish: dishData,
      editFormVisible: true,
    })
  }

  onDelete = dishData => e => {
    this.props.removeDishMenu(dishData.id).then(_ => this.fetchData())
  }

  onCloseModal = e => {
    this.setState({ editFormVisible: false, newFormVisible: false })
  }

  fetchData = () => {
    if (this.props.nursery && this.props.nursery.id) {
      this.props.fetchDishMenuList(this.props.nursery.id)
      this.setState({ didFetchDishMenu: true })
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.didFetchDishMenu) {
      this.fetchData()
    }
  }

  render() {
    const { nursery, dishMenuList } = this.props
    const { newFormVisible, editFormVisible, selectedDish } = this.state

    return (
      <div>
        <div className={style.settingContent}>
          <section>
            <Row type="flex" justify="space-between">
              <Col>
                <h2>園の献立メニュー一覧</h2>
              </Col>
              <Col>
                <Button type="primary" shape="circle" icon="plus" onClick={this.onNew} />
              </Col>
            </Row>
          </section>

          <section>
            <Table columns={this.columns} dataSource={addKeyForDataSource(dishMenuList)} />
          </section>
        </div>
        <Modal
          title="料理メニューを追加"
          visible={newFormVisible}
          onCancel={this.onCloseModal}
          footer={null}
        >
          <CreateDishMenuForm nurseryId={nursery && nursery.id} onSubmit={this.createDishMenu} />
        </Modal>
        <Modal
          title="料理メニューを更新"
          visible={editFormVisible}
          onCancel={this.onCloseModal}
          footer={null}
        >
          <EditDishMenuForm
            dishId={selectedDish.id}
            data={selectedDish}
            onSubmit={this.updateDishMenu}
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ app, nursery, classRoom, dishMenu }) => ({
  isLoading: app.isLoading,
  classRoomList: classRoom.list,
  nursery: nursery.mine,
  dishMenuList: dishMenu.list,
})

const mapDispatchToProps = dispatch => ({
  fetchDishMenuList: nurseryId => dispatch(fetchDishMenuListForNursery(nurseryId)),
  createDishMenu: (nurseryId, params) => dispatch(createDishMenuForNursery(nurseryId, params)),
  updateDishMenu: (dishId, params) => dispatch(updateDishMenu(dishId, params)),
  removeDishMenu: id => dispatch(removeDishMenu(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MealSetting))

import React from 'react'
import { Button, Col, Row, Select, TimePicker } from 'antd'
import ChildMiniCard from '../Cards/ChildMiniCard'
import { range } from '../../utils/range'
import { ChildBodyTemperature } from '../../models/childHealth/childBodyTempratureHistory'
import moment from 'moment'

const { Option } = Select
const degreeOption = range(35.0, 42.0, 0.1)

export default ({ data, putBodyTemperature, removeBodyTemperature }) => {
  const { child, bodyTemperatures } = data
  let dataSource = Array.from(bodyTemperatures)
  dataSource.push(new ChildBodyTemperature({}))
  return (
    <div style={style.row}>
      <Row type="flex" gutter={3}>
        <Col>
          <ChildMiniCard child={child} showClass={false} />
        </Col>

        <Col>
          <Row type="flex" gutter={2} style={style.dataZone}>
            {dataSource.map((item, idx) => (
              <Col key={idx} style={style.inputItem}>
                <Row type="flex">
                  <Col style={style.tempItem}>
                    <Select
                      style={{ width: 80 }}
                      value={item.degree}
                      placeholder="追加"
                      onChange={putBodyTemperature(child.id, item.id, 'degree')}
                    >
                      {degreeOption.map(item => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <div style={{ marginTop: 8 }}>
                      {item.executedAt ? (
                        <TimePicker
                          style={{ width: 80 }}
                          size="small"
                          value={moment(item.executedAt)}
                          format="HH:mm"
                          onChange={(time, _) => {
                            putBodyTemperature(child.id, item.id, 'executedAt')(time)
                          }}
                        />
                      ) : (
                        '--:--'
                      )}
                    </div>
                  </Col>
                  <Col style={style.deleteItem}>
                    <Button
                      type="ghost"
                      icon="close"
                      shape="circle"
                      size="small"
                      onClick={removeBodyTemperature(item.id)}
                      disabled={item.id == null}
                    />
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  )
}

const style = {
  row: {
    borderBottom: '1px solid #ddd',
    padding: '16px 0',
    width: '100%',
  },
  dataZone: {
    padding: '0 16px',
  },
  inputItem: {
    paddingRight: 16,
  },
  tempItem: {
    paddingRight: 8,
  },
  deleteItem: {
    lineHeight: '60px',
  },
}

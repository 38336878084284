import { SET_CLASS_ROOM_DIARY, SET_NURSERY_DIARY, SIGN_OUT } from '../constants/actionTypes'
import ClassRoomDiary from '../models/diary/classRoomDiary'
import NurseryDiary from '../models/diary/nurseryDiary'

const DEFAULT_STATE = {
  forClassRoom: new ClassRoomDiary({}),
  forNursery: new NurseryDiary({}),
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_CLASS_ROOM_DIARY:
      return { ...state, forClassRoom: action.payload }
    case SET_NURSERY_DIARY:
      return { ...state, forNursery: action.payload }
    case SIGN_OUT:
      return DEFAULT_STATE
    default:
      return state
  }
}

import {
  deleteChildBodyTemperature,
  deleteChildExcrement,
  deleteChildNap,
  getChildBodyTemperatureListForNursery,
  getChildExcrementListForNursery,
  getChildNapListForNursery,
  postChildBodyTemperature,
  postChildExcrement,
  postChildNap,
} from '../services/v1ApiRequest'
import {
  SET_CHILD_BODY_TEMPERATURE_LIST,
  SET_CHILD_EXCREMENT_LIST,
  SET_CHILD_NAP_LIST,
  START_LOADING,
  STOP_LOADING,
} from '../constants/actionTypes'
import { setServerError } from './apps'
import moment from 'moment'
import ChildNapHistory from '../models/childHealth/childNapHistory'
import ChildBodyTemperatureHistory from '../models/childHealth/childBodyTempratureHistory'
import ChildExcrementHistory from '../models/childHealth/childExcrementHistory'
import { notification } from 'antd'

export const fetchChildNapList = (nurseryId, date, hour, classRoomId) => async dispatch => {
  dispatch({ type: START_LOADING })
  const dateStr = moment(date).format('YYYY-MM-DD')
  const _classRoomId = classRoomId || ''
  const _hour = hour || moment(new Date()).format('H')
  return getChildNapListForNursery(nurseryId, dateStr, _hour, _classRoomId)
    .then(res => {
      dispatch(
        setChildNapList(
          res.data.child_nap_histories.map(data => {
            return ChildNapHistory.newFromApiResponse(data)
          })
        )
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const createOrUpdateChildNap = (childId, params) => async dispatch => {
  dispatch({ type: START_LOADING })
  return postChildNap(childId, params)
    .then(_ => notification.success({ message: 'SUCCESS!' }))
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const removeChildNap = napId => async dispatch => {
  dispatch({ type: START_LOADING })
  return deleteChildNap(napId)
    .then(_ => notification.success({ message: 'SUCCESS!' }))
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const fetchChildBodyTemperatureList = (nurseryId, date, classRoomId) => async dispatch => {
  dispatch({ type: START_LOADING })
  const dateStr = moment(date).format('YYYY-MM-DD')
  const _classRoomId = classRoomId || ''
  return getChildBodyTemperatureListForNursery(nurseryId, dateStr, _classRoomId)
    .then(res => {
      dispatch(
        setChildBodyTempList(
          res.data.child_body_temperature_histories.map(data => {
            return ChildBodyTemperatureHistory.newFromApiResponse(data)
          })
        )
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const createOrUpdateChildBodyTemperature = (childId, params) => async dispatch => {
  dispatch({ type: START_LOADING })
  return postChildBodyTemperature(childId, params)
    .then(_ => notification.success({ message: 'SUCCESS!' }))
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const removeChildBodyTemperature = id => async dispatch => {
  dispatch({ type: START_LOADING })
  return deleteChildBodyTemperature(id)
    .then(_ => notification.success({ message: 'SUCCESS!' }))
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const fetchChildExcrementList = (nurseryId, date, classRoomId) => async dispatch => {
  dispatch({ type: START_LOADING })
  const dateStr = moment(date).format('YYYY-MM-DD')
  const _classRoomId = classRoomId || ''
  return getChildExcrementListForNursery(nurseryId, dateStr, _classRoomId)
    .then(res => {
      dispatch(
        setChildExcrementList(
          res.data.child_excrement_histories.map(data => {
            return ChildExcrementHistory.newFromApiResponse(data)
          })
        )
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const createOrUpdateChildExcrement = (childId, params) => async dispatch => {
  dispatch({ type: START_LOADING })
  return postChildExcrement(childId, params)
    .then(_ => notification.success({ message: 'SUCCESS!' }))
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const removeChildExcrement = id => async dispatch => {
  dispatch({ type: START_LOADING })
  return deleteChildExcrement(id)
    .then(_ => notification.success({ message: 'SUCCESS!' }))
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const setChildNapList = list => ({
  type: SET_CHILD_NAP_LIST,
  payload: list,
})

export const setChildBodyTempList = list => ({
  type: SET_CHILD_BODY_TEMPERATURE_LIST,
  payload: list,
})

export const setChildExcrementList = list => ({
  type: SET_CHILD_EXCREMENT_LIST,
  payload: list,
})

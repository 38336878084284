import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Form, Input, Popconfirm, Select, Switch, Table, Tag } from 'antd'
import { fetchClassRoomList, updateClassRoom } from '../../actions/classRoom'

const EditableContext = React.createContext()
const { Option } = Select

class ClassRoomTableCell extends React.Component {
  getInput = () => {
    if (this.props.dataIndex === 'name') {
      return <Input />
    } else if (this.props.dataIndex === 'isEnable') {
      return <Switch />
    } else if (this.props.dataIndex === 'targets') {
      return (
        <Select mode="multiple" placeholder="対象学年を選択してください（複数可）">
          {[0, 1, 2, 3, 4, 5, 7, 8].map(i => (
            <Option key={i} value={i}>{`${i}歳児`}</Option>
          ))}
        </Select>
      )
    }
    return <Input />
  }

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props
    let rules = [],
      _children = children
    if (dataIndex === 'name') {
      rules = [{ type: 'string', required: true, message: '必須入力' }]
    } else if (dataIndex === 'isEnable') {
      rules = [{ type: 'boolean' }]
    } else if (dataIndex === 'targets') {
      rules = [{ type: 'array' }]
      _children = children[2].map(old => <Tag color="green" key={old}>{`${old}歳児`}</Tag>)
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: rules,
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          _children
        )}
      </td>
    )
  }

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
  }
}

class ClassRoomTable extends React.Component {
  state = {
    editingKey: '',
  }
  columns = [
    { title: 'クラス名', dataIndex: 'name', width: '25%', editable: true },
    { title: '状態', dataIndex: 'isEnable', width: '15%', editable: true },
    { title: '対象学年', dataIndex: 'targets', width: '40%', editable: true },
    {
      title: '編集',
      dataIndex: 'operation',
      render: (text, record) => {
        const { editingKey } = this.state
        const editable = this.isEditingFor(record)
        if (editable) {
          return (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <Button
                    type="link"
                    style={{ marginRight: 8 }}
                    onClick={() => this.onUpdateRecord(form, record)}
                  >
                    保存
                  </Button>
                )}
              </EditableContext.Consumer>
              <Popconfirm title="編集内容を廃棄しますか？" onConfirm={() => this.cancel()}>
                <Button type="link">キャンセル</Button>
              </Popconfirm>
            </span>
          )
        } else {
          return (
            <Button type="link" disabled={editingKey !== ''} onClick={() => this.edit(record.id)}>
              編集
            </Button>
          )
        }
      },
    },
  ]

  isEditingFor = record => record.id === this.state.editingKey

  cancel = () => {
    this.setState({ editingKey: '' })
  }

  onUpdateRecord = (form, recordData) => {
    const id = recordData.id
    form.validateFields((error, row) => {
      if (!error) {
        this.props
          .updateClass(id, {
            class_room: {
              name: row.name,
              is_enable: row.isEnable,
              targets: row.targets,
            },
          })
          .then(_ =>
            this.props.fetchAllClassRoomSetting(this.props.account.staffMembership.nursery.id)
          )
      }
    })
    this.setState({ editingKey: '' })
  }

  edit = id => {
    this.setState({ editingKey: id })
  }

  componentDidMount() {
    if (this.props.account.staffMembership) {
      this.props.fetchAllClassRoomSetting(this.props.account.staffMembership.nursery.id)
    }
  }

  render() {
    const components = {
      body: {
        cell: ClassRoomTableCell,
      },
    }
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'isEnable' ? 'boolean' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditingFor(record),
        }),
      }
    })
    const { classRoomList } = this.props

    return (
      <div>
        <EditableContext.Provider value={this.props.form}>
          <Table
            components={components}
            bordered
            dataSource={classRoomList}
            columns={columns}
            rowClassName="editable-row"
            pagination={{
              onChange: this.cancel,
            }}
          />
        </EditableContext.Provider>
      </div>
    )
  }
}

const mapStateToProps = ({ app, account, classRoom }) => ({
  isLoading: app.isLoading,
  account,
  classRoomList: classRoom.list.map((item, idx) => {
    item['key'] = idx
    return item
  }),
})

const mapDispatchToProps = dispatch => ({
  fetchAllClassRoomSetting: nurseryId => dispatch(fetchClassRoomList(nurseryId)),
  updateClass: (id, params) => dispatch(updateClassRoom(id, params)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'class_room_table' })(withRouter(ClassRoomTable)))

import React from 'react'
import { Button, Col, Form, Input, InputNumber, Radio, Row, Switch } from 'antd'

const { TextArea } = Input
const style = {
  inputSection: {},
}

class PutChildContactBookForm extends React.Component {
  onSubmit = status => e => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values['publish_status'] = status
        this.props.onSubmit({
          child_contact_book: values,
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { data } = this.props
    return (
      <div>
        <Form onSubmit={this.onSubmit('draft')}>
          <section style={style.inputSection}>
            <h3>子どもの様子</h3>
            <Form.Item label="">
              {getFieldDecorator('message', {
                initialValue: data.nursing,
                rules: [{ required: true, message: '入力してください' }],
              })(<TextArea rows={12} />)}
            </Form.Item>
          </section>

          <section style={style.inputSection}>
            <h3>機嫌</h3>
            <Row type="flex" gutter={24}>
              <Col>
                <Form.Item label="午前">
                  {getFieldDecorator('am_mood', {
                    initialValue: data.amMood,
                  })(
                    <Radio.Group>
                      <Radio.Button value="good">良</Radio.Button>
                      <Radio.Button value="normal">普通</Radio.Button>
                      <Radio.Button value="bad">悪</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="午前">
                  {getFieldDecorator('pm_mood', {
                    initialValue: data.pmMood,
                  })(
                    <Radio.Group>
                      <Radio.Button value="good">良</Radio.Button>
                      <Radio.Button value="normal">普通</Radio.Button>
                      <Radio.Button value="bad">悪</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </section>

          <section style={style.inputSection}>
            <h3>入浴</h3>
            <Form.Item label="">
              {getFieldDecorator('took_bath', {
                initialValue: data.tookBath,
                valuePropName: 'checked',
              })(<Switch />)}
            </Form.Item>
          </section>

          <section style={style.inputSection}>
            <h3>使用おむつ枚数</h3>
            <Form.Item label="">
              {getFieldDecorator('number_of_diapers', {
                initialValue: data.numberOfDiapers,
              })(<InputNumber />)}
            </Form.Item>
          </section>

          <div style={{ textAlign: 'center' }}>
            <span style={{ marginRight: 16 }}>
              <Button onClick={this.onSubmit('draft')}>下書き保存</Button>
            </span>
            <span>
              <Button type="primary" onClick={this.onSubmit('published')}>
                公開
              </Button>
            </span>
          </div>
        </Form>
      </div>
    )
  }
}

export default Form.create({ name: 'PutChildContactBookForm' })(PutChildContactBookForm)

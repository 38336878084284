import { transformSnakeToCamel } from '../utils/snakeCamelConverter'
import Nursery from './nursery'

export default class ClassRoom {
  constructor({ id, name, isEnable, targets, nursery }) {
    this.id = id
    this.name = name
    this.isEnable = isEnable
    this.targets = targets || []
    this.nursery = nursery ? new Nursery(nursery) : new Nursery({})
  }

  static newFromApiResponse = data => {
    return new ClassRoom(transformSnakeToCamel(data))
  }
}

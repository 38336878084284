import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router-dom'
import routes from '../routes'
import requireAuth from '../components/requireAuth'
import { withGuestLayout, withMemberLayout } from '../components/Layouts/RouteWithLayout'
import TopPage from '../containers/TopPage'
import SignInPage from '../containers/SignInPage'
import SignUpPage from '../containers/SignUpPage'
import DashboardPage from '../containers/DashboardPage'
import ChildAttendancePage from '../containers/ChildAttendancePage'
import MyPage from '../containers/MyPage'
import SettingsPage from '../containers/SettingsPage'
import ChildrenBookPage from '../containers/ChildrenBookPage'
import NotYetPage from '../containers/NotYetPage'
import DailyBoardPage from '../containers/DailyBoard'

export default ({ history }) => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact path={routes.top()} render={withGuestLayout(TopPage)} />
      <Route exact path={routes.signIn()} render={withGuestLayout(SignInPage)} />
      <Route exact path={routes.signUp()} render={withGuestLayout(SignUpPage)} />

      <Route
        exact
        path={routes.dashboard()}
        render={withMemberLayout(requireAuth(DashboardPage))}
      />
      <Route exact path={routes.myPage()} render={withMemberLayout(requireAuth(MyPage))} />
      <Route path={routes.dailyBoard()} render={withMemberLayout(requireAuth(DailyBoardPage))} />
      <Route path={routes.dailyHealth()} render={withMemberLayout(requireAuth(DailyBoardPage))} />
      <Route path={routes.dailyMeal()} render={withMemberLayout(requireAuth(DailyBoardPage))} />
      <Route
        exact
        path={routes.childAttendance()}
        render={withMemberLayout(requireAuth(ChildAttendancePage))}
      />
      <Route
        exact
        path={routes.settings.index()}
        render={withMemberLayout(requireAuth(SettingsPage))}
      />
      <Route
        exact
        path={routes.settings.nursery()}
        render={withMemberLayout(requireAuth(SettingsPage))}
      />
      <Route
        exact
        path={routes.settings.classRoom()}
        render={withMemberLayout(requireAuth(SettingsPage))}
      />
      <Route
        exact
        path={routes.settings.meal()}
        render={withMemberLayout(requireAuth(SettingsPage))}
      />
      <Route
        exact
        path={routes.childrenBook(':id')}
        render={withMemberLayout(requireAuth(ChildrenBookPage))}
      />

      <Route exact path={routes.notYet()} render={withMemberLayout(requireAuth(NotYetPage))} />
    </Switch>
  </ConnectedRouter>
)

import React from 'react'
import { Button, Col, Row, Select, TimePicker } from 'antd'
import ChildMiniCard from '../Cards/ChildMiniCard'
import { ChildExcrement } from '../../models/childHealth/childExcrementHistory'
import moment from 'moment'

const { Option } = Select
const excrementOptions = [
  { label: '普通', value: 'normal' },
  { label: '軟便', value: 'soft_stool' },
  { label: '下痢便', value: 'diarrhea_stool' },
  { label: '硬便', value: 'hard_stool' },
  { label: '小便', value: 'piss' },
]

export default ({ data, putExcrement, removeExcrement }) => {
  const { child, excrements } = data
  let dataSource = Array.from(excrements)
  dataSource.push(new ChildExcrement({}))
  return (
    <div style={style.row}>
      <Row type="flex" gutter={3}>
        <Col>
          <ChildMiniCard child={child} showClass={false} />
        </Col>

        <Col>
          <Row type="flex" gutter={2}>
            {dataSource.map((item, idx) => (
              <Col key={idx} style={style.inputItem}>
                <Row type="flex">
                  <Col style={style.excrementItem}>
                    <Select
                      style={{ minWidth: 100 }}
                      value={item.type}
                      placeholder="追加"
                      onChange={putExcrement(child.id, item.id, 'type')}
                    >
                      {excrementOptions.map(item => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                    <div style={{ marginTop: 8 }}>
                      {item.executedAt ? (
                        <TimePicker
                          style={{ width: 80 }}
                          size="small"
                          value={moment(item.executedAt)}
                          format="HH:mm"
                          onChange={(time, _) => {
                            putExcrement(child.id, item.id, 'executedAt')(time)
                          }}
                        />
                      ) : (
                        '--:--'
                      )}
                    </div>
                  </Col>
                  <Col style={style.deleteItem}>
                    <Button
                      type="ghost"
                      icon="close"
                      shape="circle"
                      size="small"
                      onClick={removeExcrement(item.id)}
                      disabled={item.id == null}
                    />
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  )
}

const style = {
  row: {
    borderBottom: '1px solid #ddd',
    padding: '16px 0',
    width: '100%',
  },
  dataZone: {
    padding: '0 16px',
  },
  inputItem: {
    paddingRight: 16,
  },
  excrementItem: {
    paddingRight: 8,
  },
  deleteItem: {
    lineHeight: '60px',
  },
}

import { transformSnakeToCamel } from '../utils/snakeCamelConverter'

export default class StaffAttendanceStatus {
  constructor({ absent, leaveEarly, late, businessTrip }) {
    this.absent = absent
    this.leaveEarly = leaveEarly
    this.late = late
    this.businessTrip = businessTrip
  }

  static newFromApiResponse = data => {
    return new StaffAttendanceStatus(transformSnakeToCamel(data))
  }
}

import React from 'react'
import { Button, Form, Input } from 'antd'

const { TextArea } = Input

class PutChildDiaryForm extends React.Component {
  onSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit({
          child_diary: values,
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { data } = this.props
    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <Form.Item label="養護">
            {getFieldDecorator('nursing', {
              initialValue: data.nursing,
              rules: [{ required: true, message: '選択してください' }],
            })(<TextArea placeholder="養護" />)}
          </Form.Item>
          <Form.Item label="教育">
            {getFieldDecorator('education', {
              initialValue: data.education,
            })(<TextArea placeholder="教育" />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

export default Form.create({ name: 'PutChildDiaryForm' })(PutChildDiaryForm)

import { transformSnakeToCamel } from '../utils/snakeCamelConverter'

export class Schedule {
  constructor({ id, title, startsAt, endsAt, isAllDay }) {
    this.id = id
    this.title = title
    this.startsAt = startsAt ? new Date(startsAt) : null
    this.endsAt = endsAt ? new Date(endsAt) : null
    this.isAllDay = isAllDay
  }

  static newFromApiResponse = data => {
    return new Schedule(transformSnakeToCamel(data))
  }
}

import { transformSnakeToCamel } from '../utils/snakeCamelConverter'
import Child from './child'

export class ChildAttendanceStatus {
  constructor({ all, willCome, attend, absent, exist }) {
    this.all = all
    this.willCome = willCome
    this.attend = attend
    this.absent = absent
    this.exist = exist
  }

  static newFromApiResponse = data => {
    return new ChildAttendanceStatus(transformSnakeToCamel(data))
  }
}

export default class ChildAttendance {
  constructor({ id, type, childId, date, beginsAt, endsAt, plays, notice, child, totalNum }) {
    this.id = id
    this.type = type // Enum[:attend, :absent, :leave_early, :late, :official_absent]
    this.childId = childId
    this.date = new Date(date)
    this.beginsAt = beginsAt ? new Date(beginsAt) : null
    this.endsAt = endsAt ? new Date(endsAt) : null
    this.plays = plays ? plays.map(play => new Play(play)) : [] // TODO: 時系列順に並ぶか確認
    this.notice = notice
    this.child = child ? new Child(child) : new Child({})
    this.totalNum = totalNum

    // local only
    this.lastPlayId = this.plays.length > 0 ? this.plays.slice(-1)[0].id : null
    this.beginsPlayAt = this.plays.length > 0 ? this.plays.slice(-1)[0].beginsAt : null
    this.endsPlayAt = this.plays.length > 0 ? this.plays.slice(-1)[0].endsAt : null
  }

  static newFromApiResponse = data => {
    return new ChildAttendance(transformSnakeToCamel(data))
  }

  _getDiffAsHour = (beginsAt, endsAt) => {
    const diffMills = endsAt.getTime() - beginsAt.getTime()
    return (diffMills / (1000 * 60 * 60)).toFixed(1)
  }

  totalCareTime = () => {
    if (!this.endsAt) {
      return null
    }
    return this._getDiffAsHour(this.beginsAt, this.endsAt)
  }

  // TODO: Play全体ではなく、外出時間に絞る
  totalPlayTime = () => {
    let sum = 0.0
    this.plays.forEach(play => {
      if (play.endsAt) {
        sum = sum + this._getDiffAsHour(play.beginsAt, play.endsAt)
      }
    })
    return sum
  }
}

export class Play {
  constructor({ id, type, name, beginsAt, endsAt }) {
    this.id = id
    this.type = type
    this.name = name
    this.beginsAt = new Date(beginsAt)
    this.endsAt = endsAt ? new Date(endsAt) : null
  }

  static newFromApiResponse = data => {
    return new ChildAttendance(transformSnakeToCamel(data))
  }
}

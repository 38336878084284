import { SET_NURSERY_DIARY, START_LOADING, STOP_LOADING } from '../constants/actionTypes'
import { deleteNurseryDiary, getNurseryDiaryList, postNurseryDiary } from '../services/v1ApiRequest'
import moment from 'moment'
import NurseryDiary from '../models/diary/nurseryDiary'
import { setServerError } from './apps'
import { notification } from 'antd'

export const fetchNurseryDiary = (nurseryId, date) => dispatch => {
  dispatch({ type: START_LOADING })
  const dateStr = moment(date).format('YYYY-MM-DD')
  return getNurseryDiaryList(nurseryId, dateStr)
    .then(res => {
      const selected = res.data.nursery_diaries[0]
      dispatch(
        setDiary(selected ? NurseryDiary.newFromApiResponse(selected) : new NurseryDiary({}))
      )
    })
    .catch(e => {
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const createOrUpdateNurseryDiary = (nurseryId, date, params) => dispatch => {
  dispatch({ type: START_LOADING })
  const dateStr = moment(date).format('YYYY-MM-DD')
  params['nursery_diary']['date'] = dateStr
  if (!params['nursery_diary']['status']) {
    params['nursery_diary']['status'] = 'draft'
  }
  return postNurseryDiary(nurseryId, dateStr, params)
    .then(res => {
      notification.success({ message: 'Success' })
      dispatch(setDiary(new NurseryDiary.newFromApiResponse(res.data)))
    })
    .catch(e => {
      notification.error({ message: 'Error' })
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

export const removeNurseryDiary = id => dispatch => {
  dispatch({ type: START_LOADING })
  return deleteNurseryDiary(id)
    .then(_ => {
      notification.success({ message: 'Success!' })
      dispatch(setDiary({}))
    })
    .catch(e => {
      notification.error({ message: 'Error' })
      if (!e.response || e.response.status >= 500) {
        dispatch(setServerError())
      }
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING })
    })
}

const setDiary = data => ({
  type: SET_NURSERY_DIARY,
  payload: data,
})

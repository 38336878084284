import { Button, Form, Input } from 'antd'
import React from 'react'

export default Form.create({ name: 'account' })(
  class extends React.Component {
    onSubmit = e => {
      e.preventDefault()
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.onSubmit(values)
        }
      })
    }

    render() {
      const { getFieldDecorator } = this.props.form
      return (
        <Form onSubmit={this.onSubmit}>
          <div>
            <div>現在のパスワード</div>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: '必須項目です' }],
              })(<Input.Password style={{ margin: '8px 0' }} placeholder="現在のパスワード" />)}
            </Form.Item>
          </div>
          <div>
            <div>新しいパスワード</div>
            <Form.Item>
              {getFieldDecorator('new_password', {
                rules: [{ required: true, message: '必須項目です' }],
              })(<Input.Password style={{ margin: '8px 0' }} placeholder="新しいパスワード" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('new_password_confirm', {
                rules: [{ required: true, message: '必須項目です' }],
              })(
                <Input.Password
                  style={{ margin: '8px 0' }}
                  placeholder="新しいパスワード（確認用）"
                />
              )}
            </Form.Item>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit">
              パスワードを変更
            </Button>
          </div>
        </Form>
      )
    }
  }
)

import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { connectRouter } from 'connected-react-router'
import app from './appReducer'
import account from './accountReducer'
import nursery from './nurseryReducer'
import classRoom from './classRoomReducer'
import child from './childReducer'
import childAttendance from './childAttendanceReducer'
import nurseryCorrespondence from './nurseryCorrespondence'
import childHealth from './childHealthReducer'
import childMeal from './childMealReducer'
import parentReport from './parentReportReducer'
import dishMenu from './dishMenuReducer'
import diary from './diaryReducer'
import staffAttendance from './staffAttendanceReducer'
import physicalGrowthHistory from './physicalGrowthHistoryReducer'

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  whitelist: ['id', 'authToken'],
}

export default history =>
  combineReducers({
    router: connectRouter(history),
    account: persistReducer(authPersistConfig, account),
    app,
    nursery,
    classRoom,
    child,
    childAttendance,
    nurseryCorrespondence,
    childHealth,
    childMeal,
    parentReport,
    dishMenu,
    diary,
    staffAttendance,
    physicalGrowthHistory
  })

import React from 'react'
import { connect } from 'react-redux'
import { Col, Modal, notification, Row } from 'antd'
import ChildMiniCard from '../Cards/ChildMiniCard'
import { range } from '../../utils/range'
import moment from 'moment'
import { ChildNap } from '../../models/childHealth/childNapHistory'
import PutChildNapForm from '../../containers/Forms/PutChildNapForm'

const fiveSteps = range(0, 60, 5)

const napStatusPresenter = napData => {
  let direction = ''
  let didFix = ''
  let wakedUp = ''
  if (napData.direction === 'supine') {
    direction = '仰'
  } else if (napData.direction === 'right') {
    direction = '右'
  } else if (napData.direction === 'left') {
    direction = '左'
  }
  if (napData.fixProne) {
    didFix = '直'
  }
  if (napData.wakedUp) {
    wakedUp = '起'
  }
  return direction + didFix + wakedUp
}

class ChildNapTableCell extends React.Component {
  state = {
    showModal: false,
    selectedNap: new ChildNap({}),
  }

  onModalOk = e => {
    // REMOVE NAP
    if (this.state.selectedNap.id) {
      this.props.removeNap(this.state.selectedNap.id)
    } else {
      notification.success({ message: 'OK' })
    }
  }

  onOpenModal = nap => e => {
    this.setState({
      showModal: true,
      selectedNap: nap,
    })
  }

  onPutChildNap = params => {
    this.props.putNap(this.props.data.child.id, this.props.data.id, params)
    this.onCloseModal()
  }

  onCloseModal = _ => this.setState({ showModal: false })

  // sinceDateTimeはmoment
  render() {
    const { data, sinceDateTime, account, staffList } = this.props
    const { showModal, selectedNap } = this.state
    const { child, naps } = data
    const napList = fiveSteps.map(m => {
      const time = moment(sinceDateTime).minutes(m)
      let foundNap = null
      naps.forEach(nap => {
        if (moment(nap.startsAt).minutes() === m) {
          foundNap = nap
        }
      })
      return (
        foundNap ||
        new ChildNap({
          startsAt: time.toDate(),
          endsAt: time
            .clone()
            .add(5, 'minutes')
            .toDate(),
        })
      )
    })

    return (
      <div style={style.row}>
        <Row type="flex">
          <Col>
            <ChildMiniCard child={child} showClass={false} />
          </Col>

          <Col>
            <Row type="flex" gutter={8}>
              {napList.map((item, idx) => (
                <Col key={idx}>
                  <div style={style.napItem} onClick={this.onOpenModal(item)}>
                    <div>{moment(item.startsAt).format('mm')}</div>
                    <div>{napStatusPresenter(item)}</div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Modal
          title={`午睡（${moment(selectedNap.startsAt).format('HH:mm')}）`}
          visible={showModal}
          onOk={this.onModalOk}
          onCancel={this.onCloseModal}
          okText="削除"
          okButtonProps={{ type: 'danger' }}
          cancelText="キャンセル"
          destroyOnClose={true}
          style={style.modal}
        >
          <PutChildNapForm
            account={account}
            staffList={staffList}
            data={selectedNap}
            onSubmit={this.onPutChildNap}
          />
        </Modal>
      </div>
    )
  }
}

const style = {
  row: {
    borderBottom: '1px solid #ddd',
    padding: '16px 0',
    width: '100%',
  },
  napItem: {
    margin: '8px 8px 8px 0',
    padding: 8,
    textAlign: 'center',
    borderRadius: 5,
    border: '1px solid #ddd',
    width: 60,
    height: 60,
  },
  modal: {},
}

const mapStateToProps = ({ nursery, account }) => ({
  staffList: nursery.staffList,
  account,
})

export default connect(mapStateToProps)(ChildNapTableCell)

import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Breadcrumb, Col, DatePicker, Dropdown, Icon, Menu, Row } from 'antd'
import { setPageTitle, setSelectedDate } from '../../actions/apps'
import { fetchChildAttendanceListForChild } from '../../actions/childAttendance'
import { fetchChild } from '../../actions/child'
import ChildDetailAttendanceTable from '../../components/Tables/ChildDetailAttendanceTable'
import style from '../../styles/ChildrenBookPage.module.scss'
import routes from '../../routes'
import moment from 'moment'

const { MonthPicker } = DatePicker

class Attendance extends Component {
  state = {
    shouldSetPageName: true,
    shouldLoadAttendances: true,
  }

  onDateChange = (date, _) => {
    this.props.changeDate(this.props.child.id, date.year(), date.month() + 1)
    this.props.setSelectedDate(date.toDate())
  }

  componentDidMount() {
    this.props.setPageTitle('子ども詳細')
    if (!this.props.child.id) {
      this.props.fetchChild(this.props.child.id)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.child.id) {
      const today = moment(new Date())
      if (this.state.shouldLoadAttendances || this.state.shouldSetPageName) {
        this.props.setPageTitle(this.props.child.fullName)
        this.props.fetchAllAttendances(this.props.child.id, today.year(), today.month() + 1)
        this.setState({
          shouldSetPageName: false,
          shouldLoadAttendances: false,
        })
      }
    }
  }

  componentWillUnmount() {
    this.props.setSelectedDate(new Date())
  }

  render() {
    const { isLoading, child, token, selectedDate } = this.props
    const year = selectedDate.year()
    const month = selectedDate.month() + 1
    const downloadOptionList = [
      {
        selector: 'excel',
        display: 'Excel形式',
        icon: 'file-excel',
        link: routes.download.monthlyTimeCardForChild(this.props.child.id, year, month, token),
      },
    ]
    const downloadMenu = (
      <Menu>
        {downloadOptionList.map(item => (
          <Menu.Item key={item.selector}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a ref="noopener noreferrer" href={item.link} target="_blank">
              <Icon type={item.icon} />
              {item.display}
            </a>
          </Menu.Item>
        ))}
      </Menu>
    )

    return (
      <div>
        <h2>登降園管理</h2>

        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={routes.childAttendance()}>一覧</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <b>{child ? child.fullName : '氏名'}</b>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div style={{ padding: '24px 0' }}>
          <Row type="flex" justify="space-between">
            <Col>
              <MonthPicker
                allowClear={false}
                onChange={this.onDateChange}
                value={selectedDate}
                format="YYYY/MM"
              />
            </Col>
            <Col>
              <Dropdown.Button overlay={downloadMenu} icon={<Icon type="download" />}>
                ダウンロード
              </Dropdown.Button>
            </Col>
          </Row>
        </div>

        <div className={style.overflowXScroll}>
          <ChildDetailAttendanceTable isLoading={isLoading} childId={this.props.child.id} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ app, child, nursery, childAttendance, account }) => ({
  child: child.selected,
  selectedDate: moment(app.selectedDate),
  isLoading: app.isLoading,
  nursery: nursery.mine,
  attendanceList: childAttendance.listForChild,
  token: account.authToken,
})

const mapDispatchToProps = dispatch => ({
  setPageTitle: pageTitle => dispatch(setPageTitle(pageTitle)),
  fetchChild: id => dispatch(fetchChild(id)),
  fetchAllAttendances: (childId, year, month, page = 1) =>
    dispatch(fetchChildAttendanceListForChild(childId, year, month, page)),
  changeDate: (childId, year, month) =>
    dispatch(fetchChildAttendanceListForChild(childId, year, month)),
  setSelectedDate: date => dispatch(setSelectedDate(date)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Attendance))

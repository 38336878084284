import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Col, Layout, Menu, Row } from 'antd'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { setPageTitle, setSelectedDate } from '../../actions/apps'
import { fetchMyNursery } from '../../actions/nursery'
import DiaryPage from './diary'
import HealthPage from './health'
import MealPage from './meal'
import style from '../../styles/DailyBoardPage.module.scss'
import moment from 'moment'
import routes from '../../routes'

moment.locale('ja', {
  weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
})

const { Sider, Content } = Layout

class DailyBoard extends Component {
  state = {
    selectedKey: 'diary',
    currentUrl: '',
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const pageUrl = window.location.href
    if (pageUrl !== prevState.currentUrl) {
      let key = ''
      if (pageUrl.includes(routes.dailyBoard())) {
        key = 'diary'
      } else if (pageUrl.includes(routes.dailyHealth())) {
        key = 'health'
      } else if (pageUrl.includes(routes.dailyMeal())) {
        key = 'meal'
      } else {
        return null
      }
      return {
        currentUrl: pageUrl,
        selectedKey: key,
      }
    }
    return null
  }

  prev = e => {
    const { selectedDate } = this.props
    let _tmpDate = new Date(selectedDate)
    _tmpDate.setDate(new Date(selectedDate).getDate() - 1)
    this.props.setDate(_tmpDate)
  }

  next = e => {
    const { selectedDate } = this.props
    let _tmpDate = new Date(selectedDate)
    _tmpDate.setDate(new Date(selectedDate).getDate() + 1)
    this.props.setDate(_tmpDate)
  }

  onTapMenu = e => {
    let r = ''
    if (e.key === 'diary') {
      r = routes.dailyBoard()
    } else if (e.key === 'health') {
      r = routes.dailyHealth()
    } else if (e.key === 'meal') {
      r = routes.dailyMeal()
    } else {
      return
    }
    this.props.push(r)
  }

  componentDidMount() {
    this.props.setPageTitle('デイリーボード')
    this.props.fetchNursery()
  }

  render() {
    const { selectedDate, parentReportList } = this.props
    const { selectedKey } = this.state
    let Page = null
    if (selectedKey === 'diary') {
      Page = DiaryPage
    } else if (selectedKey === 'health') {
      Page = HealthPage
    } else if (selectedKey === 'meal') {
      Page = MealPage
    }

    return (
      <Layout>
        <Layout className={style.container}>
          <Content>
            <section>
              <Row type="flex" justify="space-between">
                <Col className={style.dateSelector}>
                  <Row type="flex" justify="space-between">
                    <Col>
                      <Button onClick={this.prev} icon="left" />
                    </Col>
                    <Col>
                      <div className={style.date}>
                        {moment(selectedDate).format('MM月DD日(ddd)')}
                      </div>
                    </Col>
                    <Col>
                      <Button onClick={this.next} icon="right" />
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Menu onClick={this.onTapMenu} selectedKeys={[selectedKey]} mode="horizontal">
                    <Menu.Item key="diary">日誌・連絡帳</Menu.Item>
                    <Menu.Item key="health">健康チェック</Menu.Item>
                    <Menu.Item key="meal">食事チェック</Menu.Item>
                  </Menu>
                </Col>
              </Row>
            </section>

            <section>
              <Page />
            </section>
          </Content>
        </Layout>

        {selectedKey === 'diary' && (
          <Sider className={style.sidebar}>
            <section>
              <h2>{moment(selectedDate).format('MM/DD')}の連絡</h2>
            </section>
            <div>
              {parentReportList.length === 0 ? 'なし' : null}
              {parentReportList.map(data => (
                <div key={data.id} className={style.reportCard}>
                  <h3>{data.child.fullName}</h3>
                  <p>{data.message}</p>
                </div>
              ))}
            </div>
          </Sider>
        )}
      </Layout>
    )
  }
}

const mapStateToProps = ({ app, parentReport }) => ({
  selectedDate: app.selectedDate,
  parentReportList: parentReport.list,
})

const mapDispatchToProps = dispatch => ({
  setPageTitle: title => dispatch(setPageTitle(title)),
  setDate: date => dispatch(setSelectedDate(date)),
  push: path => dispatch(push(path)),
  fetchNursery: () => dispatch(fetchMyNursery()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DailyBoard))
